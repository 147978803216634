

const blocksReducer =  (state = [], action)=>{
    switch (action.type) {
        case "email-block-add":
            return [...state, action.id];
        case "email-block-delete":
            return state.filter((item)=>item !== action.id)
        case "email-block-reorder":
            return action.block_order;
        default:
            return state;
    }
}

const blockDataReducer = (state = {}, action)=>{
    switch (action.type) {
        case "email-block-add":
            return {...state, [action.id]:{...action.data}}
        case "email-block-delete":
            return {...state, [action.id]:undefined};
        case "email-block-value-update":
            return {...state, [action.id]:{...state[action.id], values:{...state[action.id].values, ...action.values}}}
        default:
            return state
    }
}

export default (state = {}, action)=>{
    switch (action.type) {
        case "email-set":
            return action.data;
        case "email-update":
            return {...state, has_changed:true, ...action.change}
        case "email-block-add":
        case "email-block-delete":
        case "email-block-value-update":
        case "email-block-reorder":
            return {...state, has_changed:true, blocks: blocksReducer(state.blocks, action), data: blockDataReducer(state.data, action) }
        default:
            return state;
    }
}
