import React from 'react';
import {Route, Routes} from 'react-router-dom';
import WebflowCallback from './WebflowCallback';
import WebflowConnect from './WebflowConnect';
import {setAccessToken} from '../../../utils/access_token_handler';

function WebflowIndex(props) {
  if(new URLSearchParams(window.location.search).get('mtoken')){
    setAccessToken(new URLSearchParams(window.location.search).get('mtoken'))
  }
  return (
      <Routes>
        <Route path={"callback"} element={<WebflowCallback/>}></Route>
        <Route index element={<WebflowConnect/>}></Route>
      </Routes>
  );
}

export default WebflowIndex;
