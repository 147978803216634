import React, {useEffect} from 'react';
import FakeProgressBar from '../../../components/FakeProgressBar';
import {getCurrentQueryObject} from '../../../utils/func';
import {getHttpClient} from '../../../api';
import {setAccessToken} from '../../../utils/access_token_handler';
import {shopify} from '../../../config';
import {shopifyRedirectTo} from '../../../utils/shopifyApp';

function ShopifyBillingActivate() {
    useEffect(()=>{
        const {charge_id, shop, access_token} = getCurrentQueryObject();
        if(access_token) {
            setAccessToken(access_token)
        }
        getHttpClient().post('/billing/activate',{
            charge_id,
            shop
        }).then(({data})=>{
            window.location =`https://admin.shopify.com/store/${shop.split(".")[0]}/apps/${shopify.clientId}`
        })
    },[])
    return (
        <>
            <div className="d-flex align-items-center justify-content-center my-4 py-4">
                <div style={{width:300}}>
                    <FakeProgressBar/>
                </div>
            </div>
        </>
    );
}

export default ShopifyBillingActivate;
