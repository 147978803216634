import React, {Component} from 'react';
import SettingsSection from "../../components/SettingSection";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {SettingsMap} from "../../../../components/VideoAndHelpSectionMap";


class RechargeSettingSection extends Component {

    render() {
        const setupBtn = this.props.subscription ?
            <Link to={"/settings/integrations/rechargepayments/details"} className={"btn btn-secondary"}>Setup</Link> :
            <Link to={"/subscriptions"}>Upgrade to Connect</Link>

        return (
            <SettingsSection title={"Recharge Payments"} helpLink={SettingsMap.get('Integrations').RechargePayments.helpLink} videoID={SettingsMap.get('Integrations').RechargePayments.videoID} subtitle={"Subscription payments by rechargepayments.com"}>
                {
                    this.props.subscription === 0 ? <Link to={"/subscriptions"}>Upgrade to connect</Link>
                :
                    this.props.recharge_access_token ? <div>
                        <p>Connected</p>
                        <Link to={"/settings/integrations/rechargepayments/details"}>Details</Link>
                    </div> : <div>
                        {setupBtn}
                    </div>
                }
            </SettingsSection>
        );
    }
}


function mapStateToProps(state){
    return {
        recharge_access_token: state.settings.recharge_access_token,
        subscription: state.settings.subscription === 1
    }
}
export default connect(mapStateToProps)(RechargeSettingSection);