const jsonObjectKeys = [
    'custom_payment_methods','personal_coupon_rule', 'discount_properties', 'checkout_widget_data',
    'product_auto_enroll_to_group_array', 'legal_contracts', 'sdk_rpc_json','sdk_products_list_json',
    'salesTableColumn','affiliate_shop_theme','aff_extra_tabs','conversion_preference_order','custom_tracking_rules',
    'custom_royalties_rules','aff_quick_links','payout_filters',
    'autopay_dc_entitled_collections',
]

const defaultObject = {
    salesTableColumn:[
        {
            label:'Date',
            value:'order_date',
        },
        {
            label:'Order',
            value: 'site_order_number',
        },
        {
            label: 'Amount',
            value:'order_value'
        },
        {
            label: 'Affiliate',
            value: 'name',
        },
        {
            label: 'Commission',
            value: 'affiliate_commission',
        },
        {
            label: 'Status',
            value: 'status',
        },
        {
            label: "Actions",
            value: 'actions',
        }
    ],
    affiliate_shop_theme:{
        headingGoogleFont: 'Raleway',
        bodyGoogleFont: 'Poppins',
        bodyBackgroundColor: '#efefef',
    }
}

export default (keys)=>{
    return (state, props)=>{
        if(keys) {
            const map = {
                subscription: state.settings.subscription
            }
            keys.forEach((item) => {
                if (jsonObjectKeys.indexOf(item) > -1) {
                    try {
                        map[item] = typeof state.settings[item] === "string" ?
                            JSON.parse(state.settings[item]) :
                            state.settings[item]
                    }catch(e){

                    }
                } else{
                    map[item] = state.settings[item]
                }
                if(!map[item]){
                    map[item] = defaultObject[item]
                }
            })
            return map
        }
        return state.settings;
    }
}
