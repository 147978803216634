export const CommissionsMap = new Map([
    ['Referral Commissions', {
        DefaultCommission: {
            helpLink: 'https://docs.goaffpro.com/how-tos/set-commission-for-affiliates',
            videoID: 'tdIcdXHESrU'
        },
        AffiliateCommission: {
            helpLink: 'https://docs.goaffpro.com/how-tos/set-affiliate-commissions',
            videoID: 'n6NMIBzPRPI'
        },
        ProductCommission: {
            helpLink: 'https://docs.goaffpro.com/how-tos/set-product-commission-for-affiliates',
            videoID: 'DcFLgu4p2BY'
        },
        CollectionCommission: {
            helpLink: 'https://docs.goaffpro.com/how-tos/set-collection-commission-for-affiliates',
            videoID: 'DuFX_tvNKq4'
        }
    }],
    ['Fixed Commissions', {
        FixedCommissions: {
            helpLink: 'https://docs.goaffpro.com/how-tos/set-fixed-commissions-for-affiliates-royalties',
            videoID: 'kWJuGNfqkyI'
        },
        RuleBasedRoyalties: {
            helpLink: 'https://docs.goaffpro.com/how-tos/setup-rule-based-royalties',
            videoID: 'x9yR__kGyJg'
        },
        RuleBasedTracking: {
            helpLink: 'https://docs.goaffpro.com/how-tos/setup-rule-based-tracking-for-sales',
            videoID: 'WMmnWO0I27M'
        }
    }],
    ['Commission Modifiers', {
        CommissionModifiers: {
            helpLink: 'https://docs.goaffpro.com/how-tos/set-commission-modifiers',
            videoID: 'PJpLL_vC6sI'
        }
    }]
])

export const LookAndFeelMap = new Map([
    ['Store Profile', {
        AffiliatePortal: {
            helpLink: 'https://docs.goaffpro.com/how-tos/custom-domain-setup',
            videoID: 'nwWlbX_445w'
        },
        StoreProfile: {
            helpLink: 'https://docs.goaffpro.com/how-tos/setup-store-currency',
            videoID: 'ENWcVzZEHyc'
        },
        StoreLogo: {
            helpLink: 'https://docs.goaffpro.com/how-tos/customization-and-branding/store-profile/setup-store-logo',
            videoID: 'A6f3wT5S9HU'
        },
        BrandFont: {
            helpLink: 'https://docs.goaffpro.com/how-tos/customization-and-branding/store-profile/set-the-brand-font',
            videoID: 'DMEajwqV0Zs'
        },
        BrandPrimaryColor: {
            helpLink: 'https://docs.goaffpro.com/how-tos/customization-and-branding/store-profile/set-the-brand-primary-color',
            videoID: 'H2t2jnMET2k'
        },
        AffiliatePortalLanguage: {
            helpLink: 'https://docs.goaffpro.com/how-tos/customization-and-branding/store-profile/affiliate-portal-language',
            videoID: '46Vg1_bqDVY'
        },
        EditTranslations: {
            helpLink: 'https://docs.goaffpro.com/how-tos/edit-text-in-affiliate-dashboard',
            videoID: '1Y-Re0QQnFw'
        },
        Legal: {
            helpLink: 'https://docs.goaffpro.com/how-tos/customization-and-branding/store-profile/legal',
            videoID: 'KbLJ5G7Ubws'
        },
        ContactPage: {
            helpLink: 'https://docs.goaffpro.com/how-tos/customization-and-branding/store-profile/set-contact-page-url',
            videoID: 'o7q4VhKBUfI'
        },
        OtherPages: {
            helpLink: 'https://docs.goaffpro.com/how-tos/customize-other-pages-of-affiliate-portal',
            videoID: 'ucVy7wiZfkU'
        },
    }],
    ['Landing Page', {
        LandingPageEditor: {
            helpLink: 'https://docs.goaffpro.com/how-tos/customization-and-branding/affiliate-portal-landing-page',
            videoID: 'Se_0WdbtVeE'
        }
    }],
    ['Signup Page', {
        SignUpPageStyle: {
            helpLink: 'https://docs.goaffpro.com/how-tos/customization-and-branding/affiliate-portal-signup-page',
            videoID: 'fBfjw4gJKeE'
        },
        ExtraSignupPages: {
            helpLink: 'https://docs.goaffpro.com/how-tos/create-multiple-signup-pages/setup-new-signup-page',
            videoID: 'vMCXFdyTC98'
        }
    }],
    ['Affiliate Dashboard', {
        DashboardTabs: {
            helpLink: 'https://docs.goaffpro.com/how-tos/enable-additional-dashboard-tabs',
            videoID: 'EK39C2oXDnY'
        },
        NewCustomPage: {
            helpLink: 'https://docs.goaffpro.com/how-tos/enable-additional-dashboard-tabs/create-new-dashboard-tab',
            videoID: 'uR9zTRCMSUY'
        },
        WidgetAndAnalytics: {
            helpLink: 'https://docs.goaffpro.com/how-tos/customization-and-branding/affiliate-dashboard/widgets-and-analytics',
            videoID: 'HnMmseYC8IE'
        },
        Logo: {
            helpLink: 'https://docs.goaffpro.com/how-tos/customization-and-branding/affiliate-dashboard/logo-and-dashboard-colors',
            videoID: 'oFCkPDB4ZhU'
        },
        DashboardColors: {
            helpLink: 'https://docs.goaffpro.com/how-tos/customization-and-branding/affiliate-dashboard/dashboard-colors',
            videoID: '8IsStXd5zlE'
        },
        AffiliatePortalTracking: {
            helpLink: 'https://docs.goaffpro.com/how-tos/setup-portal-tracking-with-facebook-pixel',
            videoID: 'ARdaDwaPsPk'
        },
        FacebookMessenger: {
            helpLink: 'https://docs.goaffpro.com/how-tos/facebook-messenger-widget-for-affiliate-portal/setup-facebook-messenger-widget',
            videoID: null
        },
    }],
    ['Toolbars', {
        AffiliateToolbar: {
            helpLink: 'https://docs.goaffpro.com/how-tos/setup-affiliate-toolbar',
            videoID: 'RnqNCdU2I0M'
        },
        CustomizeAffiliateToolbar: {
            helpLink: 'https://docs.goaffpro.com/how-tos/setup-affiliate-toolbar/customize-affiliate-toolbar',
            videoID: '5fnb0GAagUE'
        },
        ShoppingWithToolbar: {
            helpLink: 'https://docs.goaffpro.com/how-tos/setup-shopping-with-toolbar',
            videoID: 'DAuNJICFke4'
        },
        CustomizeShoppingWithToolbar: {
            helpLink: 'https://docs.goaffpro.com/how-tos/setup-shopping-with-toolbar/customize-shopping-with-toolbar',
            videoID: 'mU-of4lgOLE'
        },
        ShoppingWithFloatingWidget: {
            helpLink: 'https://docs.goaffpro.com/how-tos/setup-shopping-with-floating-widget',
            videoID: 'iF7RCJQmBnk'
        },
        CustomizeShoppingWithFloatingWidget: {
            helpLink: 'https://docs.goaffpro.com/how-tos/setup-shopping-with-floating-widget/customize-shopping-with-floating-widget',
            videoID: 'bmN-xNWs7m8'
        },
        ShoppingCartReferredBy: {
            helpLink: 'https://docs.goaffpro.com/how-tos/setup-shopping-cart-referred-by-widget',
            videoID: 'ecXFKaXz74M'
        },
        CustomizeShoppingCartReferredBy: {
            helpLink: 'https://docs.goaffpro.com/how-tos/setup-shopping-cart-referred-by-widget/customize-shopping-cart-referred-by-widget',
            videoID: 'ScaYipfbYxg'
        },
    }],
])

export const CouponsMap = new Map([
    ['Coupon Based Commissions', {
        AssignCoupons: {
            helpLink: 'https://docs.goaffpro.com/how-tos/assign-coupon-to-affiliate',
            videoID: '3D3TAUPlGqc'
        },
        AssignCustomCoupons: {
            helpLink: 'https://docs.goaffpro.com/how-tos/assign-coupon-to-affiliate/assign-custom-coupons',
            videoID: '8G-PhaKH4AY'
        }
    }],
    ['Automatic Coupons', {
        AutomaticCoupons: {
            helpLink: 'https://docs.goaffpro.com/how-tos/automatically-generate-coupons-for-affiliates',
            videoID: '9egC6ZdPFlc'
        },
        SetupAutomaticCoupons: {
            helpLink: 'https://docs.goaffpro.com/how-tos/automatically-generate-coupons-for-affiliates/change-discount-type-for-automatic-coupons',
            videoID: 'Az7CjknW-bc'
        },
        AutomaticCouponsStyleChange: {
            helpLink: 'https://docs.goaffpro.com/how-tos/change-style-of-generated-coupons',
            videoID: '3kfxB5YW8_s'
        },
        GenerateAutomaticCoupons: {
            helpLink: 'https://docs.goaffpro.com/how-tos/generate-coupons-for-affiliates',
            videoID: 't3Ui4TlGOi4'
        }
    }],
    ['Automatically Apply Discount', {
        AutomaticallyApplyDiscount: {
            helpLink: 'https://docs.goaffpro.com/how-tos/automatically-apply-discount-codes',
            videoID: 'VsT5jwcYfCA'
        }
    }],
    ['Personal Coupons', {
        PersonalCoupons: {
            helpLink: 'https://docs.goaffpro.com/how-tos/automatically-generate-personal-coupons-for-affiliates',
            videoID: 'W6D2BzfNZ9Q'
        },
        SetupPersonalCoupons: {
            helpLink: 'https://docs.goaffpro.com/how-tos/automatically-generate-personal-coupons-for-affiliates/change-discount-type-for-personal-coupons',
            videoID: '9VAsgbkYRIY'
        },
        PersonalCouponsStyleChange: {
            helpLink: 'https://docs.goaffpro.com/how-tos/change-style-of-personal-coupons',
            videoID: 'n2zbsjwuJ2U'
        },
        GeneratePersonalCoupons: {
            helpLink: 'https://docs.goaffpro.com/how-tos/generate-personal-coupons-for-existing-affiliates',
            videoID: '8OrAMVvAuHQ'
        }
    }],
])

export const TargetsMap = new Map([
    ['Target Bonus', {
        helpLink: 'https://docs.goaffpro.com/how-tos/set-target-for-affiliates',
        videoID: 's_9f4AjOIug'
    }],
    ['Commissions Booster', {
        helpLink: 'https://docs.goaffpro.com/how-tos/set-commission-booster-for-affiliates',
        videoID: 'sfWKoa6G8Gc'
    }],
    ['Signup Bonus', {
        helpLink: 'https://docs.goaffpro.com/how-tos/setup-signup-bonus-for-affiliates',
        videoID: 'HGOKv6dXT-s'
    }],
    ['Recurring Bonus', {
        helpLink: 'https://docs.goaffpro.com/how-tos/setup-recurring-bonus-for-affiliates',
        videoID: 'jTdAliax48k'
    }]
])

export const FormReferralsMap = new Map([
    //TODO - Video Missing
    ['Form Referrals', {
        helpLink: 'https://docs.goaffpro.com/how-tos/setup-referral-forms',
        videoID: ''
    }]
])

export const CreativesMap = new Map([
    ['Creatives', {
        helpLink: 'https://docs.goaffpro.com/how-tos/upload-creative-media',
        videoID: 'GSh6L3v6NdA'
    }]
])

export const BulkMailMap = new Map([
    ['Quick Email', {
        helpLink: 'https://docs.goaffpro.com/how-tos/send-email-to-affiliates',
        videoID: 'DIzwNZwSiYE'
    }]
])

export const SalesMap = new Map([
    ['All Sales', {
        AssignSaleToAffiliate: {
            helpLink: 'https://docs.goaffpro.com/how-tos/assign-sale-to-an-affiliate',
            videoID: 'lLk3RSJnLOM'
        },
        ManuallyCreateNewSale: {
            helpLink: 'https://docs.goaffpro.com/how-tos/create-a-sale-manually',
            videoID: '3Sga3PBr8g4'
        }
    }]
])

export const ConnectionsMap = new Map([
    ['Connected Customer', {
        helpLink: 'https://docs.goaffpro.com/how-tos/create-a-new-customer-affiliate-connect',
        videoID: 'mkFdshXtzoQ'
    }],
    ['Bulk Import', {
        helpLink: 'https://docs.goaffpro.com/how-tos/bulk-import-customer-affiliate-connections',
        videoID: 'muUUuHJlLzQ'
    }]
])

export const GroupsMap = new Map([
    ['New Group', {
        helpLink: 'https://docs.goaffpro.com/how-tos/create-commission-groups-for-affilates',
        videoID: 'hWqherQXg6c'
    }],
    ['Default Group', {
        helpLink: 'https://docs.goaffpro.com/how-tos/create-commission-groups-for-affilates/set-default-commission-group',
        videoID: 'XYaaufKwV3c'
    }],
    ['Edit Members', {
        helpLink: 'https://docs.goaffpro.com/how-tos/create-commission-groups-for-affilates/edit-members-in-commission-groups',
        videoID: '7n4DCVWo35g'
    }],
    ['Edit Group', {
        DefaultCommission: {
            helpLink: 'https://docs.goaffpro.com/how-tos/create-commission-groups-for-affilates/set-default-commission-for-group',
            videoID: 'hM-EIZAQhpc'
        },
        CommissionRules: {
            helpLink: 'https://docs.goaffpro.com/how-tos/create-commission-groups-for-affilates/set-commission-rules-for-group',
            videoID: 'S_P08dSN3B0'
        },
        ProductCommission: {
            helpLink: 'https://docs.goaffpro.com/how-tos/create-commission-groups-for-affilates/set-product-commission-for-group',
            videoID: 'Sl5LhHi2WzA'
        },
        //Collection Commission is not shown
        CollectionCommission: {
            helpLink: 'https://docs.goaffpro.com/how-tos/create-commission-groups-for-affilates/set-collection-commission-for-group',
            videoID: 'DvEatOZT3gs'
        },
        MultiLevelCommission: {
            helpLink: 'https://docs.goaffpro.com/how-tos/create-commission-groups-for-affilates/set-multi-level-commission-for-group',
            videoID: '1zUzY7cvS_k'
        },
        MLMProductCommission: {
            helpLink: 'https://docs.goaffpro.com/how-tos/create-commission-groups-for-affilates/set-multi-level-product-commission-for-group',
            videoID: 'LhLTT-LJ0Oo'
        },
        CommissionModifiers: {
            helpLink: 'https://docs.goaffpro.com/how-tos/create-commission-groups-for-affilates/set-commission-modifiers-for-group',
            videoID: '_Et4EoZuKJM'
        },
        CommissionBooster: {
            helpLink: 'https://docs.goaffpro.com/how-tos/create-commission-groups-for-affilates/set-commission-booster-for-group',
            videoID: 'N6gZ8XLMxe0'
        },
        TargetBonus: {
            helpLink: 'https://docs.goaffpro.com/how-tos/create-commission-groups-for-affilates/set-targets-for-group',
            videoID: '0kXeIWb5i2s'
        }
    }]
])

export const AffiliatePagesMap = new Map([
    ['Affiliate Pages', {
        helpLink: 'https://docs.goaffpro.com/goaffpro-admin/manage/affiliate-pages',
        videoID: 'ZzrgKHlEw14'
    }]
])

export const InfluencerSeedingMap = new Map([
    ['Influencer Seeding', {
        helpLink: 'https://docs.goaffpro.com/how-tos/create-order-for-affiliate',
        videoID: 'y4IBJoVimTw'
    }]
])

export const MembershipPlansMap = new Map([
    ['Payment Plans', {
        helpLink: 'https://docs.goaffpro.com/how-tos/setup-membership-plans/setup-payment-plan',
        videoID: 'olFw_AdGqlE'
    }]
])

export const PaypalPayoutsMap = new Map([
    ['Paypal Payouts', {
        helpLink: 'https://docs.goaffpro.com/how-tos/paypal-payouts-integration/pay-affiliates-using-paypal-payouts',
        videoID: 'EcD0IUzuupQ'
    }]
])

export const MultiLevelMarketingMap = new Map([
    //TODO - Video Missing
    ['Multi Level Marketing', {
        helpLink: 'https://docs.goaffpro.com/how-tos/setup-multi-level-network',
        videoID: ''
    }]
])

export const TipaltiPayoutsMap = new Map([
    ['Tipalti Payouts', {
        helpLink: 'https://docs.goaffpro.com/how-tos/tipalti-integration/pay-affiliates-using-tipalti',
        videoID: 'cTQO7zmCxyk'
    }]
])

export const PartyPlansMap = new Map([
    ['Party Plans', {
        helpLink: 'https://docs.goaffpro.com/how-tos/setup-party-plans',
        videoID: 'VtOD5Spc6ho'
    }]
])

export const SettingsMap = new Map([
    ['Notifications', {
        FromEmail: {
            helpLink: 'https://docs.goaffpro.com/how-tos/set-notification-email-address',
            videoID: '4VYKxSgAx9c'
        }
    }],
    ['Extras', {
        CustomerAffiliateConnect: {
            helpLink: 'https://docs.goaffpro.com/how-tos/commission-for-recurring-payments',
            videoID: 'KpCrZ2DtROw'
        },
        AffiliateStore: {
            helpLink: 'https://docs.goaffpro.com/how-tos/add-mini-store-to-affiliate-dashboard',
            videoID: null
        },
        AffiliatePublicStore: {
            helpLink: 'https://docs.goaffpro.com/how-tos/setup-affiliate-public-store',
            videoID: 'v5f3XRJyuZY'
        },
        MembershipFee: {
            helpLink: 'https://docs.goaffpro.com/how-tos/setup-membership-plans',
            videoID: 'e3emm_9iqCc'
        },
        PaymentGateway: {
            helpLink: 'https://docs.goaffpro.com/how-tos/setup-membership-plans/setup-payment-gateway',
            videoID: 'wqGAF03m2qA'
        },
        W9Form: {
            helpLink: 'https://docs.goaffpro.com/how-tos/w9-form-for-affiliates',
            videoID: 'u52CM4eeZ14'
        },
        Invoices: {
            helpLink: 'https://docs.goaffpro.com/how-tos/invoices-for-affiliate-payments',
            videoID: 'j_IJ7srrQ-M'
        },
        LegalDocuments: {
            helpLink: 'https://docs.goaffpro.com/how-tos/create-legal-documents',
            videoID: '3dJnQ0ZLd3M'
        },
        ManagerAccounts: {
            helpLink: 'https://docs.goaffpro.com/how-tos/create-a-manager-account',
            videoID: '4MLnTsPGW7E'
        },
        Scripts: {
            helpLink: 'https://docs.goaffpro.com/goaffpro-admin/manage/scripts',
            videoID: 'j3M6zUBHnxg'
        },
        //TODO - Video Missing
        EventCallbacks: {
            helpLink: 'https://docs.goaffpro.com/how-tos/http-callbacks-for-events-from-app',
            videoID: ''
        }
    }],
    ['Integrations', {
        Tipalti: {
            helpLink: 'https://docs.goaffpro.com/how-tos/tipalti-integration',
            videoID: 'WIZNd_BGMW0'
        },
        PaypalPayouts: {
            helpLink: 'https://docs.goaffpro.com/how-tos/paypal-payouts-integration/setup-paypal-payouts',
            videoID: '9Gc6t7MLoBI'
        },
        GoogleRecaptcha: {
            helpLink: 'https://docs.goaffpro.com/how-tos/recaptcha-verification-for-signup-page',
            videoID: 'ryDE8aNQC5E'
        },
        AccountSync: {
            helpLink: 'https://docs.goaffpro.com/how-tos/register-affiliate-accounts-as-store-customers',
            videoID: 'V_tsAbE7n98'
        },
        MailChimp: {
            helpLink: 'https://docs.goaffpro.com/how-tos/mailchimp-integration',
            videoID: 'bA_aj0JgeuM'
        },
        Omnisend: {
            helpLink: 'https://docs.goaffpro.com/how-tos/omnisend-integration',
            videoID: 'RH9MzuUVc6s'
        },
        Klaviyo: {
            helpLink: 'https://docs.goaffpro.com/how-tos/klaviyo-integration/setup-klaviyo-integration',
            videoID: 'P4_Rmr-pEC4'
        },
        ConvertKit: {
            helpLink: 'https://docs.goaffpro.com/how-tos/convertkit-integration',
            videoID: 'QqC9TmKNOVk'
        },
        MailerLite: {
            helpLink: 'https://docs.goaffpro.com/how-tos/mailerlite-integration',
            videoID: 'DDAn-f5LEOc'
        },
        Hubspot: {
            helpLink: 'https://docs.goaffpro.com/how-tos/hubspot-integration',
            videoID: 'PUxsmau6l3k'
        },
        ActiveCampaign: {
            helpLink: 'https://docs.goaffpro.com/how-tos/activecampaign-integration',
            videoID: 'iVjr59vnJh8'
        },
        CampaignMonitor: {
            helpLink: 'https://docs.goaffpro.com/how-tos/campaignmonitor-integration',
            videoID: 'AxNamdl-P10'
        },
        ThirdPartyCheckout: {
            helpLink: 'https://docs.goaffpro.com/how-tos/third-party-checkout-integration',
            videoID: 'y94QFag0B7k'
        },
        BoldSubscription: {
            helpLink: 'https://docs.goaffpro.com/how-tos/bold-subscription-integration',
            videoID: 'XnJ80DS26L4'
        },
        ZipifyOCU: {
            helpLink: 'https://docs.goaffpro.com/how-tos/zipify-ocu-integration',
            videoID: 'RROhTTMQMq8'
        },
        RechargePayments: {
            helpLink: 'https://docs.goaffpro.com/how-tos/recharge-payments-integration',
            videoID: 'uva9Zyme8-Q'
        },
        SquareCheckout: {
            helpLink: 'https://docs.goaffpro.com/how-tos/square-checkout-integration',
            videoID: 'O6vkZ00lCqY'
        },
        PayWhirl: {
            helpLink: 'https://docs.goaffpro.com/how-tos/paywhirl-integration',
            videoID: '5tUQkLXWy5c'
        }
    }],
    ['Advanced', {
        DefaultReferralLink: {
            helpLink: 'https://docs.goaffpro.com/how-tos/set-default-referral-link',
            videoID: 'KRX9vTXCQQI'
        },
        ReferralLinkParameters: {
            helpLink: 'https://docs.goaffpro.com/how-tos/set-referral-link-parameters',
            videoID: 'YMhLnup2C0E'
        },
        DefaultNetworkLink: {
            helpLink: 'https://docs.goaffpro.com/how-tos/set-default-network-link',
            videoID: 'SLOQzZysGS0'
        },
        BlockedIPOrigins: {
            helpLink: 'https://docs.goaffpro.com/how-tos/block-commission-from-domains',
            videoID: '50h6FnhkFQQ'
        },
        AffiliateConflictResolution: {
            helpLink: 'https://docs.goaffpro.com/how-tos/affiliate-conflict-resolution',
            videoID: '0Dyjr-3-vV0'
        },
        GenerateShortenedLinks: {
            helpLink: 'https://docs.goaffpro.com/how-tos/generate-shortened-links-with-shopify-shortener',
            videoID: 'eNk_ldW9hV4'
        },
        ReferralCodeStyle: {
            helpLink: 'https://docs.goaffpro.com/how-tos/set-referral-code-style',
            videoID: 'wCbOWyBKOeM'
        },
        ReferralLinkIdentifiers: {
            helpLink: 'https://docs.goaffpro.com/how-tos/set-referral-link-identifiers',
            videoID: '9wzlDxndSp4'
        },
        MultipleReferralCodes: {
            helpLink: 'https://docs.goaffpro.com/how-tos/enable-multiple-referral-codes-for-affiliates',
            videoID: 'Vh1w28KlemA'
        },
        AMPPagesTracking: {
            helpLink: 'https://docs.goaffpro.com/how-tos/setup-tracking-for-amp-pages',
            videoID: 'SE0JLClKeJA'
        },
        ThirdPartyTracking: {
            helpLink: 'https://docs.goaffpro.com/how-tos/third-party-tracking',
            videoID: '869FGhWg1gw'
        },
        ConversionPreferenceOrder: {
            helpLink: 'https://docs.goaffpro.com/how-tos/set-conversion-preference-order',
            videoID: 'fCqKqDHPkyg'
        },
        CrossDomainTracking: {
            helpLink: 'https://docs.goaffpro.com/how-tos/setup-cross-domain-tracking',
            videoID: null
        },
        AutoRefLinking: {
            helpLink: 'https://docs.goaffpro.com/how-tos/automatically-add-referral-code-to-outgoing-links',
            videoID: 'N6F1Mhq_6Fw'
        },
        AutoApproveAffiliates: {
            helpLink: 'https://docs.goaffpro.com/how-tos/setup-auto-approve-affiliates-source',
            videoID: 'XVdlRtjH7Zw'
        },
        OrderTags: {
            helpLink: 'https://docs.goaffpro.com/how-tos/add-extra-information-using-order-tags',
            videoID: 'tQ-dSN_yoKc'
        },
        CustomerTags: {
            helpLink: 'https://docs.goaffpro.com/how-tos/add-additional-tags-to-an-affiliates-customer',
            videoID: 'JzirXfc3r-c'
        },
        GiveIndividualCodes: {
            helpLink: 'https://docs.goaffpro.com/how-tos/generate-individual-discount-codes',
            videoID: 'gFpygsnf1JY'
        },
        SaleReporting: {
            helpLink: 'https://docs.goaffpro.com/how-tos/setup-sales-reporting',
            videoID: 'BxHL56RYFBI'
        },
        ExtraFields: {
            helpLink: 'https://docs.goaffpro.com/how-tos/add-extra-fields-in-affiliate-admin-page',
            videoID: 'rMBk99Nwz1Q'
        },
        SingleSignOn: {
            helpLink: 'https://docs.goaffpro.com/how-tos/affiliate-account-access-from-shopify-accounts-page',
            videoID: 'RJPA7KsguwE'
        },
        AffiliatePortalAlias: {
            helpLink: 'https://docs.goaffpro.com/how-tos/setup-affiliate-portal-aliases',
            videoID: 'f7Rp8NCvXSQ'
        },
        AccessTokens: {
            helpLink: 'https://docs.goaffpro.com/how-tos/generate-api-keys-custom-integration-with-goaffpro-app',
            videoID: 'WqjJ83RLm_I'
        },
        TwoFactorAuthentication: {
            helpLink: 'https://docs.goaffpro.com/how-tos/setup-two-factor-authentication',
            videoID: 'mytFhS5yzIQ'
        },
        TrackingScriptCache: {
            helpLink: 'https://docs.goaffpro.com/how-tos/set-tracking-script-cache-duration',
            videoID: 'Pt67oIZGVls'
        },
        ProgramStatus: {
            helpLink: 'https://docs.goaffpro.com/how-tos/disable-affiliate-program',
            videoID: 'em0Lo8aEJVQ'
        },
    }]
])