import React from 'react';
import CopyBasicTrackingCode from './CopyBasicTrackingCode';
import CopyButton from '../../../../components/CopyButton';

function ThrivecartIntegrationSteps(props) {
  const conversionTracking = `<script type="text/javascript">
        window.goaffpro_order = {
            id:_thrive_order.order.id,
            number:_thrive_order.order.invoice_id,
            total: _thrive_order.order.total/100,
            tax: _thrive_order.order.tax/100,
            shipping: _thrive_order.order.shipping,
            currency:_thrive_order.order.currency,
            customer: {
                first_name: _thrive_order.customer.name,
                email:_thrive_order.customer.email
            },
        }
        if(typeof goaffproTrackConversion !== "undefined"){
          goaffproTrackConversion(window.goaffpro_order);
        }        
      </script>`
  return (
      <>
        <h4>Step 1</h4>
        <p>Add the following code in your store header area</p>
        <CopyBasicTrackingCode />
        <h4>Step 2</h4>
        <p>Copy and Paste the following code in your Success page / Thank you page</p>
        <>
          <textarea value={conversionTracking} className={"form-control"} readOnly></textarea>
          <CopyButton text={conversionTracking} copyBtnText={"Copy"} className={'btn btn-outline-primary mt-2 btn-sm'} />
        </>
      </>
  );
}

export default ThrivecartIntegrationSteps;
