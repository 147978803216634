import React from "react";
import Label from "./Label";

function FormGroup({children, label, required, htmlFor, info}: {
    children: React.ReactNode,
    label?: string,
    required?: boolean,
    htmlFor?: string,
    info?: string | React.ReactNode
}) {
    return <div className="form-group">
        {
            label && <><Label title={label} htmlFor={htmlFor}/>{required &&
                <small className={"ml-1 text-danger"}>*</small>}</>
        }
        {children}
        {
            info && <small className={"text-muted"}>{info}</small>
        }
    </div>
}

export default FormGroup
