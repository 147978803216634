import React, {Component} from 'react';
import PropTypes from 'prop-types';

class Youtube extends Component {

    render() {
        const {videoId} = this.props;
        return (
            <div
                className="video"
                style={{
                    position: "relative",
                    paddingBottom: "56.25%" /* 16:9 */,
                    paddingTop: 25,
                    height: 0
                }}
            >
                <iframe id="ytplayer" style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%"
                }} src={`https://www.youtube.com/embed/${videoId}?autoplay=${this.props.autoplay === undefined ? 1 : this.props.autoplay}`} title={"Youtube video"} allow={"fullscreen; autoplay;"} frameBorder="0"/>
            </div>
        );
    }
}


Youtube.propTypes = {
    videoId: PropTypes.string.isRequired,
};

export default Youtube;
