import React, {Component, useState} from 'react';
import Label from "../../../components/form/Label";
import Button from "../../../components/form/Button";
import Youtube from "../../../components/video/Youtube";
import FormGroup from "../../../components/form/FormGroup";
import {getHttpClient} from "../../../api";
import {connect} from "react-redux";
import settingsDispatchToProps from "../../settings/premiumextras/settingsDispatchToProps";
import settingsStateToProps from "../../settings/settingsStateToProps";
import useObjectState from '../../../components/hooks/useObjectState';
import {useNavigate} from 'react-router-dom';
import Input from '../../../components/form/Input';

function SetCredentials({updateSettings, website_match}){
    const [data, setData] = useObjectState({
        consumer_key:'',
        consumer_secret: ''
    });
    const navigate = useNavigate()
    const [saving, setSaving] = useState(false)
    const [error, setError] = useState(null)
    const onSubmit = ()=>{
        const {consumer_key, consumer_secret} = data;
        setSaving(true)
        updateSettings({
            consumer_key, consumer_secret
        }).then(()=>{
            return getHttpClient().get('/woocommerce/is_installed?shop='+website_match)
            .then(({data})=>{
                setSaving(false)
                if(data.access_token){
                    navigate(-1)
                }else{
                    setError('Invalid keys')
                }
            })
        })
    }
     return (
            <div className={'container-fluid mt-4'}>
                <div className={"card-group"}>
                <div className={"card"}>
                    <div className="card-body">
                    <FormGroup label={'Consumer Key - Read/Write'}>
                        <Input value={data.consumer_key} onChange={setData('consumer_key')} placeholder={"API Key"} />
                    </FormGroup>
                    <FormGroup>
                        <Label title="Consumer Secret - Read/Write"/>
                        <input value={data.consumer_secret} onChange={setData('consumer_secret')} placeholder={"API Secret"}/>
                    </FormGroup>
                        <Button onClick={onSubmit} disabled={!data.consumer_secret || !data.consumer_key} loading={saving} className="btn btn-primary">Submit</Button>
                        {
                            error && <p className={"alert mt-2 alert-danger"}>{error}</p>
                        }
                    </div>
                </div>
                    <div className="card">
                        <Youtube videoId={"It2mpuLcMI0"} />
                    </div>
                 </div>
            </div>
        );
}

export default connect(settingsStateToProps(['website_match']), settingsDispatchToProps)(SetCredentials);
