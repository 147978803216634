import React, {useEffect} from 'react';
import ProgressLoader from '../../components/ProgressLoader';
import {getHttpClient} from '../../api';
import {getCurrentQueryObject} from '../../utils/func';
import {connect} from 'react-redux';
import {actionMergeSetting} from '../../redux/settings/settingsAction';
import {useNavigate} from 'react-router-dom';

function ActivateCharged ({updateSubscription}){
    const navigate = useNavigate()

    useEffect(() => {
        const {charge_id} = getCurrentQueryObject();
        getHttpClient().post('/billing/activate',{
            charge_id
        }).then(({data})=>{
            const {id} = data;
            updateSubscription(id);
            navigate('/subscriptions',{
                replace:true
            });
        })
    },[navigate, updateSubscription])
     return (
        <UpgradeContainer>
            <h4 className={"text-center"}>Just a moment</h4>
            <div className={"text-center"}><ProgressLoader /></div>
        </UpgradeContainer>
    );
}


function UpgradeContainer({children}){
    return <div className={"container-fluid bg-light"}>
        <div className={"row min-vh-100 align-items-center justify-content-center"}>
            <div className={"col-md-4"}>
                <div className={"card"}>
                    <div className={"card-body"}>
                        {children}
                    </div></div></div>
        </div></div>
}

const mapDispatchToProps = (dispatch, props)=>{
    return {
        updateSubscription:(subscription)=>dispatch(actionMergeSetting({subscription}))
    }
};

export default connect(null, mapDispatchToProps)(ActivateCharged);
