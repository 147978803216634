import React, {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {updateSetting} from '../../../../redux/settings/settingsAction';
import ReactSwitch from 'react-switch';

function ShopbaseIntegration(props) {
  const dispatch = useDispatch();
  const shopbase_checkout_enabled = useSelector(
      (state) => state.settings.shopbase_checkout_enabled);
  const onChange = useCallback((shopbase_checkout_enabled) => {
    dispatch(updateSetting({
      shopbase_checkout_enabled,
    }));
  }, [dispatch]);
  return (
      <>
        <ReactSwitch checked={shopbase_checkout_enabled} onChange={onChange}/>
      </>
  );
}

export default ShopbaseIntegration;
