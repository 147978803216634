import createApp from '@shopify/app-bridge';
import {Redirect} from '@shopify/app-bridge/actions';
import { getSessionToken } from "@shopify/app-bridge/utilities";
const searchParams = new URLSearchParams(document.location.search);
const host = searchParams.get("host");
const config = {
  // The client ID provided for your application in the Partner Dashboard.
  apiKey: "eda11fcd576a556494594ddd4660db71",
  // The host of the specific shop that's embedding your app. This value is provided by Shopify as a URL query parameter that's appended to your application URL when your app is loaded inside the Shopify admin.
  host,
  forceRedirect:false,
};
window.shopify_app = host ? createApp(config) : null

const redirect = window.shopify_app ? Redirect.create(window.shopify_app) : null;
export function getShopifyApp(){
  return window?.shopify_app;
}
export function shopifyRedirectTo(location){
    if(!redirect || window.top === window.self){
      window.location = location
    }else {
      redirect.dispatch(Redirect.Action.REMOTE, location);
    }
}

export function redirectToApp(){
  if(!redirect){
    createApp({

    })
  }
  if(redirect){
    redirect.dispatch(Redirect.Action.APP,"/")
  }else{
    window.location = "/"
  }
}

export function shopifyOpenShopMenu(shop){
  if(redirect){
    redirect.dispatch(Redirect.Action.ADMIN_PATH,"/menus");
  }else{
    window.open(`https://${shop}/admin/menus`)
//    window.top.location = location;
  }
}

export function getShopifySessionToken(){
  return getShopifyApp() ? getSessionToken(getShopifyApp()) : null
}
