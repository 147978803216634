import React, {Component} from 'react';
import SettingSection from "../../components/SettingSection";
import ReactSwitch from "react-switch";
import {updateSetting} from "../../../../redux/settings/settingsAction";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {getHttpClient} from "../../../../api";
import {SettingsMap} from "../../../../components/VideoAndHelpSectionMap";

class MailerliteSection extends Component {


    state = {
        show_modal:false,
    }
    closeModal = ()=>{
        this.setState({show_modal:false});
    }

    showModal = ()=>{
        this.setState({show_modal:true});
    }

    disconnect = (e)=>{
        this.props.updateSetting({mailerlite_sync_enabled: e})
        if(e){
           this.resync();
        }
    }

    resync = ()=>{
        this.setState({syncing:true, syncError:false, syncComplete:false})
        getHttpClient().post('/integrations/mailerlite/do_initial_sync').then((e)=>{
            this.setState({syncing:false, syncComplete:true});
            setTimeout((e)=>{
                this.setState({syncCompleted:false})
            }, 3000)
        }).catch((e)=>{
            this.setState({syncing:false, syncError:true})
        })
    }


    render() {
        const setupBtn = this.props.subscription ?
            <Link to={"/settings/integrations/mailerlite/configure"} className={"btn btn-secondary"}>Setup</Link> :
            <Link to={"/subscriptions"}>Upgrade to Sync</Link>

        return (
            <SettingSection title={"MailerLite"} helpLink={SettingsMap.get('Integrations').MailerLite.helpLink} videoID={SettingsMap.get('Integrations').MailerLite.videoID} subtitle={"Sync affiliate data with MailerLite"}>
                {
                    this.props.mailerlite_api_key ?
                        <div>
                            <ReactSwitch checked={this.props.mailerlite_sync_enabled} onChange={this.disconnect}  />
                            <br/>
                            <p className={"lead"}>Syncing to: {this.props.mailerlite_list_name}</p>
                            {
                                this.state.syncing && <p className={"text-success"}>Syncing...</p>
                            }
                            {
                                this.state.syncError && <p className={"text-danger"}>Sync Error</p>
                            }
                            {
                                this.state.syncComplete && <p className={"text-success"}>Sync Completed</p>
                            }
                            <div className="d-flex align-items-center">
                                <Link to={"/settings/integrations/mailerlite/configure"}>Re-Configure</Link>
                                <span className={"mx-4"}>|</span>
                                <button className={"btn btn-link"} onClick={this.resync}>Re-Sync</button>
                            </div>
                        </div>:
                        setupBtn
                }
            </SettingSection>
        );
    }
}

function mapDispatchToProps(dispatch){
    return {
        updateSetting:(change)=>dispatch(updateSetting(change))
    }
}

function mapStateToProps(state){
    return {
        mailerlite_api_key: state.settings.mailerlite_api_key,
        mailerlite_list_name: state.settings.mailerlite_list && JSON.parse(state.settings.mailerlite_list).name,
        mailerlite_sync_enabled: state.settings.mailerlite_sync_enabled,
        subscription: state.settings.subscription === 1,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MailerliteSection);
