import {useSelector} from "react-redux";
import {useCallback, useEffect} from "react";
import {ReduxStore, SettingsTypes} from "../redux/settings/SettingsTypes";
declare global {
    interface Window{
        Tawk_API:{
            setAttributes:(attribs:any, callback:any)=>void
        }
    }
}
export default function TawkToLoader(){
    const {owner_name, owner_email, site_id, store_name, integration, website} = useSelector<ReduxStore, Partial<SettingsTypes>>((state)=>{
        const {owner_name, owner_email, site_id, store_name, integration, website} = state.settings
        return {
            owner_name, owner_email, site_id, store_name, integration, website
        }
    })
    const setAttribs = useCallback(()=>{
        try{
            if (window.Tawk_API && site_id) {
                window.Tawk_API.setAttributes({
                    name: owner_name,
                    email: owner_email,
                    oa720sw9cn: site_id ? Number(site_id) : undefined,
                    ectujru91i: store_name || undefined,
                    website: website?.startsWith('https') ? website : `https://${website}`,
                    s2evqxsxmv: integration,

                }, function(error) {
                    console.log({error})
                })
                return true;
            }
        }catch(e){

        }
    },[owner_name, owner_email,  integration, site_id, website, store_name])
    useEffect(()=>{
        if(!setAttribs()){
            const t = setInterval(function(){
                if(setAttribs()){
                    clearInterval(t)
                }
            },3000)
            return ()=>{
                clearTimeout(t)
            }
        }
    },[setAttribs])
    return null;
}
