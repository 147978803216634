import React, {Component} from 'react';
import PropTypes from 'prop-types';

//TODO enhance progressbar to be indeterminate when progress = 0 and transform to normal when progress > 0
function RealProgressBar ({max, progress, showProgressLabel}:{max?: number, progress: number, showProgressLabel?: boolean}){
    const m = max || 100;
        return (
            <div className="progress" style={{width:"100%"}}>
                <div className="progress-bar progress-bar-animated progress-bar-striped "
                     role="progressbar" style={{width: `${(progress * 100)/m}%`}}
                     aria-valuenow={progress}
                     aria-valuemin={0}
                     aria-valuemax={m}>
                    {
                        showProgressLabel ? `${progress}/${m}` : null
                    }
                </div>

            </div>
        );
}

RealProgressBar.defaultProps = {
    max:100,
    showProgressLabel:false
}

export default RealProgressBar;
