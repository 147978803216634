// this file ensures that access token once set for a page is permanently set
// this can serve as a standby until scoping by URL can be established
import Cookies from 'js-cookie';
const key = 'access_token';
let access_token = null;
try{
  access_token = localStorage.getItem(key)
}catch(e){

}
if(!access_token){
  try{
    access_token = Cookies.get(key)
  }catch(e){

  }
}
export const setAccessToken = (token) => {
  access_token = token;
  try {
    window.dispatchEvent(new CustomEvent('on_access_token_set', {detail: {access_token: token}}))
  }catch(e){

  }
  try {
    if(token) {
      localStorage.setItem(key, token)
    }else{
      localStorage.removeItem(key)
    }
  }catch(e){
    // console.log('got error')
    // console.log(e)
  }
  try {
    if(token) {
      Cookies.set(key, token,{ expires: 7 });
    }else{
      Cookies.remove(key)
    }

  }catch(e){
//    console.log(e)
  }
};


export const deleteAccessToken = ()=>{
  access_token = undefined;
  try {
    localStorage.removeItem(key)
  }catch(e){
//    console.log(e)

  }
  try {
    Cookies.remove(key);
  }catch(e){
  //  console.log(e)
  }
}
export const getAccessToken = () => {
  // console.log('getting from var cache', access_token)
  if(access_token) {
    return access_token
  }
// else if(store.getState()?.settings?.access_token) return store.getState().settings.access_token
  try{
    // console.log('getting from localstorage', localStorage.getItem(key))
    if(localStorage.getItem(key)) return localStorage.getItem(key)
  }catch(e){

  }
  try{
    // console.log('gettin g from cookies', Cookies.get(key))
    return Cookies.get(key)
  }catch(e){

  }

};
