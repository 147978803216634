import StartContainer from '../../login/StartContainer';
import {WebflowLogo} from '../PlatformLogos';
import React from 'react';

export default function Wrapper({children}) {
  return <StartContainer title={'Goaffpro + Webflow integration'}>
    <div className="d-flex align-items-center justify-content-center mb-4">
      <WebflowLogo/></div>
    <div className="card">
      <div className="card-body">
        <div className="card-title text-center">Integrate Goaffpro with Webflow
          store
        </div>
        {children}
      </div>
    </div>
  </StartContainer>;
}
