import React from 'react';
import CustomSdkIntegrationSteps from "./CustomSdkIntegrationSteps";

export default function CustomSdkIntegration(){
        return (
            <div className={"bg-light"}>
                <div className={"container-fluid "}>
                    <div className={"row justify-content-center min-vh-100"} style={{paddingTop:'80px'}}>
                        <div className={"col-lg-6 col-md-8"}>
                <h1 className="text-center">Integration instructions</h1>
                <div className="card">
                    <div className="card-body">
                        <CustomSdkIntegrationSteps/>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
    );
}
