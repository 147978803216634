import React, {useMemo} from 'react';
import CopyButton from '../../../../components/CopyButton';
import useThirdPartyTrackingScript
  from '../../../../components/hooks/useThirdPartyTrackingScript';

function ThinkificIntegrationSteps(props) {
  const code = useThirdPartyTrackingScript();
  const trackingCode = useMemo(()=>{
    return `<script type="text/javascript">
    (function(){
      const script = document.createElement('script');
      script.type = "text/javascript"
      script.src = "${code}";
      document.head.appendChild(script);
    })()
    </script>`
  },[])

  const conversion_code = `<script type="text/javascript">
    window.goaffpro_order = {
       number:"{{order_number}}",
       total:"{{total_price}}",
       ref: "{{referral_code}}",
       customer: {
          name:"{{billing_name}}",
          email:"{{billing_email}}",
       },
       line_items:[
         {
            name:"{{product_name}}",
            product_id:"{{product_id}}",
            price:"{{total_price}}",
            quantity:1,
         }
       ]
  }
    if(typeof goaffproTrackConversion !== "undefined"){
      goaffproTrackConversion(window.goaffpro_order);
    }
</script>`;
  return (
      <>
        <a href={"/images/thinkific/TrackingCode.jpg"} target={"_blank"}>
          <img src={"/images/thinkific/TrackingCode.jpg"} className={"w-100"} />
        </a>
        <br/>
        <br/>
        <ol>
          <li>
            Click Settings in the blue menu on the left-hand side of your dashboard
          </li>
          <li>
            Select the Code & analytics tab from the top of the page
          </li>
          <li>
            Select Site footer code from the left-hand menu
          </li>
          <li>
            Copy and paste the following code in the text area. Then click Save to save changes
            <textarea className={"form-control"} value={trackingCode}/>
            <CopyButton text={trackingCode} className={"btn mt-2 btn-outline-primary btn-sm"} copyBtnText={"Copy code"}/>
          </li>

          <li>
            Next, select Order tracking code from the left-hand menu
          </li>
          <li>
            Then, copy and paste the following code in the text area. Then click Save to save changes
            <textarea className={"form-control"} value={conversion_code}/>
            <CopyButton text={code} className={"btn mt-2 btn-outline-primary btn-sm"} copyBtnText={"Copy code"}/>
          </li>
        </ol>
      </>
  );
}

export default ThinkificIntegrationSteps;
