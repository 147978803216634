import React from 'react';
import './App.scss';
import App from './App';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {Provider, useSelector} from 'react-redux';
import store from './redux/store';
import {loadSettings} from './redux/settings/settingsAction';
import {IntlProvider} from 'react-intl';
import {removeItem, setItem} from './utils/storage';
import {ConfirmationProvider} from './components/ConfirmModal';
import ScrollToTop from './components/ScrollToTop';
import IframeCookieFix from './pages/shopify/IframeCookieFix';
import * as en from './locales/en.js';
import ShopifyBillingActivate from './pages/billing/shopify/ShopifyBillingActivate';

/*
const AdminApp = () =>(
    <ErrorBoundary>
        <IntlProvider locale={"en"} messages={require('./locales/en.js')}>
                <Provider store={store}>
                    <Router history={history}>
                        <ConfirmationProvider>
                        <App />
                        </ConfirmationProvider>
                    </Router>
                </Provider>
        </IntlProvider>
</ErrorBoundary>)
 */


const ReactGA = React.lazy(()=>import('./components/ReactGALoader'))

// if (store.getState().settings.access_token) {
//   store.dispatch(loadSettings()).then(() => {
//     if (store.getState().settings) {
//       const {website_match, subscription} = store.getState().settings;
//       ReactGA.set({shop: website_match, subscription});
//     }
//   });
// }


/*
Temporarily turned off

const history = require('history').createBrowserHistory()
const unlisten = history.listen((location, action) => {
    // location is an object like window.location
    ReactGA.pageview(location.pathname + location.search);
//    console.log(action, location.pathname, location.state);
});

 */

/*
var bugsnagClient = process.env.NODE_ENV === 'development' ? bugsnag('s') : bugsnag({apiKey:'cb089721c41136f29259760e46725dbe',
    beforeSend: function (report) {
        // Add additional diagnostic information
        report.updateMetaData('account', {
            site_id: store.getState().settings.site_id,
        })
    }
})
bugsnagClient.use(bugsnagReact, React)
var ErrorBoundary = bugsnagClient.getPlugin('react')
 */
//remove multi site token from storage if
try {
  // catch the error in case the pathname is not accessible due to CSP policies of the host iframe
  if (window.top && window.top.location.pathname !== '/master') {
    removeItem('multi_site_access_token');
    console.log('removing multi site token');
  }
} catch (e) {
//  console.log(e);
}
window.addEventListener('message', function(message) {
  if (message.data.access_token) {
    setItem('access_token', message.data.access_token);
    if (message.data.multi_site_access_token) {
      setItem('multi_site_access_token', message.data.multi_site_access_token);
    }
    store.dispatch({
      type: 'update-settings',
      data: {
        access_token: message.data.access_token,
        connectedSites: message.data.sites,
        multi_site_access_token: message.data.multi_site_access_token,
      },
    });
    store.dispatch(loadSettings());
  }
});
const AdminApp = () => (
      <Provider store={store}>
       <TimezonedApp />
    </Provider>
);
const reducer = (state)=>state.settings.timezone;
function TimezonedApp(){
//  const timezone = useSelector(reducer)
  return  <IntlProvider
//      timeZone={timezone}
      locale={'en'}
      messages={en.default}>
    <BrowserRouter>
      <React.Suspense>
        <ReactGA />
      </React.Suspense>
      <ConfirmationProvider>
        <Routes>
          <Route path={'/t/:access_token?'} element={<IframeCookieFix/>}/>
          <Route path={'/billing/shopify/activate'} element={<ShopifyBillingActivate/>}/>
          <Route path={"/*"} element={<App />} />
        </Routes>
        <ScrollToTop />
      </ConfirmationProvider>
    </BrowserRouter>
  </IntlProvider>
}

export default AdminApp;
