import React, {useCallback, useState} from 'react';
import {FaEyeSlash, FaEye} from 'react-icons/fa';

function PasswordInput({value, onChange, id, name}:{value: string, onChange: (newValue: string)=>void, id?: string, name?: string}) {

  const changed = useCallback((e)=>{
      onChange(e.target.value)
  },[onChange])

  const [type, setType] = useState('password')
  return (
      <div className={"position-relative"}>
        <span onClick={()=>setType((t)=> t==="password" ? "text" : "password")} className={"text-muted position-absolute d-inline-flex align-items-center justify-content-center px-2"} style={{right:0, bottom:0, top:0}}>
          {
            type === "password" ? <FaEyeSlash/> : <FaEye/>
          }
        </span>
        <input
            // autoComplete={"new-password"}
            id={id} name={name} className={'form-control pr-4'}
            type={type} value={value} onChange={changed}/>
      </div>
  );
}

export default PasswordInput;
