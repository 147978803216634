
export function PrestaShopLogo({size}){
    return <div className="d-flex align-items-center">
        <img alt={"Install on Prestashop store"} src={"/images/platform-logos/prestashop.svg"} style={{height:size, marginRight:6}}/>
        <strong style={{fontSize:size/2 + 5, color:'#281143'}}>Presta<span style={{color:'#DF1668'}}>Shop</span></strong>
    </div>
}
export function CommerceHQLogo({size}){
    return <div className="d-flex align-items-center">
        <img alt={"Install on CommerceHQ store"} src={"/images/platform-logos/commerce-hq.svg"} style={{height:size, marginRight:6}}/>
        <span style={{fontSize:size/2 + 5}}>COMMERCE<strong>HQ</strong></span>
    </div>
}
export function ShopWiredLogo({size}){
    return   <span className={"italic"} style={{fontSize:size/2 + 5}}>Shop<span style={{color:'#5CA7D7'}}>Wired</span></span>
}



ShopWiredLogo.defaultProps = {
    size:40
}
CommerceHQLogo.defaultProps = {
    size:40
}
PrestaShopLogo.defaultProps = {
    size: 40
}

export function ShopifyLogo(props){
    return <SvgLogo alt={"Install on shopify store"} src="shopify" {...props}/>
}

function SvgLogo({src, alt, size, width, height}){
    return <img alt={alt} src={`/images/platform-logos/${src}.svg`} className={"inline-block"} style={{ height: size || 40}}/>

}

export function EcwidLogo(props = {}){
    return <SvgLogo alt={"Install on ecwid stores"} src="ecwid" {...props}/>

}
export function WebflowLogo(props = {}){
    return <SvgLogo alt={"Install on webflow store"} src="webflow" {...props}/>
}
export function MagentoLogo(props = {}){
    return <SvgLogo alt={"Install on magento store"} src="magentoLogo" {...props}/>
}
export function BigCommerceLogo(props = {}){
    return <SvgLogo alt={"Install on bigcommerce store"} src="bigcommerce" {...props}/>
}


export function WooCommerceLogo(props = {}){
    return <SvgLogo alt={"Install on woocommerce store"} src="woocommerce-logo" {...props}/>
}

export function WixLogo(props = {}){
    return  <SvgLogo src="wix" alt={"Install on Wix store"} {...props}/>
}
export function WeeblyLogo(props = {}){
    return  <SvgLogo src="weebly" alt={"Install on Weebly store"} {...props}/>
}
export function SdkLogo({size}){
    return <div className="d-flex align-items-center">
        <img alt={"Custom SDK Installation"} src={"/images/platform-logos/sdk.svg"} style={{height:Number(size), marginRight:6}}/>
        <strong style={{fontSize:Number(size)/2 + 5}}>CUSTOM SDK</strong>
    </div>
}

SdkLogo.defaultProps = {
    size:40
}

export function SquarespaceLogo({size}){
    return <div className="d-flex align-items-center">
        <img alt={"Install on Squarespace store"} src={"/images/platform-logos/squarespace.svg"} style={{height:size, marginRight:6}}/>
        <strong style={{fontSize:size/2 + 5}}>SQUARESPACE</strong>
    </div>
}
SquarespaceLogo.defaultProps = {
    size: 40
}
