import React from 'react';
import AdminApp from './index.admin-app'
import { createRoot } from 'react-dom/client';
import {
  deleteAccessToken,
  getAccessToken,
} from './utils/access_token_handler';
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
if(getAccessToken() === "undefined"){
  deleteAccessToken()
}
root.render(<AdminApp />);
/* strict mode is breaking react-router v5
root.render(<React.StrictMode>
  <AdminApp />
</React.StrictMode>
);

 */
