import React from 'react';
import SettingsSection from '../components/SettingSection';
import useToggle from '../../../components/hooks/useToggle';
import Modal from '../../../components/Modal';
import {useSelector} from 'react-redux';
import VimeoOttInstructions
    from '../../installers/sdk/integration-instructions/VimeoOttInstructions';

export default function VimeoOTT(props) {
    const website_match = useSelector((state) => state.settings.website_match);

    const [visible, toggle] = useToggle();
    return (
        <SettingsSection title={'Vimeo OTT'}
                         subtitle={'Connect Goaffpro with your Vimeo OTT Subscription'}>
            <button className={'btn btn-link px-0'} onClick={toggle}>
                See Instructions
            </button>
            <Modal title={'Vimeo OTT Integration'} visible={visible}
                   okText={'Done'} onOk={toggle} onClose={toggle}>
                <VimeoOttInstructions />
            </Modal>

        </SettingsSection>
    );
}
