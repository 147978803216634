import React, {Component} from 'react';
import {buildUrl, getCurrentQueryObject} from "../../../utils/func";
import {woocommerce} from "../../../config";
import {getHttpClient} from "../../../api";
import ProgressLoader from "../../../components/ProgressLoader";

import * as localStorage from '../../../utils/storage';
import {Link} from "react-router-dom";


export function installApp(){
    const currentQuery = getCurrentQueryObject();
    const {shop, email, blog} = currentQuery;
    if(shop){
        const base = `//${shop}/${woocommerce.endpoint}`;
        const query = woocommerce.query;
        const sharedSecret = new Date().getTime();

        query.return_url = buildUrl(query.return_url,{sharedSecret,shop, email, blog});
        query.callback_url = buildUrl(query.callback_url, {sharedSecret, shop, email});
        if(window.top === window.self) {
            window.location = buildUrl(base, query);
        }else{
            window.top.location = buildUrl(base, query);
        }
        return false;
    }else{
        return {error:'Please install the app from within plugin section'}
    }
}

class WooCommerceInstall extends Component {

    state = {
        error:false,
        needs_installation: true
    };

    componentDidMount() {
        const hasToken = localStorage.getItem("access_token");
        const {shop} = getCurrentQueryObject();
        if(hasToken){
            getHttpClient().get('/woocommerce/is_installed',{
                params:{
                    shop
                }
            }).then(({data})=>{
                if(data.access_token){
                    localStorage.setItem('access_token', data.access_token);
                    window.location = "/"
                }else{
                    localStorage.removeItem('access_token');
                    this.doInstall();
                }
            })
        }else {
            this.doInstall();
        }
    }

    doInstall = ()=>{
        this.setState({needs_installation:true})
    };

    doAutomaticInstall = ()=>{
        const {error} = installApp();
        if(error){
            this.setState({error})
        }
    }



    render() {
        if(this.state.needs_installation){
                return <div className={"container-fluid bg-light min-vh-100"}>
                    <div className={"py-4 text-center"}>
                        {this.state.error && <div className={"alert alert-danger"}>{this.state.error}</div>}
                    </div>
                    <div className="text-center mb-4">
                        <img src={"/logo.svg"} title={"Goaffpro"} height={64} />
                    </div>
                    <div className="card-group">
                        <div className="card">
                            <div className="card-body">
                                <h4>Setup</h4>
                                <ul className="list-group">
                                    <Link to={"/woocommerce/install/manual"} className="list-group-item list-group-item-action">Manual Installation</Link>
                                    <Link to={"/woocommerce/install/auto"}  className="list-group-item list-group-item-action">Automatic Installation</Link>
                                </ul>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <h4>Login</h4>
                                <ul className="list-group">
                                    <Link to={"/master"} className="list-group-item  list-group-item-action">Manager Login</Link>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
        }else{
            return <div className={"container-fluid bg-light min-vh-100"}>
                <div className={"text-center py-4"}>
                    <p className={"display-4"}>Just a moment</p>
                    <ProgressLoader />
                </div>
            </div>
        }

    }
}

export default WooCommerceInstall;
