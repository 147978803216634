import React, {useState} from 'react';
import StartContainer from '../login/StartContainer';
import Button from '../../components/form/Button';
import {getHttpClient} from '../../api';
import DocumentTitle from '../../components/DocumentTitle';
import * as localStorage from '../../utils/storage';
import useObjectState from '../../components/hooks/useObjectState';
import FormGroup from '../../components/form/FormGroup';
import Input from '../../components/form/Input';
import PasswordInput from '../../components/form/PasswordInput';

function ManagerLogin() {
  const [data, setData] = useObjectState({
    email: '',
    password:'',
  })
  const [response, setResponse] = useState(null)
  const [loading, setLoading] = useState(false);

  const onSubmit = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    const {email, password} = data;
    setLoading(true)
    setResponse(null)
    getHttpClient().post('/manager/login-master', {
      email, password,
    }).then(({data}) => {
      if (data.manager_access_token) {
        localStorage.setItem('manager_access_token', data.manager_access_token);
        if (data.sites.length > 1) {
          window.location = '/master';
        } else {
          localStorage.setItem('access_token', data.sites[0].access_token);
          window.location = '/';
        }
      } else {
        setResponse({error: 'Invalid credentials'})
      }
      setLoading(false)
    }).catch((e) => {
      console.log(e)
      setLoading(false)
      setResponse({error: 'Network error'})
    });
  };
   return (
        <StartContainer>
          <DocumentTitle title={'Admin Login'}/>
          <div className="text-center mb-2">
            <img src={'/icon-square.svg'} width={50} height={50} alt={"Goaffpro Logo"}/>
          </div>
          <h1 className={'text-center'}>Manager Login</h1>
          <div className="card shadow-lg">
            <form className="card-body" onSubmit={onSubmit}>
              {
                  response?.error &&
                  <p className="alert alert-danger">{response?.error}</p>
              }
              <FormGroup label={"Username or Email address"}>
                  <Input value={data.email} onChange={setData('email')} />
              </FormGroup>
              <FormGroup label={"Password"}>
                <PasswordInput value={data.password} onChange={setData('password')} />
              </FormGroup>
              <Button disabled={!data.email || !data.password}
                      loading={loading} onClick={onSubmit}
                      className={'btn btn-primary'}>Login</Button>
            </form>
          </div>
        </StartContainer>
    );
}

export default ManagerLogin;
