import React, {Component} from 'react';
import PropTypes from 'prop-types';

class FakeProgressBar extends Component {

    state = {
        progress:0 //pseudo progressbar
    }

    startProgress = ()=>{
        //takes about 10 seconds (10000 ms)
        const time = (this.props.time || 5) * 10;
        if(this.state.progress < 100) {
            setTimeout(() => {
                this.setState({progress: this.state.progress + 2}, () => {
                    this.startProgress();
                });
            }, time)
        }
    }


    componentDidMount() {
        this.startProgress();
    }


    render() {
        return (
            <div className="progress" style={{width:"100%"}}>
                <div className="progress-bar  progress-bar-animated progress-bar-striped"
                     role="progressbar" style={{width: `${this.state.progress}%`}}
                     aria-valuenow="25"
                     aria-valuemin="0"
                     aria-valuemax="100">
                </div>
            </div>
        );
    }
}

FakeProgressBar.propTypes = {
    time: PropTypes.number
}

export default FakeProgressBar;
