import React, {Component, useState} from 'react';
import CopyToClipboard from "react-copy-to-clipboard";

function CopyButton({text, className, copyBtnText, title}:{title?: string, text: string, className?: string, copyBtnText?: string | React.ReactNode}) {
    const [copied, setCopied] = useState(false)
    const onCopy = ()=>{
        setCopied(true)
        setTimeout(()=>{
            setCopied(false)
        }, 1000);
    }
        return (
            <CopyToClipboard text={text} onCopy={onCopy}>
                <button  title={title} type={"button"}
                        disabled={copied}
                        className={className}>
                    {copied ? 'Copied' : copyBtnText || 'Copy'}</button>
            </CopyToClipboard>
        );
}

export default CopyButton;
