import React from 'react';
import FormGroup from '../../../../components/form/FormGroup';
import CopyButton from '../../../../components/CopyButton';
import {useSelector} from 'react-redux';

function CustomSdk(props) {
  const website_match = useSelector((state)=>state.settings.website_match)
  const visitsTracker = `<script type="text/javascript" src="https://api.goaffpro.com/loader.js?shop=${website_match}"></script>`
  const conversionTracker = `<script type="text/javascript">
      window.goaffpro_order = {
        number : "#1001",
        total: 1000
      }
      if(typeof window.goaffproTrackConversion !== "undefined"){
        window.goaffproTrackConversion(window.goaffpro_order)
      }
    </script>`
  return (
      <>

        <h3>Step 1</h3>
        <p>
          Add the following code to all pages on your store <strong>before</strong> the <code>{`</head>`}</code> tag. Usually you would want to paste this in your theme's layout file
        </p>
        <FormGroup>
          <textarea className="form-control" spellCheck={false} value={visitsTracker}/>
          <div className="text-right my-2">
            <CopyButton text={visitsTracker} className={"btn btn-sm btn-outline-primary"}/>
          </div>
        </FormGroup>
        <hr/>
        <h3>Step 2</h3>
        <p>
          Add the following code to the conversion page or order "thank you" page <strong>before</strong> the <code>{`</body>`}</code> tag. Usually you would want to paste this in your theme's layout file footer area
        </p>
        <FormGroup>
          <textarea rows={5} className="form-control" spellCheck={false} value={conversionTracker}/>
          <div className="text-right my-2">
            <CopyButton text={conversionTracker} className={"btn btn-sm btn-outline-primary"}/>
          </div>
        </FormGroup>
        <p className="alert alert-info">Please ensure that the <code>goaffproOrder</code> object has the actual order data of the customer. Simply copy-pasting the code above will not work</p>
        <p>The <code>goaffproOrder</code> object must conform to the following schema</p>
        <table className="table">
          <thead>
          <tr>
            <th>Key</th>
            <th>Value</th>
          </tr>
          </thead>
          <tbody>
          {
            keys.map((item)=>{
              return <tr key={item.key}>
                <td>{item.key}</td>
                <td>{item.value}</td>
              </tr>
            })
          }
          </tbody>
        </table>
        <div className="text-center">
          Need more information ? Read our <a href={"https://github.com/anujtenani/goaffpro/wiki/Custom-Integration-advanced-guide"} target={"_blank"}>complete sdk setup guide here</a>
        </div>
      </>
  );
}

const keys = [
  {
    key:'number',
    value:'The order number',
    required:true
  },
  {
    key:'total',
    value:'Total order amount',
    required:true
  },
]

export default CustomSdk;
