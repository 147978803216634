import React, {useEffect, useState} from 'react';
import {getCurrentQueryObject} from '../../../utils/func';
import ProgressLoader from '../../../components/ProgressLoader';
import {getHttpClient} from '../../../api';
import * as localStorage from '../../../utils/storage';

export function installApp(){
    const {code, context, scope} = getCurrentQueryObject();
    const admin_ref_code = localStorage.getItem('admin_ref_code');

    return getHttpClient().post('/bigcommerce/install',{
        code, context, scope, admin_ref_code
    }).then(({data})=>{
        if(data.access_token){
            localStorage.setItem("access_token", data.access_token);
            document.location = "/"
        }else{
            return {error:'Unable to install the app'}
        }
    }).catch((e)=>{
        console.log(e)
        return {error:'Network error'}
    });
}

function BigCommerceInstall(){
    const [error, setError] = useState(null)
    useEffect(()=>{
        installApp().then(({error})=>{
            if(error){
               setError(error)
            }
        });
    },[])
    if(error){
        return <div className={"container-fluid bg-light min-vh-100"}>
            <div className={"py-4 text-center"}>
                <div className={"alert alert-danger"}>
                    {error}
                </div>
            </div>
        </div>
    }else {
        return <div className={"container-fluid bg-light min-vh-100"}>
            <div className={"text-center py-4"}>
                <p className={"display-4"}>Just a moment</p>
                <ProgressLoader />
            </div>
        </div>
    }

}

export default BigCommerceInstall;
