import React, {useEffect, useState} from 'react';
import Wrapper from './Wrapper';
import {getHttpClient} from '../../../api';
import Spinner from '../../../components/Spinner';

function WebflowConnect(props) {
  const [needsLogin, setError] = useState(false)
  useEffect(() => {
    getHttpClient().get('/webflow/authorize').then(({data})=>{
      console.log('data')
      if(data.url){
        window.location.href = data.url
      }
    }).catch((e)=>{
      if(e.response.status === 403){
        setError(true)
      }
    })
  }, []);
  return (
        <Wrapper>
          <div className="text-center">
            {
              needsLogin ?
                  <>
                  <p className="alert alert-danger">You need to login before you can connect your webflow store</p>
                    <a href={"https://goaffpro.com/login?redirect=/install/webflow"} className={"btn btn-primary"}>Login</a>
                  </>
                  :
                  <Spinner />
            }
          </div>
      </Wrapper>
    )
  ;
}

export default WebflowConnect;
