import React, {Component} from 'react';
import {getCurrentQueryObject} from '../../../utils/func';
import {getHttpClient} from '../../../api';
import Button from '../../../components/form/Button';
import StartContainer from '../../login/StartContainer';
import Spinner from '../../../components/Spinner';
import FormGroup from '../../../components/form/FormGroup';
import CurrencySelector from '../../../components/form/CurrencySelector';
import DocumentTitle from '../../../components/DocumentTitle';
import * as localStorage from '../../../utils/storage';

class WixCallback extends Component {
  state = {
    saving_keys: false,
  };

  componentDidMount() {
    this.checkForReinstall();
  }

  checkForReinstall = async () => {
    const {code, instanceId} = getCurrentQueryObject();
    if (code && instanceId) {
      this.setState({testing: true});
      await getHttpClient().post('/wix/test_login', {
        code, instanceId,
      }).then(({data}) => {
        this.setState({testing: false});
        if (data.site_id) {
          localStorage.setItem('access_token', data.access_token);
          window.location = '/wizard';
        } else {
          const {
            url,
            paymentCurrency,
            siteDisplayName,
            businessName,
            email,
            access_token,
            refresh_token,
          } = data;
          this.setState({
            email,
            website: url,
            name: businessName,
            store_name: siteDisplayName,
            currency: paymentCurrency,
            access_token,
            refresh_token,
          });
        }
      });
    }
  };

  createAccount = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    const {
      name,
      email,
      password,
      store_name,
      website,
      currency,
      access_token,
      refresh_token,
    } = this.state;
    this.setState({accountCreating: true});
    const q = getCurrentQueryObject();
    const {instanceId, code, mtoken} = q;
    //verify the installation
    getHttpClient().post('/wix/save_keys', {
      merchant_token: mtoken,
      instanceId, code,
      access_token, refresh_token,
      account_data: {
        name, email, password, store_name, website, currency,
      },
    }).then(({data}) => {
      if (data.access_token) {
        localStorage.setItem('access_token', data.access_token);
        this.setState({accountCreating: false});
        window.location = '/';
      } else if (data.error) {
        this.setState({error: data.error, accountCreating: false});
      }
    }).catch((e) => {
      this.setState({accountCreating: false});
    });
  };

  isEnabled = () => {
    const {email, password, name, website} = this.state;
    if (this.state.mtoken) {
      return !!website;
    } else {
      return !!(email && password && name && website);
    }
  };
  onChange = (key) => e => {
    this.setState({[key]: e.target.value});
  };

  render() {
    const {name, email, password, store_name, website, currency} = this.state;
    return <StartContainer>
      <DocumentTitle title={'Store Setup'}/>
      {
        this.state.testing ? <div className={'text-center my-4 py-4'}>
          <Spinner/>
        </div> : <>
          <h1 className={'text-center'}>Create store account</h1>
          <div className="card">
            <form onSubmit={this.createAccount} className="card-body">
              {
                !this.state.mtoken ? <>
                  <div className="row">
                    <div className="col-md-6">
                      <FormGroup label={'Your name'}>
                        <input type="text" placeholder={'John Doe'}
                               className="form-control"
                               value={name} onChange={this.onChange('name')}/>
                      </FormGroup>
                    </div>
                    <div className="col-md-6">
                      <FormGroup label={'Email address'}>
                        <input type="email"
                               placeholder={'youremail@example.com'}
                               className="form-control" value={email}
                               onChange={this.onChange('email')}/>
                      </FormGroup>
                    </div>
                  </div>
                  <FormGroup label={'Password'}>
                    <input type="password" className="form-control"
                           value={password}
                           onChange={this.onChange('password')}/>
                  </FormGroup>
                </> : null
              }
              <div className="row">
                <div className="col-md-6">
                  <FormGroup label={'Store Name'}>
                    <input type="text" placeholder={'Acme Inc!'}
                           className="form-control" value={store_name}
                           onChange={this.onChange('store_name')}/>
                  </FormGroup>
                </div>
                <div className="col-md-6">
                  <FormGroup label={'Website'}>
                    <input type="website" placeholder={'https://example.com'}
                           className="form-control" value={website}
                           onChange={this.onChange('website')}/>
                  </FormGroup>
                </div>
              </div>
              <FormGroup label={'Currency'}>
                <CurrencySelector value={currency}
                                  onChange={this.onChange('currency')}/>
              </FormGroup>

              <Button disabled={!this.isEnabled()}
                      className={'btn btn-primary'}
                      loading={this.state.accountCreating}
                      onClick={this.createAccount}>Create store account</Button>
            </form>
          </div>
        </>
      }
    </StartContainer>;
  }
}

export default WixCallback;
