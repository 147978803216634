import React, {Component} from 'react';
import currencies from "../../currencies";
import PropTypes from "prop-types";

class CurrencySelector extends Component {
    render() {
        return (
            <select className={'form-select'} disabled={this.props.disabled} name={"default_currency"}
                    value={this.props.value}
                    onChange={this.props.onChange}>
                {
                    Object.keys(currencies).map((cur)=>{
                        return <option key={cur} value={cur}>{currencies[cur].name}</option>
                    })
                }
            </select>        );
    }
}

CurrencySelector.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool
}

export default CurrencySelector;
