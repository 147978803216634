import React, {useMemo} from 'react';
import useVisitsTrackingCode from "../../../../components/hooks/useVisitsTrackingCode";
import CopyButton from "../../../../components/CopyButton";

export function ShopifyViaCustomSdK() {
    const {
        script,
        website_match,
    } = useVisitsTrackingCode();
    const conversionTrackingCode = useMemo(() => {
        return `<script type="text/javascript">
    var urlObj = Object.fromEntries(new URL(document.location.href).searchParams.entries())
    window.goaffpro_order = {
        id:"{{order.id}}",
        total:"{{order.amount}}",
        currency:"{{order.currency}}",
        customer:{
          id:"{{customer.id}}",
          first_name:"{{customer.first_name}}",
          last_name:"{{customer.last_name}}",
          email:"{{customer.email}}"
        },
        coupons: [urlObj.coupon, urlObj.giftCard].filter((i)=>i),
        ref: urlObj.ref,
      }
      if(typeof goaffproTrackConversion !== "undefined"){
        goaffproTrackConversion(window.goaffpro_order);
      }else if(window.top !== window.self){
        const script = document.createElement('script')
        script.src = "https://api.goaffpro.com/loader.js?shop=${website_match}"
        document.head.appendChild(script);
      }</script>`;
    }, []);
    return <>
        <h3>Shopify via Custom SDK</h3>
        <p>Open your Shopify backoffice -&gt; <kbd>Online store</kbd> -&gt; <kbd>Themes</kbd> -&gt; <kbd>Edit code</kbd></p>
        <p>Then paste the below code in the <code>layouts/theme.liquid</code> file just above the closing <mark className={"font-monospace"}>{`</body>`}</mark> tag </p>
        <textarea value={script} className={'form-control mb-1'}
                  readOnly></textarea>
        <CopyButton className={'btn btn-outline-primary'} text={script}/>
        <p className={"mt-2"}>That's it. The integration is complete now</p>
    </>;

}

export default ShopifyViaCustomSdK;
