import {useSelector} from "react-redux";
import {useMemo} from "react";
import {ReduxStore} from "../../redux/settings/SettingsTypes";

export default function useThirdPartyTrackingScript() {
    const [website_match, integration] = useSelector<ReduxStore, [string, string]>(
        (state) => [
            state.settings.website_match, state.settings.integration]);
    return useMemo(() => integration === 'wix' ?
            `https://api.goaffpro.com/wix_loader.js?shop=${website_match}` :
            `https://api.goaffpro.com/loader.js?shop=${website_match}`,
        [integration, website_match]);
}
