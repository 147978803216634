import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

const ScrollToTop = () => {
  // Extracts pathname property(key) from an object
  const { href, pathname,search } = useLocation();
  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    try {
      if(!pathname.startsWith("/master")
          && !pathname.startsWith("/logout")
          && !pathname.startsWith("/t/") && !pathname.startsWith("/manage")){
        sessionStorage.setItem('masterDashboardLocation', pathname)
      }
    }catch(e){
      // console.log(e)
    }
    setTimeout(function(){
      window.scrollTo(0, 0);
    },30)
    // window.scrollTo({
    //   top: 0,
    //   left:0,
    // });
  }, [pathname, search, href]);
}

export default ScrollToTop;
