import React, {Component} from 'react';
import CopyButton from "../../../components/CopyButton";
import Youtube from "../../../components/video/Youtube";
import {connect} from "react-redux";

class BigcommerceTrackingManual extends Component {
    state = {
        code:`<script type="text/javascript">window.__big = {order_id: %%ORDER_ID%%, order_amount: %%ORDER_AMOUNT%% }</script>`
    }
    onClick = ()=>{
        window.open(`https://${this.props.website}/manager/settings/analytics`)
    }

    render() {
        return (
            <div className="min-vh-100 bg-light py-4">

            <div className="container">
            <div className="text-center">
                <h3>Manually add tracking code</h3>
            </div>
            <div className={"card-group"}>
                <div className="card">
                    <div className="card-body">
                        <ol>
                            <li>Open BigCommerce Admin Page</li>
                            <li>Select <code>Settings</code> in the Sidebar</li>
                            <li>Then in the <code>Advanced</code> section, choose <code>Data solutions</code></li>
                                {
                                    this.props.website &&
                                    <div>
                                        <button className={"btn btn-link"} onClick={this.onClick}>
                                            Open Page
                                        </button>
                                    </div>
                                }
                            <li>Click <kbd>Connect</kbd> in <mark>Affiliate Conversion Tracking</mark></li>
                            <li>
                                Paste the code given below in the Affiliate Conversion Tracking field
                            </li>
                        </ol>
                        <textarea className={"form-control"} value={this.state.code} disabled={true}/>
                        <CopyButton className={"btn btn-link"} text={this.state.code}/>
                    </div>
                </div>
                <div className="card">
                    <Youtube videoId={"-pA0XUb06Vc"} />
                </div>
            </div>
                <div className="text-center my-4">
                    <a href={"/"}>Back to home</a>
                </div>
            </div>
            </div>

        );
    }
}

function mapStateToProps(state){
    return{
        website: state.settings.website
    }
}

export default connect(mapStateToProps)(BigcommerceTrackingManual);
