import React, {useCallback} from 'react';
import {
  FiArrowRight, FiCheckCircle,
  FiChevronDown,
  FiChevronRight,
  FiHelpCircle,
} from 'react-icons/fi';
import FormGroup from '../../../../components/form/FormGroup';
import CopyButton from '../../../../components/CopyButton';
import useToggle from '../../../../components/hooks/useToggle';
import {useDispatch, useSelector} from 'react-redux';
import {updateSetting} from '../../../../redux/settings/settingsAction';
import {uniq} from '../../../../utils/func';

function VimeoOttInstructions(props) {
  const website_match = useSelector((state)=>state.settings.website_match)
  return (
      <>
        <h3>Step 1</h3>
        <p>Select how your affiliates will send referrals to your Vimeo
          OTT page</p>
        <div className="border rounded">
          <CollapsibleItem
              title={'Affiliates will send traffic to a landing page'}>
            <h5>Step 1a</h5>
            <p>Ensure that the following code is present in the
              landing page</p>
            <EmbedCode/>
            <h5>Step 1b</h5>
            <JustCompatibleWithVimeo/>
          </CollapsibleItem>
          <CollapsibleItem
              title={'Affiliates will send traffic directly to Vimeo OTT page'}>
            <CampaignAsPrimaryRefIdentifier/>
          </CollapsibleItem>
        </div>
        <br/>
        <h3>Step 2</h3>
        <p>Add the following webhooks to your Vimeo OTT site <a
            target={'_blank'}
            title={'Guide from venmo OTT website on how to set webhooks'}
            rel={'noreferrer nofollow noopener'}
            href={'https://vimeoott.zendesk.com/hc/en-us/articles/360023065913-Create-a-Vimeo-OTT-webhook#h_01F0XPKFX10444BR7AKGHBTNWA'}>
          <FiHelpCircle/>
        </a></p>
        <ol>
          <li>
            Open the Vimeo OTT admin
          </li>
          <li>Go to <kbd>Manager</kbd> <FiArrowRight/> <kbd>Site</kbd>
            <FiArrowRight/> <kbd>Advanced</kbd></li>
          <li>Paste the URL provided below in the Webhook URLs section
            (click the (+) icon to add more webhooks)<br/>
            <img alt={'Vimeo OTT Setup webhooks example image'}
                 src={'/images/vimeo_ott_setup_webhooks.png'}
                 style={{width: '100%'}}/>

            <br/>

          </li>
        </ol>
        <FormGroup label={'Webhook URL'}>
          <input onFocus={(e) => e.target.select()}
                 className={'form-control'}
                 value={`https://api.goaffpro.com/vimeo/webhooks/${website_match}`}
          />

        </FormGroup>
        <CopyButton
            text={`https://api.goaffpro.com/vimeo/webhooks/${website_match}`}
            className={'btn btn-sm btn-outline-primary'}
            title={'Copy'}/>
      </>
  );
}


function CollapsibleItem({title, children}) {
  const [visible, toggle] = useToggle();
  return <>
    <div className={'p-2 border-bottom'}>
      <button className="btn p-0  space-between w-100" onClick={toggle}>
        {title} {visible ? <FiChevronDown/> : <FiChevronRight/>}
      </button>
      {
        visible ? <div className={'py-2'}>{children}</div> : null
      }
    </div>

  </>;
}

function EmbedCode() {
  const website_match = useSelector((state) => state.settings.website_match);
  const integration = useSelector((state) => state.settings.integration);
  const url = integration === 'wix' ?
      `https://api.goaffpro.com/wix_loader.js?shop=${website_match}` :
      `https://api.goaffpro.com/loader.js?shop=${website_match}`;

  return <FormGroup label={'Embed code'}>
                <textarea className={'form-control'} readOnly value={
                  `<script type="text/javascript" src="${url}"></script>`
                }/>
    <CopyButton className={'btn btn-link'}
                text={`<script type="text/javascript" src="${url}"></script>`}/>
  </FormGroup>;
}

function JustCompatibleWithVimeo() {
  const ref_identifiers = useSelector((state) => {
    return state.settings.ref_identifiers ?
        JSON.parse(state.settings.ref_identifiers) :
        ['ref', 'click_id', 'sub_id', 'wpam_id', 'aff', 'hash'];
  });
  const dispatch = useDispatch();
  const isCampaignPrimary = ref_identifiers.some((a) => a === 'campaign');
  const setVimeoCompat = useCallback(() => {
    dispatch(updateSetting({
      ref_identifiers: JSON.stringify(
          uniq([...ref_identifiers, 'campaign'])),
      autolink_ref_parameter: 'campaign',
    }));
  }, []);

  return isCampaignPrimary ? <p className={'text-success'}>
    <FiCheckCircle/> Successfully set
  </p> : <>
    <button className="btn btn-outline-primary d-block"
            onClick={setVimeoCompat}>Enable VIMEO OTT Integration
    </button>
  </>;
}

function CampaignAsPrimaryRefIdentifier() {
  const ref_identifiers = useSelector((state) => {
    return state.settings.ref_identifiers ?
        JSON.parse(state.settings.ref_identifiers) :
        ['ref', 'click_id', 'sub_id', 'wpam_id', 'aff', 'hash'];
  });
  const dispatch = useDispatch();
  const isCampaignPrimary = ref_identifiers[0] === 'campaign';
  const setCampaignPrimary = useCallback(() => {
    dispatch(updateSetting({
      ref_identifiers: JSON.stringify(
          uniq(['campaign', ...ref_identifiers])),
      autolink_ref_parameter: 'campaign',
    }));
  }, []);

  return isCampaignPrimary ? <p className={'text-success'}>
    <FiCheckCircle/> Successfully set
  </p> : <>
    <button className="btn btn-outline-primary d-block"
            onClick={setCampaignPrimary}>Enable VIMEO OTT Integration
    </button>
    <small className={'text-muted'}>This will update your affiliate's
      referral links to be compatible with Vimeo OTT</small>
  </>;
}

export default VimeoOttInstructions;
