import {getHttpClient} from "../../../api";

const endpoint = "/v1/admin/groups"

export function createGroup(group){
    return (dispatch, getState)=>{
        return getHttpClient().post(endpoint, {group})
            .then(({data})=>{
                dispatch({
                    type:'groups-add',
                    data: data.group
                })
                return data.group
            })
    }

}
export function updateGroup(id, group){
    return (dispatch, getState)=>{
        return getHttpClient().put(endpoint+"/"+id, {group})
//            .then(({data:{group}})=>{
            .then(({data})=>{
                dispatch({
                    id,
                    type:'groups-update',
                    change:group
                })
            })
    }
}
export function updateGroupCommissions(id, change){
    return (dispatch, getState)=>{
        const {name, commissions, config} = getState().groups.find((item)=>String(item.id) === String(id));
        const group = {
            name,
            commissions: {
                ...commissions,
                ...change
            },
            config
        }
        return dispatch(updateGroup(id, group))
    }
}
export function updateGroupConfig(id, change){
    return (dispatch, getState)=>{
        const {name, commissions, config} = getState().groups.find((item)=>String(item.id) === String(id));
        const group = {
            name,
            commissions,
            config:{
                ...(config || {}),
                ...change
            }
        }
        return dispatch(updateGroup(id, group))
    }
}
export function loadGroups(){
        return (dispatch, getState)=>{
            return getHttpClient().get(endpoint, {
                params:{
                    fields:'id, name, commissions,config'
                }
            }).then(({data})=>{
                    dispatch({
                        type:'groups-set',
                        data: data.groups
                    })
                    return data.groups
                })
        }

}
export function deleteGroup(id){
    return (dispatch, getState)=>{
        return getHttpClient().delete(endpoint + "/"+id)
            .then((data)=>{
                dispatch({
                    type:'groups-delete',
                    id
                })
            })
    }
}

/**
 *
 * @param group_id
 * @param members - list of affiliate ids
 * @returns {function(*, *): Promise<AxiosResponse<any> | never>}
 */
export function putMembersInGroup(group_id, members){
    return (dispatch, getState)=>{
        return getHttpClient().put('/v1/admin/groups/'+group_id+"/members", {
            members
        }).then(()=>{
            return dispatch(loadGroups())
        })
    }
}

