import React, {Component} from 'react';
import SettingsSection from "../../components/SettingSection";
import Button from "../../../../components/form/Button";
import Modal from "../../../../components/Modal";
import CopyButton from "../../../../components/CopyButton";
import ReactSwitch from "react-switch";
import {connect} from "react-redux";
import {updateSetting} from "../../../../redux/settings/settingsAction";
import {Link} from "react-router-dom";
import {SettingsMap} from "../../../../components/VideoAndHelpSectionMap";

const trackingCode = `var GoaffproBoldObject = {
            order_name:[order_name],
            total:[total],
            subtotal:[subtotal],
            tax:[tax],
            shipping:[shipping],
            customer_email:[customer_email],
            customer_id:[customer_id],
            first_name:[first_name],
            last_name:[last_name],
            coupon_code:[coupon_code],
            shop:[myshopify_domain],
            line_items:products_json,
            currency:[currency]
        }
        function gfp_trackSale(){GoaffproBoldObject.ref=function(t){for(var e=t+"=",o=decodeURIComponent(document.cookie).split(";"),n=0;n<o.length;n++){for(var r=o[n];" "==r.charAt(0);)r=r.substring(1);if(0==r.indexOf(e))return r.substring(e.length,r.length)}return""}("ref");var t=new XMLHttpRequest;t.open("POST","https://api.goaffpro.com/bold_subscription_thankyou"),t.setRequestHeader("Content-Type","application/json;charset=UTF-8"),t.send(JSON.stringify(GoaffproBoldObject)),t.onload=function(){}}gfp_trackSale();
        function loadCheckoutWidget(){
            var script = document.createElement('script')
            script.src = "https://api.goaffpro.com/checkout_widget.js?shop="+GoaffproBoldObject.shop
            script.async = false
            document.head.appendChild(script)
        }
        loadCheckoutWidget();`

class BoldSubscription extends Component {
    state = {
        visible:false
    }

    onChange = (e)=>{
        this.props.updateSetting({
            customer_tracking:e,
            customer_affiliate_connect_auto: e
        })
    }

    toggleModal = ()=>{
        this.setState({visible: !this.state.visible})
    }

    render() {
        const {customer_affiliate_connect_auto, customer_tracking} = this.props;
        return (
            <SettingsSection title={"Bold Subscription"} helpLink={SettingsMap.get('Integrations').BoldSubscription.helpLink} videoID={SettingsMap.get('Integrations').BoldSubscription.videoID} subtitle={"Integrate tracking code in bold subscription app"}>
                <Button onClick={this.toggleModal} className={"btn btn-secondary"}>
                    Setup
                </Button>
                <Modal title={"Setup Instructions"} onClose={this.toggleModal} visible={this.state.visible}>
                    <h5>Step 1</h5>
                    <p>Add following code in <kbd>Bold Subscription</kbd> -&gt; <kbd>Integrations</kbd> -&gt; <kbd>Advanced analytics/tracking</kbd> section. (To track first order)</p>
                    <textarea value={trackingCode} disabled className={"form-control"} />
                    <div className="text-right">
                        <CopyButton text={trackingCode} className={"btn btn-link"} />
                    </div>
                    <hr/>

                    <h5>Step 2</h5>
                    <p>Turn on Affiliate-Customer connection (To track recurring orders)</p>
                    {
                        this.props.subscription === 1 ?
                        <ReactSwitch checked={customer_tracking && customer_affiliate_connect_auto}
                                     onChange={this.onChange}/>
                            : <Link to={"/subscriptions"}>Upgrade to activate</Link>
                    }
                </Modal>
            </SettingsSection>
        );
    }
}

function mapStateToProps(state){
    return {
        customer_tracking: state.settings.customer_tracking,
        customer_affiliate_connect_auto:state.settings.customer_affiliate_connect_auto,
        subscription: state.settings.subscription
    }
}


function mapDispatchToProps(dispatch){
    return {
        updateSetting:(change)=>dispatch(updateSetting(change))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(BoldSubscription);
