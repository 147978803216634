import React, {Component} from 'react';
import {getCurrentQueryObject} from "../../../utils/func";
import ProgressLoader from "../../../components/ProgressLoader";
import {getHttpClient} from "../../../api";
import * as localStorage from '../../../utils/storage';


export function installApp(){
    const {signed_payload} = getCurrentQueryObject();
    return getHttpClient().post('/bigcommerce/signin',{
        signed_payload
    }).then(({data})=>{
        if(data.access_token){
            localStorage.setItem("access_token", data.access_token);
            document.location = "/"
        }else{
            return {error:<div>
                    <p>Unable to load the app, kindly reinstall</p>
                    <strong>Error</strong>
                    <textarea className={"form-control"} value={JSON.stringify(data)} />
            </div>}
        }
    }).catch((e)=>{
        return {error:'Network error'}
    });
}

class BigCommerceLoad extends Component {

    state = {
        error:false
    };

    componentDidMount() {

        this.doInstall();
    }

    doInstall = async ()=>{
        const ret = await installApp();
        if(ret && ret.error){
            this.setState({error: ret.error})
        }
    };




    render() {
        if(this.state.error){
            return <div className={"container-fluid bg-light min-vh-100"}>
                <div className={"py-4 text-center"}>
                    <div className={"alert alert-danger"}>
                        {this.state.error}
                    </div>
                </div>
            </div>
        }else {
            return <div className={"container-fluid bg-light min-vh-100"}>
                <div className={"text-center py-4"}>
                    <p className={"display-4"}>Just a moment</p>
                    <ProgressLoader />
                </div>
            </div>
        }
    }
}

export default BigCommerceLoad;
