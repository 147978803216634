import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getHttpClient} from "../../../api";
import {getCurrentQueryObject} from "../../../utils/func";
import ProgressLoader from "../../../components/ProgressLoader";
import * as localStorage from '../../../utils/storage';

class WooCommerceCallback extends Component {

    state = {
        loading:false,
        error:null
    };

    componentDidMount(){
        const{sharedSecret, shop, email, blog, password} = getCurrentQueryObject();
        this.setState({loading:true});
        getHttpClient().post('/woocommerce/save_keys',{
            shared_secret: sharedSecret,
            email, blog,
            password,
            shop
        }).then(({data})=>{
            if(data.access_token) {
                this.setState({loading:false});
                localStorage.setItem("access_token", data.access_token);
//                this.props.history.replace('/');
                document.location = "/";
            }else{

                console.log('error', data);
                this.setState({error:data, loading:false});
            }
        })
    }

    render() {
        return (
            <div className={"container-fluid bg-light min-vh-100"}>
                <div className={"row align-items-center justify-content-center"}>
                    <div className={"col-6"}>
                        {this.state.error ?
                            <textarea className={"form-control"}>
                            {JSON.stringify(this.state.error, null, 2)}
                        </textarea> : null
                        }
                        {
                            this.state.loading ? <InstallLoader />: null
                        }
                    </div>
                </div>
            </div>
        );
    }
}


function InstallLoader(){
    return <div className={"pt-4 text-center"}>
        <p className={"display-4"}>Please wait</p>
        <ProgressLoader />
    </div>
}

function mapStateToProps(state) {
    return {};
}

export default connect(
    mapStateToProps,
)(WooCommerceCallback);
