import React, {useState} from 'react';
import SettingsSection from '../../components/SettingSection';
import {connect} from 'react-redux';
import ReactSwitch from 'react-switch';
import {Link} from 'react-router-dom';
import FormGroup from '../../../../components/form/FormGroup';
import settingsDispatchToProps
    from '../../premiumextras/settingsDispatchToProps';
import Checkbox from '../../../../components/form/Checkbox';
import {SettingsMap} from '../../../../components/VideoAndHelpSectionMap';
import CreatableReactSelect
    from '../../../../components/react-select/CreatableReactSelect';

let options = [
    {
        label:'Affiliate\'s Name',
        value:'name'
    },
    {
        label:'Affiliate\'s Email',
        value:'email'
    },
    {
        label:'Affiliate\'s Referral Code',
        value:'ref_code'
    },
    {
        label:'Goaffpro',
        value:'Goaffpro'
    },
    {
        label:'Affiliate',
        value:'Affiliate'
    }
];

function CustomerTags({upgrade_required, customer_tags_on_new_customers_only, customer_tags, customer_tags_enabled, updateSettings}){
    const [error, setError] = useState(null)
    function onToggleChange(customer_tags_enabled){
        if(upgrade_required){
            return setError(<>You need to <Link to={"/subscriptions"}>upgrade</Link> to use order tags</>)
        }
        updateSettings({customer_tags_enabled})
    }
    function onTagsInput(data) {
        updateSettings({customer_tags: JSON.stringify(data.map((item)=>item.value))})
    }

        return (
            <SettingsSection title={"Customer tags"} helpLink={SettingsMap.get('Advanced').CustomerTags.helpLink} videoID={SettingsMap.get('Advanced').CustomerTags.videoID}
                             subtitle={"Add additional tags to affiliate's customer in shopify. You can also use dynamic tags to retrieve query parameters from the referral link etc. {{utm_source}} etc."}>
                <div className="form-group">
                    <ReactSwitch checked={customer_tags_enabled}
                                 onChange={onToggleChange}/>
                    {
                        error ? <p className="text-danger">{error}</p> : null
                    }
                </div>
                <div className="form-group">
                    {
                        customer_tags_enabled &&
                        <>
                            <FormGroup label={"Tags to add to the customer"}>
                                <CreatableReactSelect options={options}
                                             value={customer_tags}
                                             onChange={onTagsInput}
                                             isMulti
                                             closeOnMenuSelect={false} />
                                <small className={"text-muted"}>You can also write your own tags in the field above above</small>
                            </FormGroup>
                            <Checkbox checked={customer_tags_on_new_customers_only}
                                      onChange={(e)=>updateSettings({customer_tags_on_new_customers_only:e.target.checked})}
                                      label={"Apply tags only on new customers"}  />
                        </>

                    }
                </div>
            </SettingsSection>
        );
}

function mapStateToProps(state) {
    const {customer_tags, customer_tags_enabled, customer_tags_on_new_customers_only} = state.settings;
    const r = customer_tags ? JSON.parse(customer_tags)
        .filter((item)=>item)
        .map((item)=>{
        return options.find((itm)=>itm.value === item) || {label:item, value: item};
    }) : [];
    return {
        customer_tags_enabled,
        customer_tags: r,
        customer_tags_on_new_customers_only,
        website_match: state.settings.website_match,
        upgrade_required: state.settings.subscription !== 1,
    };
}



export default connect(mapStateToProps, settingsDispatchToProps)(CustomerTags);
