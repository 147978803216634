import React, {useEffect, useState} from 'react';
import {getCurrentQueryObject} from '../../../../utils/func';
import {getHttpClient} from '../../../../api';
import {actionMergeSetting} from '../../../../redux/settings/settingsAction';
import {connect} from 'react-redux';
import FakeProgressBar from '../../../../components/FakeProgressBar';
import {useNavigate} from 'react-router-dom';

function MailchimpPostAuth({mergeSettings}) {
    const [error, setError] = useState(null)
    const navigate = useNavigate()
    useEffect(() => {
        const {code} = getCurrentQueryObject();
        if (code) {
            getHttpClient().post('/integrations/mailchimp/after_auth', {
                code
            }).then(({data}) => {
                if (data.error) {
                    setError(data.error)
                } else if (data.mc_access_token) {
                    console.log('got mc_access_token')
                    mergeSettings({...data});
                    navigate('/settings/integrations', {replace:true})
                }
            })
        }
    }, [mergeSettings, navigate])
    return (
        <div
            className={'p-4 m-4  d-flex align-items-center justify-content-center'}>
            <FakeProgressBar/>
            {
                error && <div>{error}</div>
            }
        </div>
    );
}


function mapDispatchToProps(dispatch){
    return {
        mergeSettings: (change)=>dispatch(actionMergeSetting(change))
    }
}

export default connect(null, mapDispatchToProps)(MailchimpPostAuth);
