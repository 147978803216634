import React, {useEffect, useState} from 'react';
import SettingsSection from '../../components/SettingSection';
import ReactSwitch from 'react-switch';
import {connect, useDispatch, useSelector} from 'react-redux';
import Button from '../../../../components/form/Button';
import {getHttpClient, swrFetcher} from '../../../../api';
import {Link} from 'react-router-dom';
import FormGroup from '../../../../components/form/FormGroup';
import settingsStateToProps from '../../settingsStateToProps';
import settingsDispatchToProps
    from '../../premiumextras/settingsDispatchToProps';
import {SettingsMap} from '../../../../components/VideoAndHelpSectionMap';
import useSwr from 'swr';
import {updateSetting} from '../../../../redux/settings/settingsAction';
import Select from '../../../../components/form/Select';

function ShopifyAccountSync({updateSettings, account_sync_opt_in_email_marketing, store_account_sync, subscription, account_sync_custom_tags}) {
    const [resyncing, setResyncing] = useState(false)
    function startResync(){
        setResyncing(true);
        getHttpClient().post('/integrations/store_account/do_initial_sync')
            .then(({data})=>{
                setResyncing(false)
            }).catch(()=>{
            setResyncing(false)
        })
    }

    const integration = useSelector((state)=>state.settings.integration)


     return (
        <SettingsSection title={"Account Sync"} helpLink={SettingsMap.get('Integrations').AccountSync.helpLink} videoID={SettingsMap.get('Integrations').AccountSync.videoID} subtitle={"Automatically registers affiliates as store customers"}>
            {
                subscription === 1 ? <>
                    <div className="d-flex justify-content-between">
                        <ReactSwitch checked={store_account_sync} onChange={(store_account_sync)=>updateSettings({store_account_sync})}/>
                        {
                            store_account_sync && <>
                                <Button loading={resyncing} onClick={startResync} className={"btn btn-link px-0"}>Re-Sync</Button>
                            </>
                        }
                    </div>

                    {
                        store_account_sync ? <>
                                {
                                    integration === "shopify" ?
                                <ShopifyAccountSyncOptions/> : null
                                }
                                {
                                    integration === "bigcommerce" ?
                                        <BigCommerceAccountSync/> : null
                                }
                                </> : null
                    }
                    </>
                    : <Link to={"/subscriptions"}>Upgrade to activate</Link>
            }

        </SettingsSection>
    );
}
function ShopifyAccountSyncOptions() {
    const account_sync_opt_in_email_marketing = useSelector((state)=>state.settings.account_sync_opt_in_email_marketing);
    const account_sync_custom_tags = useSelector((state)=>state.settings.account_sync_custom_tags)
    const [customTags, setCustomTags] = useState(account_sync_custom_tags)
    const [saving, setSaving] = useState(false)
    const dispatch = useDispatch()
    useEffect(()=>{
        setCustomTags(account_sync_custom_tags)
    },[account_sync_custom_tags])
    function submit(e){
        if(e){
            e.preventDefault();
            e.stopPropagation();
        }
        dispatch(updateSetting({
            account_sync_custom_tags:customTags
        }))
    }
    const toggleOptIn = (account_sync_opt_in_email_marketing) => {
        dispatch(updateSetting({account_sync_opt_in_email_marketing}))
    }
    return <form onSubmit={submit}>
        <FormGroup label={"Tag synced accounts with tag"}
                   info={"You can separate tags with a comma"}>
            <div className="row">
                <div className="col-8">
                    <input type="text" disabled={saving}
                           className="form-control" value={customTags}
                           onChange={(e) => setCustomTags(e.target.value)}
                           placeholder={"affiliate,goaffpro"}/>
                </div>
                <div className="col-4">
                    {
                        saving || customTags !== account_sync_custom_tags ?
                            <Button loading={saving}
                                    className={"btn btn-primary"}
                                    onClick={submit} title={"Submit"}/> :
                            null
                    }
                </div>
            </div>
        </FormGroup>
        <FormGroup label={"Auto opt in to email marketing"}>
            <div>
                <ReactSwitch checked={account_sync_opt_in_email_marketing}
                             onChange={toggleOptIn}/>
            </div>
        </FormGroup>
    </form>
}

function BigCommerceAccountSync() {
    const {data, isLoading} = useSwr('/bigcommerce/customer_groups', swrFetcher,{
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
    })
    const [customerGroup, setCustomerGroup] = useState(null)
    const account_sync_customer_group_id = useSelector((state)=>state.settings.account_sync_customer_group_id);
    useEffect(()=>{
        setCustomerGroup(account_sync_customer_group_id)
    },[account_sync_customer_group_id])
    const dispatch = useDispatch();
    const updateCustomerGroup = () => {
        dispatch(updateSetting({account_sync_customer_group_id:customerGroup || null}))
    }
    return <>
        <FormGroup label={"Sync to group (optional)"}>
            <div className="row">
                <div className="col-8">
                    <Select hasEmpty options={data?.customer_groups.map((i)=>{
                        return {label:i.name,value:i.id}
                    }) || []}
                            value={customerGroup}
                            onChange={setCustomerGroup}
                            isLoading={isLoading}
                    />
                </div>
                <div className="col-4">
                    {
                        (customerGroup || '') !== (account_sync_customer_group_id || '') ?
                            <Button onClick={updateCustomerGroup} title={"Save"} className={"btn btn-primary"}/> : null
                    }
                </div>
            </div>


        </FormGroup>
    </>
}

export default connect(settingsStateToProps([
        'account_sync_opt_in_email_marketing',
        'store_account_sync', 'subscription', 'account_sync_custom_tags']),
    settingsDispatchToProps)(ShopifyAccountSync);
