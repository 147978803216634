import React, {Component} from 'react';
import SettingsSection from "../../components/SettingSection";
import Button from "../../../../components/form/Button";
import Modal from "../../../../components/Modal";
import CopyButton from "../../../../components/CopyButton";
import {connect} from "react-redux";
import {updateSetting} from "../../../../redux/settings/settingsAction";
import {SettingsMap} from "../../../../components/VideoAndHelpSectionMap";

const trackingCode = (shop)=> `<script type="text/javascript">
    var GoaffproShop = '${shop}'
    var GoaffproObject = {
        shop:  "${shop}",
        order_name: {{subscription.plan_id}},
        total:{{ invoice.subtotal }},
        subtotal:{{ invoice.subtotal}},
        tax:{{ invoice.tax_total}},
        shipping:{{ invoice.shipping_total}},
        customer_email:{{ customer.email }},
        customer_id:{{ customer.id }},
        first_name:{{ customer.first_name }},
        last_name:{{ customer.last_name }},
        line_items:{{ invoice.items }},
        address:{{address}},
        subscription: {{subscription}},
        plan:{{plan}},
        customer:{{customer}}
    }
    function gfp_trackSale(){GoaffproObject.ref=function(t){for(var e=t+"=",o=decodeURIComponent(document.cookie).split(";"),n=0;n<o.length;n++){for(var r=o[n];" "==r.charAt(0);)r=r.substring(1);if(0==r.indexOf(e))return r.substring(e.length,r.length)}return""}("ref");var t=new XMLHttpRequest;t.open("POST","https://api.goaffpro.com/paywhirl_thankyou"),t.setRequestHeader("Content-Type","application/json;charset=UTF-8"),t.send(JSON.stringify(GoaffproObject)),t.onload=function(){}}gfp_trackSale();
</script>
<script type="text/javascript" src="https://api.goaffpro.com/checkout_widget.js?shop=${shop}"></script>
`

class PayWhirl extends Component {
    state = {
        visible:false
    }

    toggleModal = ()=>{
        this.setState({visible: !this.state.visible})
    }

    render() {
        return (
            <SettingsSection title={"PayWhirl"} helpLink={SettingsMap.get('Integrations').PayWhirl.helpLink} videoID={SettingsMap.get('Integrations').PayWhirl.videoID} subtitle={"Integrate tracking code in PayWhirl checkout"}>
                <Button onClick={this.toggleModal} className={"btn btn-secondary"}>
                    Setup
                </Button>
                <Modal title={"Setup Instructions"} onClose={this.toggleModal} visible={this.state.visible}>
                    <p>Add following code in the Thank You page of PayWhirl</p>
                    <textarea value={trackingCode(this.props.website_match)} disabled className={"form-control"} />
                    <div className="text-right">
                        <CopyButton text={trackingCode(this.props.website_match)} className={"btn btn-link"} />
                    </div>
                </Modal>
            </SettingsSection>
        );
    }
}

function mapStateToProps(state){
    return {
        website_match: state.settings.website_match,
    }
}


function mapDispatchToProps(dispatch){
    return {
        updateSetting:(change)=>dispatch(updateSetting(change))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(PayWhirl);
