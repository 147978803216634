import React, {useCallback, useState} from 'react';
import StartContainer from '../login/StartContainer';
import Button from '../../components/form/Button';
import {getHttpClient} from '../../api';
import useObjectState from '../../components/hooks/useObjectState';
import FormGroup from '../../components/form/FormGroup';
import Input from '../../components/form/Input';
import {useTitle} from 'ahooks';
import * as localStorage from '../../utils/storage';
import GoaffproLogo from '../../components/GoaffproLogo';
function ManagerLogin() {
  useTitle('Manager login');
  const [data, setData] = useObjectState({
    store_id: '',
    password: '',
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const onSubmit = useCallback((e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    const {store_id, password} = data;
    setLoading(true);
    setError(null);
    getHttpClient().post('/manager/login', {
      store_id, manager_key: password,
    }).then(({data}) => {
      if (data.access_token) {
        localStorage.setItem('access_token', data.access_token);
        window.location = '/';
      } else {
        setError('Invalid Credentials');
      }
      setLoading(false);
    }).catch(() => {
      setError('Network error');
    });
  }, [data]);
  return (
      <StartContainer>
        <div className="text-center mb-2">
          <GoaffproLogo size={50}></GoaffproLogo>
        </div>
        <h1 className={'text-center'}>Manager Login</h1>
        <div className="card shadow-lg">
          <form className="card-body" onSubmit={onSubmit}>
            {
                error && <p className="alert alert-danger">{error}</p>
            }
            <FormGroup label={'Store ID'}>
              <Input value={data.store_id} onChange={setData('store_id')}/>
            </FormGroup>
            <FormGroup label={'Password'}>
              <Input value={data.password} type={'password'}
                     onChange={setData('password')}/>
            </FormGroup>
            <Button disabled={!data.store_id || !data.password}
                    loading={loading} onClick={onSubmit}
                    className={'btn btn-primary'}>Login</Button>
          </form>
        </div>
      </StartContainer>
  );
}

export default ManagerLogin;
