import React from 'react';
import Skeleton from 'react-loading-skeleton';

const Cmp = React.lazy(()=>import('react-select/creatable'))

function CreatableReactSelect(props){
  return <React.Suspense fallback={<Skeleton style={{height:35.5}} />}>
    <Cmp {...props} />
  </React.Suspense>
}

export default CreatableReactSelect
