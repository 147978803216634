import {useCallback, useState} from 'react';

type valueType = Record<string, any>;

function useObjectState<T>(initialState: T): [T, (key: string) => (value: string | boolean) => void, (data: T) => void] {
    const [data, setData] = useState<T>(initialState)
    const changed = useCallback((key) => {
        return (value) => {
            setData((d) => {
                return {...d, [key]: value}
            })
        }
    }, [])
    return [data, changed, setData]
}

export default useObjectState
