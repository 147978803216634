import React, {useMemo} from "react";
import {randomString} from "../../utils/func";

export default function Checkbox({checked, value, label, onChange, small, disabled, radio}:{
    checked?:boolean,
    onChange:(inp: {target: {checked:boolean}})=>void,
    label?: string
    value?: string
    disabled?:boolean
    radio?: boolean
    small?: boolean
}){
    const id = useMemo(()=>{
        return randomString()
    },[])
    return <div className={"mb-2"}>
        <input disabled={disabled} className="form-check-input" onChange={onChange} type={radio ? 'radio' : "checkbox"} id={id} value={value} checked={checked || false}/>
        {
            small ? <small>
                <label className="form-check-label" htmlFor={id}>{label}</label>
            </small> :                 <label className="form-check-label" htmlFor={id}>{label}</label>

        }
    </div>
}
