import {getHttpClient} from '../../api';
import {getAccessToken} from '../../utils/access_token_handler';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from 'react';


export const ACTIONS = {
    merge:'update-settings',
    set: 'set-settings'
};
export const actionMergeSetting = (data)=>{
    return {
        type:ACTIONS.merge,
        data:castJSONKeys(data)
    }
};

const castJSONKeys = (settings) => {
    jsonKeysToTransform.forEach((i)=>{
      if(typeof settings[i] === "string"){
        console.log('casted', i)
        settings[i] = JSON.parse(settings[i]);
      }
    })
    return settings;
  }
  
  const jsonKeysToTransform = [
    'activity_sheets',
    'autopay_dc_entitled_collections',
    'sw_fbar_show_only_for_groups',
    'available_locales',
    'checkout_blocker_config',
    'autopay_shopify_combines_with'
  ]
  


export const updateSetting = (change)=>{
  return (dispatch, getState)=>{
      const original = getState().settings;
      dispatch(actionMergeSetting({saving:true}));
      dispatch(actionMergeSetting(change));
    return getHttpClient().post('/sites/settings',{
            change
        }).then(({data})=>{
            //const {setting_key, setting_value} = data;
            //dispatch(actionMergeSetting({[setting_key]: setting_value}))
            dispatch(actionMergeSetting({saving:false}));
            return data;
        }).catch((e)=>{
            const originalObject = {};
            Object.keys(change).forEach((key)=>{
                originalObject[key] = original[key];
            });
            dispatch(actionMergeSetting(originalObject));
            dispatch(actionMergeSetting({saving:false}));
        })
  }
};


export const updatePortalConfig = (change)=>{
    return (dispatch, getState)=>{
        const oldConfig = getState().settings.affiliate_portal_config;
        const oObj = oldConfig ? JSON.parse(oldConfig) : {}
        const newConfig = JSON.stringify({
            ...oObj,
            ...change
        })
        dispatch(actionMergeSetting({saving:true}));
        dispatch(actionMergeSetting({
            affiliate_portal_config: newConfig
        }));
        return getHttpClient().post('/sites/settings',{
            change:{
                affiliate_portal_config: newConfig
            }
        }).then(({data})=>{
            //const {setting_key, setting_value} = data;
            //dispatch(actionMergeSetting({[setting_key]: setting_value}))
            dispatch(actionMergeSetting({saving:false}));
            return data;
        }).catch((e)=>{
            dispatch(actionMergeSetting({
                affiliate_portal_config: oldConfig,
                saving:false
            }));
        })
    }
};


export const loadSettings = ()=>{
    return (dispatch, getState)=>{
        dispatch(actionMergeSetting({loading:true, under_maintenance:false}));
        return getHttpClient().get('/sites/settings').then(({data})=>{
            // console.log('received settings', JSON.stringify(data, null, 2))
            dispatch({
                type:ACTIONS.set,
                data:{
                    ...data,
                    loading: false,
                    access_token: getAccessToken(),
                }
            })
        }).catch(()=>{
            dispatch(actionMergeSetting({loading:false, under_maintenance:true}))
        })
    }
};

export function useSettings(){
    const access_token = useSelector((state)=>state.settings.access_token)
    const dispatch = useDispatch()
    useEffect(()=>{
        const listener = (e)=>{
            dispatch(actionMergeSetting({
                access_token: e.detail.access_token
            }))
        };
        window.addEventListener('on_access_token_set',listener)
        return ()=>{
            window.removeEventListener('on_access_token_set', listener)
        }
    }, [dispatch])
    useEffect(()=>{
        if(access_token){
            dispatch(loadSettings())
        }
    },[access_token, dispatch])
}
