import React from 'react';
import {FormattedMessage} from "react-intl";

interface ButtonProps{
    className?: string
    loading?: boolean
    saved?: boolean
    title?: string
    type?: 'submit' | 'button'
    disabled?: boolean
    children?: React.ReactNode

    onClick:(e?:any)=>void,

}
export default function Button(props: ButtonProps){
    const cls = "btn "+props.className;
    const {loading, saved, title} = props;
    const btnProps = {...props};
    delete btnProps.loading;
    delete btnProps.saved
    return (
        <button {...btnProps} disabled={props.disabled || loading || saved} className={cls}>
            {
                loading &&  <span className="spinner-border spinner-border-sm mx-2" role="status" aria-hidden="true"/>
            }
            {!loading && !saved && props.children }
            {
                saved && <span>Saved</span>
            }
            {
                title && <FormattedMessage id={title} />
            }
        </button>
    )
}
