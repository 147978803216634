import React from 'react';
import {useSelector} from 'react-redux';
import {FiLogOut} from 'react-icons/fi';
import {Link} from 'react-router-dom';

function AdminStoreNameBanner(props) {
  const store_name = useSelector((state) => state.settings.store_name);
    if(window.top != window.self) return null;
    return (
      <div
          className={'bg-dark d-flex align-items-center border-bottom border-dark-subtle justify-content-between text-light py-2 px-4'}><>
        {store_name}
        <div className={"d-inline-flex align-items-center"}>
          <Link to={'/logout'} className="btn ml-2 text-light" title={'Logout'}>
            <FiLogOut/>
          </Link>
        </div>
      </>
      </div>
  );
}

export default AdminStoreNameBanner;
