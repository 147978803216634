import React from "react";
import {Link} from "react-router-dom";
import VideoModalWithHelpLink from "../../../components/video/VideoModalWithHelpLink";

export default function SettingsSection({
                                            title,
                                            subtitle,
                                            children,
                                            customSubtitle,
                                            fullWidth,
                                            noBorder,
                                            requiresUpgrade,
                                            helpLink,
                                            videoID
                                        }: {
    children: React.ReactNode,
    title?: string
    subtitle?: string | React.ReactNode;
    helpLink?: string;
    requiresUpgrade?: boolean,
    noBorder?: boolean;
    fullWidth?: boolean;
    customSubtitle?: React.ReactNode
    videoID?: string
}) {
    return <div className={`row pb-2 ${!noBorder && 'border-bottom mb-4 pb-4'}`}>
        <div className={fullWidth ? 'col-12' : "col-md-6"}>
            <div className={"mb-2"}>
                <h5 className={(helpLink || videoID) ? 'd-inline-block' : undefined}>{title}</h5>
                {(helpLink || videoID) ?
                    <VideoModalWithHelpLink title={title} helpLink={helpLink} videoID={videoID}/> : null}
                {
                    customSubtitle ? subtitle : <div className={"text-muted"}>{subtitle}</div>
                }
                {
                    requiresUpgrade && <small className={"text-danger"}>Requires upgrade <Link to={"/subscriptions"}
                                                                                               className={"text-primary"}>Upgrade</Link></small>
                }
            </div>
        </div>
        <div className={fullWidth ? 'col-12' : "col-md-6"}>
            {children}
        </div>
    </div>
}
