import {getHttpClient} from '../../api';
import {loadGroups} from "../../pages/partnergroups/redux/groupsActions";
import {getNames} from '../../utils/func';


export const ACTIONS = {
    merge:'update-affiliate',
    reset: 'reset-affiliate'
};


export const actionMergeAffiliate = (data)=>{
    return {
        type:ACTIONS.merge,
        data
    }
};



export const reloadAffiliate = (affiliate_id)=>{
    return (dispatch, getState)=>{
        dispatch({
            type: ACTIONS.merge,
            data:{
                loading:true,
                affiliate_id
            }
        })
        getHttpClient().get(`/affiliates/details/${affiliate_id}`).then(({data})=>{
            if(affiliate_id === getState().currentAffiliate.affiliate_id) {
                const d = {...data, loading:false};
                try {
                    d.payment_details_data = data.payment_details_data ? JSON.parse(data.payment_details_data) : {}
                }catch(e){

                }
                dispatch(actionMergeAffiliate(d))
            }
        });
    }
}


export const loadAffiliate = (affiliate_id)=>{
    return (dispatch, getState)=>{
        dispatch({
            type: ACTIONS.reset
        })
        dispatch({
            type: ACTIONS.merge,
            data:{
                loading:true,
                affiliate_id
            }
        })
        getHttpClient().get(`/affiliates/details/${affiliate_id}`).then(({data})=>{
            if(affiliate_id === getState().currentAffiliate.affiliate_id) {
                if(data.group_id && !getState().groups.find((item)=>String(item.id) === String(data.group_id))){
                    dispatch(loadGroups())
                }
                const d = {...data, loading:false};
                try {
                    d.payment_details_data = data.payment_details_data ? JSON.parse(data.payment_details_data) : {}
                }catch(e){

                }
                const {name, fname, lname} = getNames(d);
                d.name = name;
                d.fname = fname;
                d.lname = lname;
                dispatch(actionMergeAffiliate(d))
            }
        });
    }
}


export const updateAffiliate = (affiliate_id, change)=> {
    return (dispatch, getState) => {
        const original = getState().currentAffiliate;
        if(change.name || change.fname || change.lname){
            const {name, fname, lname} = getNames(change);
            change.name = name;
            change.fname = fname;
            change.lname = lname;
        }
        const d = {...change, saving: true}
        if(d.payment_details_data){
            d.payment_details_data = JSON.parse(d.payment_details_data)
        }
        const revert = ()=>{
            const originalObject = {};
            Object.keys(change).forEach((key) => {
                originalObject[key] = original[key];
            });
            originalObject.saving = false;
            dispatch(actionMergeAffiliate(originalObject));
        }
        dispatch(actionMergeAffiliate(d));
        return getHttpClient().patch('/affiliates/' + affiliate_id, change)
            .then(({data}) => {
                dispatch(actionMergeAffiliate({saving: false}));
                if(data.error){
                    revert()
                }
                return {data};
            }).catch(revert)
    }
}


export const updateReferralCode = (affiliate_id, referral_codes)=> {
    return (dispatch, getState) => {
        return getHttpClient().post('/affiliates/referral_codes/'+affiliate_id,{
            referral_codes
        }).then(({data})=>{
            if(data.codes){
                dispatch(actionMergeAffiliate({
                    ref_code: data.codes[0]
                }))
                return data;
            }else if(data.conflicts){
                return {error:'Referral code is already in use', conflicts: data.conflicts}
            }else{
                return {error:'Network error'}
            }
        })
    }
}

export const fetchPaymentHistory = (affiliate_id, startDate, endDate)=>{
    return (dispatch, getState)=>{
        return getHttpClient().get('/payouts',{
            params: {
                startDate,
                endDate,
                affiliate_id,
            }
        }).then(({data}) => {
            actionMergeAffiliate({
                payout_history:  data.map((item)=>{ return {...item, key:item.payout_id}})
            });
        }).catch((e)=>{
        });
    }
}

export const deletePaymentHistory = (payout_id)=>{

}
