import React, {useCallback, useEffect} from 'react';

export default function Select({value, options,isMulti, defaultSelectFirst, disabled, onChange, placeholder, hasEmpty, style}: {
    hasEmpty?: boolean,
    isMulti?: boolean,
    placeholder?: string,
    onChange: (str: any) => void,
    disabled?: boolean,
    defaultSelectFirst?: boolean,
    options: Array<{ label: string, value: string, disabled?: boolean }>,
    value?: any
    style?: any,
}) {
    const changed = useCallback((e) => {
        if (onChange) {
            if(isMulti){
                const value: string[] = Array.from(e.target.selectedOptions, ((option: any) => 'value' in option ? option.value : null));
                onChange(value)
            }else {
                onChange(e.target.value)
            }
        }
    }, [onChange, isMulti])

    useEffect(() => {
        const optionExists = options && options.length > 0 ? options.find((i) => String(i.value) === String(value)) : false
        if (defaultSelectFirst) {
            if (!optionExists || !value) {
                if (options.length > 0) {
                    onChange(options[0].value)
                }
            }
        }
    }, [defaultSelectFirst, onChange, options, value])

    return (
        <select disabled={disabled}
                multiple={isMulti}
                style={style} className={"form-select"} value={value} onChange={changed}>
            {
                hasEmpty ? <option value={''}>{placeholder}</option> : null
            }
            {
                options.map(({label, value, disabled}) => {
                    return <option value={value} disabled={disabled} key={value}>{label}</option>
                })
            }
        </select>
    );
}
