import React, {Component} from 'react';
import {getCurrentQueryObject} from "../../../utils/func";
import {getHttpClient} from "../../../api";
import * as localStorage from '../../../utils/storage';
import Spinner from "../../../components/Spinner";
import StartContainer from "../../login/StartContainer";
import Button from "../../../components/form/Button";
import FormGroup from "../../../components/form/FormGroup";
import DocumentTitle from '../../../components/DocumentTitle';

class EcwidInstall extends Component {

    state = {
        error:false,
        formData:{}
    };

    componentDidMount() {
        const {code, mtoken} = getCurrentQueryObject();
        this.setState({
            code, mtoken
        }, this.doInstall)
    }

    doInstall = async (e)=>{
        if(e){
            e.preventDefault();
            e.stopPropagation();
        }
        const {code, name, email,password, mtoken} = this.state
        if(mtoken || (name && email && password)){
            this.setState({saving:true})
            const {access_token, error} = await getHttpClient().post('/ecwid/save_keys', {
                code,
                merchant_token: mtoken,
                name, email, password
            }).then(({data})=>data).catch((e)=>{return {}})
            this.setState({saving:false})
            if(access_token){
                localStorage.setItem('access_token',access_token);
                window.location = "/wizard";
            }else if(error){
                this.setState({error})
            }
        }
    };

    canSubmit = ()=>{
        const {code, name, email, password} = this.state;
        return code && name && email && password
    }

    onChange = key => (e)=>{
        this.setState({[key]:e.target.value})
    }

    render() {
        const {name, email, password} = this.state;
        return (
            <StartContainer>
                <DocumentTitle title={"Store Setup"}/>
                    {
                        this.state.error && <div className={"py-4 text-center"}>
                                <div className={"alert alert-danger"}>
                                    {this.state.error}
                                </div>
                            </div>
                    }
                    {
                        !this.state.mtoken && <><h3 className={"text-center"}>Create store account</h3>
                            <div className={"card"}>
                            <div className={"card-body"}>
                                <form onSubmit={this.doInstall}>
                                     <FormGroup label={"Your name"}>
                                         <input type="text" name={"name"} value={name} onChange={this.onChange('name')} className="form-control"/>
                                    </FormGroup>
                                    <FormGroup label={"Email address"}>
                                        <input type="email" name={"email"} value={email} onChange={this.onChange('email')} className="form-control"/>
                                    </FormGroup>
                                    <FormGroup label={"Password"}>
                                        <input type="password" name={"password"} value={password} onChange={this.onChange('password')} className="form-control"/>
                                    </FormGroup>
                                    <Button
                                        loading={this.state.saving}
                                        disabled={!this.canSubmit()} onClick={this.doInstall} className={"btn btn-primary"}>Create account</Button>
                                </form>
                            </div>

                        </div></>
                    }
                    {
                        this.state.saving && <LoadingComponent/>
                    }
            </StartContainer>
        )
    }
}
function LoadingComponent(){
    return  <div className={"text-center py-4"}>
        <p className={"display-4"}>Just a moment</p>
        <Spinner/>
    </div>
}

export default EcwidInstall;
