import React, {Component} from 'react';
import Youtube from '../../../../components/video/Youtube';
import Label from '../../../../components/form/Label';
import Button from '../../../../components/form/Button';
import InputGroup from '../../../../components/form/InputGroup';
import {getHttpClient} from '../../../../api';
import * as localStorage from '../../../../utils/storage';

class WooCommerceManualInstall extends Component {
  state = {
    error: undefined,
    shop: '',
    email: '',
    password: '',
    consumer_key: '',
    consumer_secret: '',
  };

  onChange = (key) => (e) => {
    this.setState({[key]: e.target.value});
  };

  onSubmit = e => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    const {shop, consumer_key, consumer_secret, email, password} = this.state;
    if (!shop || !consumer_secret || !consumer_key || !email || !password) {
      return this.setState({error: 'All fields are required'});
    }
    this.setState({saving: true});
    getHttpClient().post('/woocommerce/save_keys', {
      shop, consumer_key, consumer_secret, email, password,
    }).then(({data}) => {
      if (data.access_token) {
        localStorage.setItem('access_token', data.access_token);
        document.location = '/';
      } else {
        this.setState(
            {saving: false, error: data.error || 'Installation failed'});
      }
    }).catch(() => {
      this.setState({saving: false});
    });
  };

  pageUrl = () => {
    return `https://${this.state.shop}/wp-admin/admin.php?page=wc-settings&tab=advanced&section=keys&create-key=1`;
  };

  render() {
    const {shop, consumer_key, consumer_secret, email, password} = this.state;
    return (
        <div className={'container-fluid bg-light min-vh-100'}>
          <div className={'py-4 text-center'}>
            {this.state.error &&
                <div className={'alert alert-danger'}>{this.state.error}</div>}
          </div>
          <div className="text-center mb-4">
            <img src={'/logo.svg'} alt={'Goaffpro'} height={64}/>
          </div>
          <div className="card-group">
            <form className="card" onSubmit={this.onSubmit}>
              <div className="card-body">
                <h4>Manual Install</h4>
                <div className="form-group">
                  <Label title={'Store Domain Name'}/>
                  <InputGroup value={shop} onChange={this.onChange('shop')}
                              prefix={'https://'} type="text"
                              className="form-control"/>
                </div>
                <div className="form-group">
                  <Label title={'Your email address'}/>
                  <InputGroup value={email} placeholder={'Your email address'}
                              onChange={this.onChange('email')} type="email"
                              className="form-control"/>
                </div>
                <div className="form-group">
                  <Label title={'Password'}/>
                  <InputGroup value={password} placeholder={'Password'}
                              onChange={this.onChange('password')}
                              type="password" className="form-control"/>
                </div>
                <div className="form-group">
                  <div className="d-flex justify-content-between">
                    <Label title="Consumer Key - Read/Write"/>
                    {
                        this.state.shop &&
                        <a href={this.pageUrl()} target={'_blank'}>Open API
                          Page</a>
                    }
                  </div>
                  <input value={consumer_key}
                         onChange={this.onChange('consumer_key')}
                         placeholder={'API Key'} type="text"
                         className="form-control"/>
                </div>
                <div className="form-group">
                  <Label title="Consumer Secret - Read/Write"/>
                  <input value={consumer_secret}
                         onChange={this.onChange('consumer_secret')}
                         placeholder={'API Secret'} type="text"
                         className="form-control"/>
                </div>
                <Button onClick={this.onSubmit}
                        disabled={!email || !password || !shop ||
                            !consumer_secret || !consumer_key}
                        loading={this.state.saving}
                        className="btn btn-primary">Install</Button>
              </div>
            </form>
            <div className="card">
              <Youtube videoId={'It2mpuLcMI0'}/>
            </div>
          </div>
        </div>
    );
  }
}

export default WooCommerceManualInstall;
