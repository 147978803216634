import Cookies from 'js-cookie';
import {setAccessToken} from './access_token_handler';

export function setSessionItem(key: string, value: string) {
    try {
        sessionStorage.setItem(key, value);
    }catch(e){

    }
}
export function getSessionItem(key: string){
  try{
    return sessionStorage.getItem(key);
  }catch(e){

  }
}
export function deleteSessionItem(key: string){
    try{
        sessionStorage.removeItem(key);
    }catch(e){

    }

}

export function setItem(key, value) {
  if (key === 'access_token') {
    setAccessToken(value);
  }
  try {
    localStorage.setItem(key, value);
  }catch(e){

  }
  try {
    Cookies.set(key, value);
  }catch(e){

  }
}

export function getItem(key) {
  try {
    return localStorage.getItem(key);
  }catch(e){

  }
  try{
    return Cookies.get(key)
  }catch(e){

  }
}

export function removeItem(key) {
  if (key === 'access_token') {
    setAccessToken(null);
  }
  try {
    localStorage.removeItem(key);
  }catch(e){

  }
  try {
    Cookies.remove(key);
  }catch(e){

  }
}
