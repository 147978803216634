import React, {useMemo} from "react";
import CopyButton from "../../../../components/CopyButton";
import {useSelector} from "react-redux";
import {ReduxStore} from "../../../../redux/settings/SettingsTypes";

export function CartPanda() {
    const website_match = useSelector<ReduxStore>((state)=>state.settings.website_match)
    const conversionTrackingCode = useMemo(() => {
        return `<script type="text/javascript">
    window.goaffpro_order = {
        number:"{{order.order_number}}",
        total:"{{order.total_price}}",
        coupons:["{{order.discount_applications.code}}"],
        forceSDK:true,
      }
      if(typeof goaffproTrackConversion !== "undefined"){
        goaffproTrackConversion(window.goaffpro_order);
      }else{
        const script = document.createElement('script')
        script.src = "https://api.goaffpro.com/loader.js?shop=${website_match}"
        document.head.appendChild(script);
      }</script>`;
    }, []);
    return <>
        <p>Paste the following code in the <code>layouts/thankyou.liquid</code> file just above the closing <mark className={"font-monospace"}>{`</body>`}</mark> tag </p>
        <textarea value={conversionTrackingCode} className={'form-control mb-1'}
                  readOnly></textarea>
        <CopyButton className={'btn btn-outline-primary'} text={conversionTrackingCode}/>

    </>;

}

export default CartPanda;
