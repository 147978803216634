import React from "react";
import PropTypes from 'prop-types';
import Spinner from './Spinner';

const ProgressLoader =  function ({color, size}){
    return <Spinner />
}


ProgressLoader.propTypes = {
    color: PropTypes.string,
    size: PropTypes.number
}

ProgressLoader.defaultProps = {
    size: 16
}
export default ProgressLoader;



// export default Spin;
