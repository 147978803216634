import React, {useEffect, useMemo, useState} from 'react';
import {getCurrentQueryObject} from '../../../utils/func';
import {getHttpClient} from '../../../api';
import Spinner from '../../../components/Spinner';
import * as localStorage from '../../../utils/storage';

export default function ShopLazzaCallback() {
  const [error, setError] = useState(null);
  const {shop, code} = useMemo(() => {
    return getCurrentQueryObject();
  }, []);
  useEffect(() => {
    getHttpClient().post('/integrations/shoplazza/save_keys', {
      code, shop,
    }).then(({data}) => {
          const {access_token, error} = data;
          if (access_token) {
            localStorage.setItem('access_token', access_token);
            window.location = '/wizard';
          } else {
            setError(error || 'Unexpected Error! Kindly reinstall the app');
          }
        },
    );
  }, [code, shop]);
  return <div style={{paddingTop: 200}}
              className={'d-flex align-items-center justify-content-center'}>
    {
      error ? <p>{error}</p> : <Spinner/>
    }
  </div>;
}
