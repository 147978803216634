import React, {Component} from 'react';
import Label from "../../../../components/form/Label";
import Button from "../../../../components/form/Button";
import InputGroup from "../../../../components/form/InputGroup";
import {buildUrl} from "../../../../utils/func";
import {woocommerce} from "../../../../config";

export function installApp(shop, email, password){
//    const currentQuery = getCurrentQueryObject();
//    const {blog, email, password} = currentQuery;
    const blog = shop;
    if(shop){
        const base = `//${shop}/${woocommerce.endpoint}`;
        const query = woocommerce.query;
        const sharedSecret = new Date().getTime();

        query.return_url = buildUrl(query.return_url,{sharedSecret,shop, email, password,  blog});
        query.callback_url = buildUrl(query.callback_url, {sharedSecret, shop, email, password});
        if(window.top === window.self) {
            window.location = buildUrl(base, query);
        }else{
            window.top.location = buildUrl(base, query);
        }
        return false;
    }else{
        return {error:'Please install the app from within plugin section'}
    }
}
class WooCommerceAutomaticInstall extends Component {
    state = {
        error: undefined,
        shop:'',
        email:'', password:'',
    }

    onChange = (key)=>(e)=>{
        this.setState({[key]:e.target.value})
    }

    onSubmit = e =>{
        if(e){
            e.preventDefault();
            e.stopPropagation();
        }
        const {shop, email, password} = this.state;
        installApp(shop, email, password)
    }

    pageUrl = ()=>{
        return `https://${this.state.shop}/wp-admin/admin.php?page=wc-settings&tab=advanced&section=keys&create-key=1`;
    }

    render() {
        const {shop, email, password} = this.state;
        return (
            <div className={"container-fluid bg-light min-vh-100"}>
                <div className={"py-4 text-center"}>
                    {this.state.error && <div className={"alert alert-danger"}>{this.state.error}</div>}
                </div>
                <div className="text-center mb-4">
                    <img src={"/logo.svg"} alt={"Goaffpro"} height={64} />
                </div>
                <div className={"row"}>
                    <div className={"col-4"}/>
                <div className="card-group col-4">
                    <form className="card" onSubmit={this.onSubmit}>
                        <div className="card-body">
                            <h4>Automatic Install</h4>
                            <div className="form-group">
                                <Label title={"Store Domain Name"} />
                                <InputGroup value={shop} onChange={this.onChange('shop')} prefix={"https://"} type="text" className="form-control"/>
                            </div>
                            <div className="form-group">
                                <Label title={"Your email address"} />
                                <InputGroup value={email}  placeholder={"Your email address"} onChange={this.onChange('email')} type="email" className="form-control"/>
                            </div>
                            <div className="form-group">
                                <Label title={"Password"} />
                                <InputGroup value={password} placeholder={"Password"} onChange={this.onChange('password')} type="password" className="form-control"/>
                            </div>
                            <Button disabled={!shop || !email ||!password} onClick={this.onSubmit} loading={this.state.saving} className="btn btn-primary">Install</Button>
                        </div>
                    </form>
                </div>
                </div>
            </div>
        );
    }
}

export default WooCommerceAutomaticInstall;
