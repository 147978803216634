import React from 'react';
import Skeleton from 'react-loading-skeleton';
import PropTypes from 'prop-types';

const Cmp = React.lazy(()=>import('react-select'))

function ReactSelect(props){
  return <React.Suspense fallback={<Skeleton style={{height:35.5}} />}>
    <Cmp {...props} />
  </React.Suspense>
}
ReactSelect.props = {
  placeholder:PropTypes.string,
  isLoading: PropTypes.boolean,
  options: PropTypes.any.isRequired,
  isMulti: PropTypes.bool,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.any.isRequired
}

export default ReactSelect
