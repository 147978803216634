import React, {Component} from 'react';
import SettingSection from "../../components/SettingSection";
import MailchimpConnect from "./MailchimpConnect";
import {updateSetting} from "../../../../redux/settings/settingsAction";
import {connect} from "react-redux";
import MailchimpListSelector from "./MailchimpListSelector";
import {getHttpClient} from "../../../../api";
import Button from "../../../../components/form/Button";
import {SettingsMap} from "../../../../components/VideoAndHelpSectionMap";

class MailchimpSection extends Component {


    state = {
        show_modal:false,
    }
    closeModal = ()=>{
        this.setState({show_modal:false});
    }

    showModal = ()=>{
        this.setState({show_modal:true});
    }

    disconnect = ()=>{
        this.props.updateSetting({mc_access_token: '', mc_api_endpoint: '', mc_list_id:'', mc_list_title: ''})
    }

    resync = ()=>{
        this.setState({syncing:true, syncError:false})
        getHttpClient().post('/integrations/mailchimp/do_initial_sync')
            .then(({data}) => {
                this.setState({syncing:false})
            }).catch((e) => {
            this.setState({syncing:false, syncError:true})
        })
    }

    render() {
        return (
            <SettingSection title={"Mailchimp"} helpLink={SettingsMap.get('Integrations').MailChimp.helpLink} videoID={SettingsMap.get('Integrations').MailChimp.videoID} subtitle={"Sync affiliate data to a Mailchimp list"}>
                {
                    this.props.mc_access_token && !this.props.mc_list_id && <button className={"btn btn-secondary"} onClick={this.showModal}>Choose List to Sync to</button>
                }
                {
                    this.props.mc_access_token && this.props.mc_list_id && <div>
                        <span>Syncing to</span>
                        <p className={"lead"}>{this.props.mc_list_title}</p>
                        {
                            this.state.syncError && <p className={"text-danger"}>Sync Error!</p>
                        }
                        <div className="d-flex align-items-center">
                            <Button loading={this.state.syncing} onClick={this.resync} className="px-0 btn btn-link">Re-Sync</Button>
                            <span className={"mx-4"}>|</span>
                            <button className={"px-0 btn btn-link text-danger"} onClick={this.disconnect}>Disconnect</button>
                        </div>
                    </div>
                }
                {
                    ! this.props.mc_access_token &&  <MailchimpConnect />
                }
                {
                    this.state.show_modal &&
                    <MailchimpListSelector visible={this.state.show_modal} onClose={this.closeModal}/>
                }
            </SettingSection>
        );
    }
}

function mapDispatchToProps(dispatch){
    return {
        updateSetting:(change)=>dispatch(updateSetting(change))
    }
}

function mapStateToProps(state){
    return {
        mc_access_token: state.settings.mc_access_token,
        mc_list_id: state.settings.mc_list_id,
        mc_api_endpoint: state.settings.mc_api_endpoint,
        mc_list_title: state.settings.mc_list_title,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MailchimpSection);
