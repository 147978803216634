import React from 'react';
import {Route, Routes} from 'react-router-dom';
import BigCommerceInstall from './BigCommerceInstall';
import BigCommerceLoad from './BigCommerceLoad';
import BigcommerceTrackingManual from './BigcommerceTrackingManual';

export default function BigCommerceIndex(){
        return (
            <Routes>
                <Route path={"load"} element={<BigCommerceLoad/>} />
                <Route path={"manual-tracking"} element={<BigcommerceTrackingManual/>} />
                <Route path={"install"} element={<BigCommerceInstall />}/>
                <Route index element={<BigCommerceInstall/>} />
            </Routes>
        );
}
