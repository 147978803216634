
export default (state = [], action)=>{
    switch (action.type) {
        case "set-scripts":
            return action.data;
        case "update-script":
            return state.map((item)=>item.script_id == action.script_id ? {...item, ...action.change} : item)
        case "delete-script":
            return state.filter((item)=>item.script_id != action.script_id);
        case "add-script":
            return [action.data, ...state];
        default:
            return state;
    }
}
