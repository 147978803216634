

export default (state = [], action)=>{
    switch (action.type) {
        case "set-signup-pages":
            return action.data;
        case "merge-signup-page":
            if(state.find((item)=>item._id == action.data._id)){
                return state.map((item)=>{
                    return item._id == action.data._id ? {...item, ...action.data} : item;
                })
            }else{
                console.log('adding signup page', action.data._id)
                return [action.data, ...state];
            }
        case "delete-signup-pages":
            return state.filter(({_id, handle})=> _id != action._id)
        default:
            return state;
    }
}
