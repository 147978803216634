import React from 'react';
import useThirdPartyTrackingScript
  from '../../../../components/hooks/useThirdPartyTrackingScript';
import CopyButton from '../../../../components/CopyButton';

function CopyBasicTrackingCode(props) {
  const script = useThirdPartyTrackingScript();
  const tracking_code = `<script type="text/javascript" async src="${script}"></script>`

  return (
      <>
      <textarea className={"form-control"} value={tracking_code} readOnly></textarea>
      <CopyButton text={tracking_code} copyBtnText={"Copy"} className={'btn mt-2 btn-outline-primary btn-sm'} />
      </>
  );
}

export default CopyBasicTrackingCode;
