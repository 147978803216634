import React from 'react'
import LazyPageLoader from '../../components/LazyPageLoader';
const Page = React.lazy(()=>import('./LoginIndex'))

function LazyLoginIndex(props){
  return <React.Suspense fallback={<LazyPageLoader />}>
    <Page {...props} />
  </React.Suspense>
}
export default LazyLoginIndex
