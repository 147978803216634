import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {getCurrentQueryObject} from '../../../utils/func';
import FormGroup from '../../../components/form/FormGroup';
import Input from '../../../components/form/Input';
import Button from '../../../components/form/Button';
import * as localStorage from '../../../utils/storage';
import {getHttpClient} from '../../../api';
import {shopify} from '../../../config';
import {useNavigate} from 'react-router-dom';

//https://goaffpro.myshoplaza.com/admin/oauth/authorize?client_id=KAF3VQ_ML6agjFSlL3hc7EzKKZT-C75yb4EcFYmkQIo&scope=read_product%2Cread_order%2Cwrite_order%2Cread_collection%2Cread_shop%2Cread_script_tags%2Cwrite_script_tags%2Cread_customer%2Cwrite_customer%2Cread_price_rules%2Cwrite_price_rules&redirect_uri=https%3A%2F%2Fapp.goaffpro.com%2Fshoplazza%2Fcallback&response_type=code&state=1660371188247
function doInstall(shop){
  const u = new URL(`https://${shop}/admin/oauth/authorize`);
  u.searchParams.append('client_id','KAF3VQ_ML6agjFSlL3hc7EzKKZT-C75yb4EcFYmkQIo');
  u.searchParams.append('redirect_uri',`https://app.goaffpro.com/shoplazza/callback`);
  u.searchParams.append('response_type',`code`);
  u.searchParams.append('state', `${Date.now()}`)
  u.searchParams.append('scope','read_product, read_order, write_order,read_collection,read_shop,read_script_tags,write_script_tags,read_customer,write_customer,read_price_rules,write_price_rules'.split(",").join(" "))
  window.location = u.href;
}

export default function ShopLazzaInstall(){
  const [shopInput, setShop] = useState('')
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const {shop} = useMemo(()=>{
    return getCurrentQueryObject();
  },[])
  const startInstall = useCallback(()=>{
    if(shop) {
      setShop(shop.replace(".myshoplaza.com", ""))
      setLoading(true)
      doInstall(shop)
    }
  },[shop])
  useEffect(()=>{
    const hasToken = localStorage.getItem("access_token");
    if(!hasToken){
      startInstall()
    }else{
      setLoading(true)
      getHttpClient().get('/integrations/shoplazza/is_installed', {
        params: {
          shop,
        }
      }).then(({data}) => {
        setLoading(false)
        if (data.installed) {
          navigate('/',{replace:true});
        } else {
          localStorage.removeItem('access_token');
        }
      }).catch((e) => {
        setLoading(false)
        startInstall();
      })
    }
  },[navigate, shop, startInstall])

  const onSubmit = useCallback((e)=>{
    if(e){
      e.preventDefault();
      e.stopPropagation();
    }
    setLoading(true)
    doInstall(`${shopInput}.myshoplaza.com`)
  },[shopInput])
    return <div style={{paddingTop:200}} className={"bg-light min-vh-100"}>
      <div className="row">
        <div className="col-md-3"/>
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <h2>Shoplazza URL</h2>
              <form onSubmit={onSubmit}>
                <FormGroup label={"URL"}>
                  <Input value={shopInput} onChange={setShop} suffix={".myshoplaza.com"} />
                </FormGroup>
                <Button loading={loading} onClick={onSubmit} className="btn btn-primary">Submit</Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
}
