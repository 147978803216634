import React from 'react';
import CopyButton from '../../../../components/CopyButton';
import useThirdPartyTrackingScript
  from '../../../../components/hooks/useThirdPartyTrackingScript';

function MemberVault(props) {
  const script = useThirdPartyTrackingScript();
  const tracking_code = `<script type="text/javascript" async src="${script}"></script>`
  const conversion_code = `<script type="text/javascript">
  window.goaffpro_order = {
      id: Date.now(),
      total:100,  
  }
  if(typeof goaffproTrackConversion !== "undefined"){
    goaffproTrackConversion(window.goaffpro_order);
  }
  </script>`;
  return (
      <ol>
        <li>Open your MemberVault admin backoffice</li>
        <li>
          Go to the <strong>General Settings</strong> within your admin
          dashboard (under the main <strong>General tab</strong>).
        </li>
        <li>
          Scroll further down and expand the <mark>Advanced
          Settings</mark> area. This will display a text box for Custom CSS
          & <strong>Custom JavaScript</strong>.
        </li>
        <li>
          Paste the code below in the <strong>Custom JavaScript</strong> text
          box and click the ‘Save’ button.
          <br/>
          <textarea className={'form-control'} value={tracking_code}/>
          <CopyButton text={tracking_code}
                      className={'btn mt-2 btn-outline-primary btn-sm'}
                      copyBtnText={'Copy'}/>
          <br/>
        </li>
        <li>
          In MemberVault admin, edit the product you want to track, and scroll
          to the <strong>Conversion Tracking</strong> section.
          Every sign-up option will have a "Product View Tracking code", and if
          your sign-up option is Form or Paid, you'll also get a <strong>Product
          Conversion Tracking code</strong>
        </li>
        <li>
          Copy paste the code below in the <strong>Product Conversion Tracking
          code</strong> text box and click the ‘Save’ button.
          <br/>Note: Update the total value in the code below to match the price
          of the membership.
        </li>
        <textarea rows={6} className={'form-control'} value={conversion_code}/>
        <CopyButton text={conversion_code}
                    className={'btn mt-2 btn-outline-primary btn-sm'}
                    copyBtnText={'Copy'}/>
      </ol>
  );
}

export default MemberVault;
