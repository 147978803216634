import React, {useEffect, useState} from "react";
import settingsDispatchToProps from "../premiumextras/settingsDispatchToProps";
import {connect, useSelector} from 'react-redux';
import settingsStateToProps from "../settingsStateToProps";
import SettingsSection from "../components/SettingSection";
import Button from "../../../components/form/Button";
import ReactSwitch from "react-switch";
import {Link} from "react-router-dom";
import Modal from "../../../components/Modal";
import FormGroup from "../../../components/form/FormGroup";
import {getHttpClient} from "../../../api";
import {SettingsMap} from "../../../components/VideoAndHelpSectionMap";

function ActiveCampaign({updateSettings, activecampaign_sync_enabled, activecampaign_api_url, activecampaign_api_key}){
    const [setupModal, showSetupModal] = useState(false);
    const subscription = useSelector((state)=>state.settings.subscription)

    const [syncing, setSyncing] = useState(false);
    const [syncCompleted, setSyncingCompleted] = useState(false);
    function resync(){
        setSyncing(true);
        getHttpClient()
            .post('/integrations/activecampaign/do_initial_sync')
            .then((e)=>{
                setSyncing(false);
                setSyncingCompleted(true)
            setTimeout((e)=>{
                setSyncingCompleted(false)
            }, 3000)
        }).catch((e)=>{
            setSyncing(false);
        })
    }

    let cmp = null;
    if(!activecampaign_api_url && subscription < 1){
        cmp = <Link to={"/subscriptions"}>Upgrade to Sync</Link>
    }else if(activecampaign_api_url){
       cmp = <>
               <div>
                    <ReactSwitch checked={activecampaign_sync_enabled} onChange={(e)=>updateSettings({activecampaign_sync_enabled:e})}/>
               </div>
           {
               syncCompleted ? <p className={"text-success"}>Sync complete</p> : null
           }
           <div>
               <Button className={"btn px-0 btn-link"} onClick={()=>showSetupModal(!setupModal)}>Re-Configure</Button>
               <span className={"mx-4"}>|</span>
               <Button loading={syncing} className={"btn btn-link"} onClick={resync}>Re-Sync</Button>
           </div>
        </>
    }else{
        cmp = <Button className={"btn px-0 btn-link"} onClick={()=>showSetupModal(!setupModal)}>Setup</Button>
    }

    return (
         <SettingsSection requiresUpgrade={subscription < 1} title={"ActiveCampaign"} helpLink={SettingsMap.get('Integrations').ActiveCampaign.helpLink} videoID={SettingsMap.get('Integrations').ActiveCampaign.videoID}
                          subtitle={"Sync affiliate data with active campaign"}>
             {cmp}
             <ConnectedSetupModal visible={setupModal} onClose={()=>showSetupModal(false)}/>
        </SettingsSection>
    )

}
function SetupModal({activecampaign_api_url, activecampaign_api_key, updateSettings, visible, onClose}){
    const [apiUrl, setApiUrl] = useState('')
    const [apiKey, setApiKey] = useState('')
    const [saving, setSaving] = useState(false);
    const [screenshotShown, showScreenshot] = useState('');

    function onSave(e){
        e.preventDefault();
        e.stopPropagation();
        setSaving(true);
        updateSettings({
            activecampaign_api_key: apiKey,
            activecampaign_api_url: apiUrl,
            activecampaign_sync_enabled:true
        }).then((e)=>{
            return  getHttpClient()
                .post('/integrations/activecampaign/do_initial_sync')
        }).then((e)=>{
            setSaving(false)
            onClose()
        })
    }

    useEffect(()=>{
        setApiKey(activecampaign_api_key);
        setApiUrl(activecampaign_api_url);
    }, [activecampaign_api_key, activecampaign_api_url])

    return <Modal title={"ActiveCampaign"} visible={visible}
                  disabled={!apiKey || !apiUrl}
                  onCancel={onClose} confirmLoading={saving} okText={'Submit'} onSubmit={onSave}>
        <p>Copy the <kbd>URL</kbd> and <kbd>KEY</kbd> from your <br/>
            <code>ActiveCampaign admin</code> {`->`} <code>Settings</code> {`->`} <code>Developer page</code>
        </p>
        <div className="text-right">
            <button type={"button"} className="btn btn-link" onClick={()=>showScreenshot(!screenshotShown)}>Help screenshot ?</button>
        </div>
            {
                screenshotShown &&
                <img alt={"How to setup active campaign"} className={"card-img border"} src={"/images/activecampaign.png"}/>
            }
            <FormGroup label={"URL"}>
                <input
                    value={apiUrl}
                    onChange={(e)=>setApiUrl(e.target.value)}
                    type="text" className="form-control"/>
                <small className="text-muted">eg. https://accountname.api-us1.com</small>
            </FormGroup>
            <FormGroup label={"KEY"}>
                <input onChange={(e)=>setApiKey(e.target.value)}
                       value={apiKey}
                       type="text" className="form-control"/>
                <small className="text-muted">eg. 79c91f5e175abd9e19124f801aca510bf8be25ff2ea2b774a9eddb</small>
            </FormGroup>

    </Modal>
}

const ConnectedSetupModal = connect(settingsStateToProps(['subscription','activecampaign_sync_enabled','activecampaign_api_url','activecampaign_api_key']), settingsDispatchToProps)(SetupModal)

export default connect(settingsStateToProps(['subscription','activecampaign_sync_enabled','activecampaign_api_url','activecampaign_api_key']), settingsDispatchToProps)(ActiveCampaign)
