import React, {Component} from 'react';
import SettingsSection from "../../components/SettingSection";
import ReactSwitch from "react-switch";
import {connect} from "react-redux";
import {updateSetting} from "../../../../redux/settings/settingsAction";
import {SettingsMap} from "../../../../components/VideoAndHelpSectionMap";

class RecaptchaSection extends Component {
    onChange = (recaptcha_enabled)=>{
        this.props.updateSetting({recaptcha_enabled})
    }

    render() {
        return (
            <SettingsSection title={"Google ReCaptcha"} helpLink={SettingsMap.get('Integrations').GoogleRecaptcha.helpLink} videoID={SettingsMap.get('Integrations').GoogleRecaptcha.videoID} subtitle={"Protect your sign up page from bots with Google ReCaptcha"}>
                <ReactSwitch
                    checked={this.props.recaptcha_enabled}
                    onChange={this.onChange}
                />
            </SettingsSection>
        );
    }
}

function mapStateToProps(state){
    return {
        recaptcha_enabled: state.settings.recaptcha_enabled
    }
}

function mapDispatchToProps(dispatch){
    return {
        updateSetting:(change)=>dispatch(updateSetting(change))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(RecaptchaSection);
