import React from "react";
function InputGroup(props){
    const {prefix, suffix} = props;
    return <div className="input-group">
        {
            prefix && <span className="input-group-text">{prefix}</span>

        }
        <input className="form-control" {...props} value={props.value === undefined || props.value === null ? '': props.value}/>
        {
            suffix && <span className="input-group-text">{suffix}</span>
        }
    </div>
}
export default InputGroup
