import {ACTIONS} from "./affiliateAction";

const defaultSettings = {

};

export default (state = defaultSettings, action)=>{
  switch (action.type) {
      case ACTIONS.merge:
        return {...state, ...action.data};
    case ACTIONS.reset: {
      return {}
    }
    default:
      return state;
  }
}
