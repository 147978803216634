import React from 'react';
import WooCommerceIndex
  from './pages/installers/woocommerce_old/WooCommerceIndex';
import ActivatePlanIndex from './pages/subscriptions/ActivatePlanIndex';
import ActivateCharged from './pages/subscriptions/ActivateCharged';
import BigCommerceIndex from './pages/installers/bigcommerce/BigCommerceIndex';
import * as localStorage from './utils/storage';
import MailchimpPostAuth
  from './pages/settings/integrations/mailchimp/MailchimpPostAuth';
import SetRefCode from './pages/SetRefCode';
import RechargeOAuthCallback
  from './pages/settings/integrations/rechargepayments/RechargeOAuthCallback';
import PaypalCallback from './pages/billing/paypal/PaypalCallback';
import PaypalPayments from './pages/billing/paypal/PaypalPayments';
import ManagerLogin from './pages/manager-accounts/ManagerLogin';
import LogoutPage from './pages/login/LogoutPage';
import MasterPage from './pages/MasterPage';
import MasterLogin from './pages/manager-accounts/MasterLogin';
import LazyEmailEditorIndex from './pages/email-editor/LazyEmailEditorIndex';
import CustomSdkIntegration from './pages/installers/sdk/CustomSDKIntegration';
import {useSelector} from 'react-redux';
import LazyOauth2Index from './pages/oauth2/LazyOauth2Index';
import CanvasEditor from './pages/creatives-brochure/CanvasEditor';
import LazyLoginIndex from './pages/login/LazyLoginIndex';
import {Route, Routes} from 'react-router-dom';
import SquarepsaceInstall
  from './pages/installers/squarespace/SquarepsaceInstall';
import SquarespaceApiKey
  from './pages/installers/squarespace/SquarespaceAPIKey';
import WixInstall from './pages/installers/wix/WixInstall';
import WixCallback from './pages/installers/wix/WixCallback';
import SquareUpCallback from './pages/installers/squareup/SquareUpCallback';
import SquareUpIntegrationIndex
  from './pages/installers/squareup/SquareUpIntegrationIndex';
import ShopwiredInstall from './pages/installers/shopwired/ShopwiredInstall';
import EasyStoreInstaller
  from './pages/installers/easystore/EasyStoreInstaller';
import EcwidInstall from './pages/installers/ecwid/EcwidInstall';
import CommerceHqInstall from './pages/installers/commercehq/CommerceHqInstall';
import ShopLazzaInstall from './pages/installers/shoplazza/ShopLazzaInstall';
import ShopLazzaCallback from './pages/installers/shoplazza/ShopLazzaCallback';
import LazyTestPage from './test/LazyTestPage';
import LazyInstallers from './pages/installers/LazyInstallers';
import LazyPageLoader from './components/LazyPageLoader';
import ShopifyIndex from './pages/shopify/LazyShopifyIndex';
import {useSettings} from './redux/settings/settingsAction';
import ErrorBoundary from './components/ErrorBoundry';
import TawkToLoader from './components/TawkToLoader';
import LazyReferAFriendConfigureWidget
  from './pages/refer-a-friend/LazyReferAFriendConfigureWidget';
import {getAccessToken} from './utils/access_token_handler';
import LazyManageIndex from './pages/manager-accounts/LazyManageIndex';
import WebflowIndex from './pages/installers/webflow/WebflowIndex';

function App() {
  const access_token = useSelector((state)=>state.settings.access_token)|| getAccessToken();
  // console.log('app has access token', access_token)
  const hasMaster = localStorage.getItem('manager_access_token');
  useSettings()
  return (
      <Routes>
        <Route path={"/install/*"} element={<LazyInstallers />} />
        <Route path={'/squarespace/callback'} element={<SquarepsaceInstall/>}/>
        <Route path={'/squarespace/save_keys'} element={<SquarespaceApiKey/>}/>
        <Route path={'/squarespace'} element={<SquarepsaceInstall/>}/>
        <Route path={'/wix/install'} element={<WixInstall/>}/>
        <Route path={'/wix/callback'} element={<WixCallback/>}/>
        <Route path={'/wix'} element={<WixInstall/>}/>
        <Route path={'/webflow/*'} element={<WebflowIndex/>}/>
        <Route path={'/square/callback'} element={<SquareUpCallback/>}/>
        <Route path={'/shoplazza'} element={<ShopLazzaInstall/>}/>
        <Route path={'/shoplazza/callback'} element={<ShopLazzaCallback/>}/>
        <Route path={'/square'} element={<SquareUpIntegrationIndex/>}/>
        <Route path={'/shopwired'} element={<ShopwiredInstall/>}/>
        <Route path={'/easystore/install'} element={<EasyStoreInstaller/>}/>
        <Route path={'/ecwid/callback'} element={<EcwidInstall/>}/>
        <Route path={'/ecwid'} element={<EcwidInstall/>}/>
        <Route path={'/commercehq'} element={<CommerceHqInstall/>}/>
        <Route path={'/x/:ref_code'} element={<SetRefCode/>}/>
        <Route path={'/auth/*'} element={<LazyLoginIndex/>}/>
        <Route path={'/oauth/*'} element={<LazyOauth2Index/>}/>
        <Route path={'/shopify/*'} element={<ShopifyIndex/>}/>
        <Route path={'/woocommerce'} element={<WooCommerceIndex/>}/>
        <Route path={'/bigcommerce/*'} element={<BigCommerceIndex/>}/>
        <Route path={'/mailchimp/*'} element={<MailchimpPostAuth/>}/>
        <Route path={'/rechargepayments/*'} element={<RechargeOAuthCallback/>}/>
        <Route path={'/billing/paypal/activate'} element={<PaypalCallback/>}/>
        <Route path={'/billing/paypal/preactivate/:plan'}
               element={<PaypalPayments/>}/>
        <Route path={'/activate/plan/:plan_id'} element={<ActivatePlanIndex/>}/>
        <Route path={'/activate/charged'} element={<ActivateCharged/>}/>
        <Route path={'/manager-login'} element={<ManagerLogin/>}/>
        <Route path={"/manage/*"} element={<LazyManageIndex />} />
        <Route path={"/manager/*"} element={<LazyManageIndex />} />

        <Route path={'/logout'} element={<LogoutPage/>}/>
        {/*

          <Route path={"/shopify-sales-channel"} component={ShopifySalesChannelIndexLazy} />
            */}
        {
          process.env.NODE_ENV === 'development' ?
              <Route path={'/test'} element={<LazyTestPage/>}/>
              : null
        }
        <Route path={'/master'}
               element={hasMaster ? <MasterPage/> : <MasterLogin/>}/>
        <Route path={'/creatives-brochure/:media_id'}
               element={<CanvasEditor/>}/>

        {
          access_token ? <>
                <Route path={'/sdk'} element={<CustomSdkIntegration/>}/>
                <Route
                    path={'/settings/notifications/email-templates/:template_id/*'}
                    element={<LazyEmailEditorIndex/>}/>
                <Route path={"/settings/premium/refer-a-friend/edit-widget"} element={<LazyReferAFriendConfigureWidget />}/>
                <Route index path={'/*'} element={<LazyAdmin />}/>
              </> :
              <Route path={'/*'} element={<LazyLoginIndex/>}/>
        }
      </Routes>
  );
}


const Cmp = React.lazy(()=>import('./pages/AdminPage'))
function LazyAdmin(){
  // console.log('lazy admin loading')
  return <React.Suspense fallback={<LazyPageLoader />}>

      <Cmp />
    <div style={{height:100}}></div>
    <ErrorBoundary>
      <TawkToLoader />
    </ErrorBoundary>
  </React.Suspense>
}

export default App;
