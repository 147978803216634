import React, {Component} from 'react';
import ProgressLoader from "../../../components/ProgressLoader";
import {getHttpClient} from "../../../api";

class ActivatePlanDiscountForm extends Component {

    state = {
        coupon:'',
        discountLoading:false,
        discountError:null,
    };

    applyCoupon = (e)=>{
        if(e){
            e.preventDefault();
            e.stopPropagation();
        }
        const  {coupon} = this.state;
        const {plan_id} = this.props;
        this.setState({discountLoading:true, discountError:null});

        getHttpClient().post('/billing/coupon',{
            coupon,
            plan:plan_id
        }).then(({data})=>{
            console.log(data);
            const {discount} = data;
            if(discount > 0){
                this.setState({discount, discountError:null, discountLoading:false},()=>{
                    this.props.onCoupon({discount, coupon})
                });
            }else{
                this.setState({discount:0, discountError:'Invalid discount code', discountLoading:false});
            }
        }).catch((e)=>{
            this.setState({discountLoading:false});
        })
    };

    onCancel = (e)=>{
        this.setState({coupon:undefined, discountLoading:false, discountError:null});
        this.props.onCancel()
    };


    handleCouponChange = (e)=>{
        this.setState({coupon:e.target.value});
    };



    render() {
        const {coupon, discountError, discountLoading} = this.state;
        return (
            <form onSubmit={this.applyCoupon}>
                <input value={coupon} onChange={this.handleCouponChange} className={"form-control"} placeholder={"Discount Coupon"} disabled={discountLoading} />
                {
                    discountError ? <p className={"text-danger text-center"}>{discountError}</p> : null
                }
                {
                    discountLoading ? <div className={"my-2 text-center"}><ProgressLoader /></div> :         <div className={"mt-2 row justify-content-center no-gutters"}>
                        <div className={"col-auto"}>
                            <button type="button" className="btn btn-outline-secondary" onClick={this.applyCoupon}>Apply</button>
                        </div>
                        <div className={"col-auto"}>
                            <button type="button" className="ml-1 btn" onClick={this.onCancel}>Cancel</button>
                        </div>
                    </div>

                }
            </form>
        );
    }
}

export default ActivatePlanDiscountForm;
