import React, {useCallback, useMemo} from 'react';
import SettingsSection from '../components/SettingSection';
import ReactSwitch from 'react-switch';
import {updateSetting} from '../../../redux/settings/settingsAction';
import {useDispatch, useSelector} from 'react-redux';
import useToggle from '../../../components/hooks/useToggle';
import Modal from '../../../components/Modal';
import FormGroup from '../../../components/form/FormGroup';
import CopyButton from '../../../components/CopyButton';
import useThirdPartyTrackingScript
  from '../../../components/hooks/useThirdPartyTrackingScript';

function YampiCheckout(props) {
  const yampi_checkout_enabled = useSelector(
      (state) => state.settings.yampi_checkout_enabled);
  const dispatch = useDispatch();
  const onChange = useCallback((change) => {
    dispatch(updateSetting({
      yampi_checkout_enabled: change,
      cookieless_tracking_enabled: change,
    }));
  }, [dispatch]);
  const url = useThirdPartyTrackingScript();
  const code = useMemo(() => {
    return `<script type="text/javascript" src="${url}"></script>`;
  }, [url]);


  const [visible, toggle] = useToggle(false);
  const onOk = useCallback(() => {
    onChange(true);
    toggle();
  }, [onChange, toggle]);

  return (
      <SettingsSection title={'Yampi Checkout'}
                       subtitle={'Integrate Yampi checkout with your shopify store'}>
        {
          yampi_checkout_enabled ? <div>
            <ReactSwitch checked={yampi_checkout_enabled} onChange={onChange}/>
          </div> : null
        }
        <button onClick={toggle} className={'px-0 btn btn-link'}>See
          instructions
        </button>
        <Modal size={'lg'} visible={visible} onClose={toggle}
               onOk={onOk}
               okText={'Activate Integration'}
               onCancel={toggle}
               title={'Yampi Checkout Integration'}>
          <p>Copy and paste the following code in your <br/><code>Yampi Checkout
            admin page</code> &gt; <code>Additional Scripts</code> area
          </p>
          <img src={'/images/yampi-checkout-additional-scripts.png'}
               style={{width: '100%'}}
               alt={'Additional scripts section Yampi Checkout'}/>
          <FormGroup label={'Code'}>
            <textarea className={'form-control mb-2'}
                      readOnly
                      value={code}/>
            <CopyButton className={'btn btn-outline-primary btn-sm'}
                        title={'Copy'} text={code}/>
          </FormGroup>
        </Modal>
      </SettingsSection>
  );
}



export default YampiCheckout;
