import React, {Component} from 'react';
import {CloseIcon} from "../../components/Icons";
import ProgressLoader from "../../components/ProgressLoader";
import {getHttpClient} from "../../api";
import ActivatePlanDiscountForm from "./components/ActivatePlanDiscountForm";
import PropTypes from 'prop-types';
import {getCurrentQueryObject} from "../../utils/func";


class ActivatePlan extends Component {

    state = {
        planLoading:false,
        coupon:undefined,
        discount:0,
        planUpgrading:false,
    };

    removeCoupon = ()=>{
        this.setState({coupon:undefined, discount:0, discountLoading:false, discountError:null});
    };


    upgradePlan = (e)=>{
        this.setState({planUpgrading:true});
        const {plan_id} = this.props;
        const {coupon} = this.state;

        //if loaded within shopify iframe, redirect back within shopify website
        const return_url = window.top !== window.self ?
            `https://${getCurrentQueryObject().shop}/admin/apps/goaffpro/activate/charged` :
            `https://app.goaffpro.com/activate/charged`;

        getHttpClient().post('/billing/preactivate',{
            coupon,
            plan: plan_id,
            return_url
        }).then(({data})=>{
            console.log(data);
            this.setState({planUpgrading:false});
            const {confirmation_url} = data;
            const url = new URL(confirmation_url);
            const shop = url.host;
            if(window.top === window.self){
                window.location = confirmation_url
            }else{
                window.top.location = confirmation_url;
            }
        });
    };

    applyCoupon = ({discount, coupon})=>{
        this.setState({discount, coupon});
    };


    showCouponForm = ()=>{
        this.setState({coupon:''});
    };




    render() {
        const {coupon, discount,planUpgrading} = this.state;
        const { plan_name, plan_price, plan_id } = this.props;

        let couponCmp = null;
        if(discount){
            couponCmp = <DiscountApplied coupon={coupon} onRemove={this.removeCoupon} />
        }else if(coupon !== undefined){
            couponCmp = <ActivatePlanDiscountForm plan_id={plan_id} onCancel={this.removeCoupon} onCoupon={this.applyCoupon} />
        }else{
            couponCmp =  <DiscountLink onClick={this.showCouponForm} />
        }

        return (
            <div>
            <table className={"table table-striped"}>
                <thead>
                    <tr>
                        <th>Chosen Plan</th>
                        <th>Period</th>
                        <th className={"text-right"}>Pricing</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            {plan_name}
                        </td>
                        <td>
                            1 month
                        </td>
                        <td className={"text-right font-mono"}>
                            ${plan_price}
                        </td>
                    </tr>
                    {
                        discount ? <DiscountRow coupon={coupon} discount={discount} /> : null
                    }
                    <tr>
                        <td colSpan={"2"}>Total</td>
                        <td className={"text-right text-success"}>${plan_price-discount}</td>
                    </tr>
                </tbody>
            </table>
                    <div className={"row justify-content-between"}>
                        <div className={"col"}>
                            {
                                couponCmp
                            }
                        </div>
                        <div className={"col-auto"}>
                            {
                                planUpgrading ?<ProgressLoader/> :
                                    <button className={"btn btn-primary"} onClick={this.upgradePlan}>Upgrade</button>

                            }
                        </div>
                    </div>
            </div>
        );
    }
}


function DiscountLink({onClick}){
    return <button className={"btn btn-link px-0"} onClick={onClick}>Discount coupon ?</button>
}

function DiscountRow({coupon, discount}){
    return  <tr>
        <td colSpan={"2"}>Discount</td>
        <td className={"text-right text-danger"}>-${discount}</td>
    </tr>

}

function DiscountApplied({coupon, onRemove}){
    return <div className={"row no-gutters align-items-center"}>
        <div className={"col-auto"}>
            Coupon: {coupon}
        </div>
        <CloseIcon className={"col-auto"} style={{fontSize:18}} onClick={onRemove} />
    </div>
}


ActivatePlan.propTypes = {
    plan_name: PropTypes.string.isRequired,
    plan_id: PropTypes.string.isRequired,
    plan_price: PropTypes.number.isRequired
};


export default ActivatePlan;
