import React, {useEffect, useState} from 'react';
import {getHttpClient} from '../../../api';
import Select from '../../../components/form/Select';
import FormGroup from '../../../components/form/FormGroup';
import {setAccessToken} from '../../../utils/access_token_handler';
import Button from '../../../components/form/Button';
import Input from '../../../components/form/Input';
import Wrapper from './Wrapper';

function WebflowCallback(props) {
  const code = new URLSearchParams(window.location.search).get('code');
  const [loading, setLoading] = useState(false)
  const [website, setWebsite] = useState('')
  const [site_id, setSite] = useState(null)
  const [response, setResponse] = useState(null)
  useEffect(()=>{
    if(code){
      setLoading(true)
      getHttpClient().post('/webflow/callback',{
        code
      }).then((row)=>{
        setResponse(row.data)
        setLoading(false)
      }).catch((e)=>{
        setLoading(false)
      })
    }
  },[code])
  const [saving, setSaving] = useState(false)
  const found = response?.sites.find((i)=>i.id == site_id)
  useEffect(()=>{
    const found = response?.sites.find((i)=>i.id == site_id)
    if(!found) {
      setWebsite('')
    }else {
      const domain = found && Array.isArray(found.customDomains) &&
      found.customDomains.length > 0 ? found.customDomains[0]?.url : '';
      setWebsite(domain || `${found.shortName}.webflow.io`)
    }
  },[site_id, response?.sites])
  const submit = ()=>{
    setSaving(true)
    getHttpClient().post('/webflow/complete_installation',{
      site_id,
      store_name:found?.displayName,
      access_token: response?.access_token,
      website,
    }).then(({data})=>{
      setSaving(false)
      if(data.access_token){
        setAccessToken(data.access_token)
        window.location.assign('/')
      }
    }).catch((e)=>{
      setSaving(false)

    })
  }
  return (
      <Wrapper>

            <FormGroup label={"Choose site"}>
              <Select options={response?.sites.map((i)=>{
                return {
                  label:i.displayName,
                  value:i.id,
                }
              }) || []} defaultSelectFirst value={site_id} onChange={setSite}/>
            </FormGroup>
            {
              found && found.previewUrl ? <img src={found.previewUrl} alt={found.displayName} style={{maxWidth:'100%'}}/> : null
            }
            {
              found && found.customDomains.length === 0 ? <FormGroup label={"Store Domain"}>
                <Input prefix={'https://'} value={website} onChange={setWebsite} name={"url"} />
              </FormGroup> : null
            }
            <Button loading={saving} disabled={!website || !site_id} className={"btn btn-primary"} onClick={submit}>Submit</Button>

      </Wrapper>
  );
}

export default WebflowCallback;
