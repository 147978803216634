import React from 'react';

const scopes = [
    'read_script_tags','write_script_tags','read_customers','write_customers',
    'write_orders',
    'read_products',
    'read_orders']
function installApp(){
    const url = new URL("https://admin.easystore.co/oauth/authorize");
    url.searchParams.append('app_id','app4285b0ddbc22ba8e')
    url.searchParams.append('scope', scopes.join(","))
    url.searchParams.apennd('')
    window.location = " https://admin.easystore.co/oauth/authorize?app_id=&scope={scopes}&redirect_uri={redirect_uri}"
}
function EasyStoreInstaller(props) {



    return (
        <div></div>
    );
}

export default EasyStoreInstaller;
