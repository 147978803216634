import React, {useEffect} from 'react';
import {getHttpClient} from '../../../api';
import FakeProgressBar from '../../../components/FakeProgressBar';
import {useParams} from 'react-router-dom';

function PaypalPayments() {
    const params = useParams()
    useEffect(()=>{
        getHttpClient().post('/billing/paypal/preactivate',{
            plan: params.plan
        }).then(({data})=>{
            window.location = data.confirmation_url
        })
    },[params])

    return (
            <div className="min-vh-100 d-flex align-items-center justify-content-center">
                <div style={{width: 320}}>
                    <FakeProgressBar time={5}/>
                </div>
            </div>
        )
}

export default PaypalPayments;
