import React from 'react';
import LazyPageLoader from '../../components/LazyPageLoader';

const Page = React.lazy(()=>import('./ManageIndex'))

function LazyAffiliateTaskIndex(){
    return <React.Suspense fallback={<LazyPageLoader />}>
        <Page />
    </React.Suspense>
}


export default LazyAffiliateTaskIndex
