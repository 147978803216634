import {useSelector} from "react-redux";
import {useMemo} from "react";


export default function useVisitsTrackingCode() {
    const website_match = useSelector((state: any) => state.settings.website_match);
    const {
        url, script
    } = useMemo(() => {
        const url = `https://api.goaffpro.com/loader.js?shop=${website_match}`
        return {
            url,
            script: `<script type="text/javascript" src="${url}"></script>`
        }
    }, [website_match])

    return {
        url, script, website_match
    }
}
