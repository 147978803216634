import {randomString} from '../../../utils/func';
import {getAccessToken} from '../../../utils/access_token_handler';

const squareupConfig = {
    sandbox:{
        client_id:'sandbox-sq0idb-6fON8y53Yu-hzywnDiP-UQ',
        redirect_uri:'http://localhost:3000/square/callback',
        baseURL:'https://connect.squareupsandbox.com/'
    },
    live:{
        client_id:'sq0idp-WIkDXqBSVUzYaMvX4WUxIA',
        redirect_uri:'https://app.goaffpro.com/square/callback',
        baseURL:'https://connect.squareup.com/'
    }
}
const scopes = [
    "ONLINE_STORE_SNIPPETS_WRITE",
    "ONLINE_STORE_SNIPPETS_READ",
    "ONLINE_STORE_SITE_READ",
    "GIFTCARDS_READ",
    "GIFTCARDS_WRITE",
    "SUBSCRIPTIONS_READ",
    "ORDERS_READ",
    "CUSTOMERS_READ",
    "CUSTOMERS_WRITE",
    "ITEMS_READ",
    "MERCHANT_PROFILE_READ",
]


export function getSquareConnectUrl(sandbox){
    const {client_id, redirect_uri, baseURL} = sandbox ? squareupConfig.sandbox : squareupConfig.live;
    const state = randomString();
    try{
        sessionStorage.setItem('square_oauth_session_state', state)
    }catch(e){

    }
//    const urlWithRef = "https://squareup.com/t/cmtp_performance/pr_cross_product/d_partnerships/p_PARTNERNAME/cn_oauth/?"
    const u = new URL( "/oauth2/authorize", baseURL);
    u.searchParams.append('client_id', client_id)
    u.searchParams.append('session','false')
    u.searchParams.append('state', state)
//    u.searchParams.append('redirect_uri', redirect_uri);
    u.searchParams.append('scope',scopes.join(" "))
    return u.href;
}

export function getAppOauthUrl(){
    const access_token = getAccessToken();
    return `/square?connect=true${access_token ? `&access_token=${access_token}` : ''}`
}
