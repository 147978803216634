import React, {Component} from 'react';
import Modal from "../../../../components/Modal";
import FaqItem from "../../../../components/FaqItem";

class PaypalFaqModal extends Component {
    render() {
        return (
            <Modal title={"PayPal Payouts FAQ"} visible={this.props.visible} onClose={this.props.onClose}>
                <FaqItem title={"What is PayPal Payouts"} isOpen>
                    <small>PayPal payouts is a bulk payments solution offered by PayPal. This allows for quickly paying your affiliates directly from within the app. <a href={"https://developer.paypal.com/docs/payouts/"} rel={"noreferrer"} target={"_blank"}>Read more on PayPal's website</a></small>
                </FaqItem>
                <FaqItem title={"Any extra charges ?"} isOpen>
                    <small>This service is provided FREE from our end. However PayPal charges a flat fee $0.25 per transaction. See - <a href={"https://www.paypal.com/us/webapps/mpp/merchant-fees#paypal-payouts"} rel={"noreferrer"} target={"_blank"}>PayPal Payout Fees</a></small>
                </FaqItem>
                <FaqItem title={"Do I have to use it ?"} isOpen>
                    <small>No, this service is provided by us as a convenience for merchants having hundreds of affiliates to pay. You can also send money to your affiliates manually through your paypal dashboard and avoid paying the fees</small>
                </FaqItem>

            </Modal>
        );
    }
}

export default PaypalFaqModal;
