import React, {useCallback} from 'react';
import {BackButtonIcon} from "./Icons";
import {useNavigate} from 'react-router-dom'
export default function BackButton({to}:{to?: string}){
    const navigate = useNavigate()
    const onClick = useCallback(()=>{
         to ? navigate(to) : navigate(-1)
    },[navigate, to])

    return <button className={"d-flex btn align-items-center"} onClick={onClick}>
            <BackButtonIcon />
        <span className={"ml-2"}> Go Back</span>
    </button>
}
