import React, {Component} from 'react';
import {actionMergeSetting} from "../../../../redux/settings/settingsAction";
import {connect} from "react-redux";
import {Link} from 'react-router-dom';

class MailchimpConnect extends Component {

    openLink = ()=>{
        const url = new URL('https://login.mailchimp.com/oauth2/authorize');
        const array = {
            response_type:'code',
            client_id:'615242056688',
            redirect_uri:'https://app.goaffpro.com/mailchimp',
        }
        Object.keys(array).map((key)=>{
            url.searchParams.append(key, array[key]);
        })
        window.location = url.href;
    }

    render() {
        console.log(this.props);
        const { subscription }= this.props;
        if(subscription) {
            return <button className={"btn btn-secondary"} onClick={this.openLink}>Connect Mailchimp</button>
        }else{
            return <Link to={"/subscriptions"}>Upgrade to Sync</Link>
        }
    }
}

function mapStateToProps(state){
    return {
        subscription: state.settings.subscription === 1
    }
}
function mapDispatchToProps(dispatch){
    return {
        mergeSettings: (change)=>dispatch(actionMergeSetting(change))
    }
}



export default connect(mapStateToProps)(MailchimpConnect);
