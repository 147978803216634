import React, {useState} from 'react';
import FormGroup from '../../../components/form/FormGroup';
import {connect, useDispatch, useSelector} from 'react-redux';
import settingsStateToProps from '../../settings/settingsStateToProps';
import Select from '../../../components/form/Select';
import customSDK from './integration-instructions/CustomSDK';
import MemberVault from './integration-instructions/MemberVault';
import {
  SubblyCheckoutIntegrationInstructions,
} from '../../settings/integrations/IntegrationsTab';
import VimeoOttInstructions
  from './integration-instructions/VimeoOttInstructions';
import ThinkificIntegrationSteps
  from './integration-instructions/ThinkificIntegrationSteps';
import ShopbaseIntegration
  from './integration-instructions/ShopbaseIntegration';
import ThrivecartIntegrationSteps
  from './integration-instructions/ThrivecartIntegrationSteps';
import AmazonInstructions from './integration-instructions/AmazonInstructions';
import ShopifyViaCustomSdK
  from './integration-instructions/ShopifyViaCustomSdK';
import CartPanda from './integration-instructions/CartPanda';
import CopyButton from '../../../components/CopyButton';
import {updateSetting} from '../../../redux/settings/settingsAction';
import {FiCheckCircle} from 'react-icons/fi';
import Button from '../../../components/form/Button'
import useVisitsTrackingCode
  from '../../../components/hooks/useVisitsTrackingCode';
const platforms = {
  "Shopify":ShopifyViaCustomSdK,
  Subbly:SubblyCheckoutIntegrationInstructions,
  MemberVault,
  "Vimeo OTT":VimeoOttInstructions,
  "Thinkific": ThinkificIntegrationSteps,
  Shopbase,
  "Thrive Cart":ThrivecartIntegrationSteps,
  Amazon,
  "Custom SDK": customSDK,
  "Cart Panda": CartPanda,
  "Google Tag Manager":GoogleTagManager,
  "Volusion":Volusion,
}

function Volusion(){
  const dispatch = useDispatch()
  const {
    script,
    website_match,
  } = useVisitsTrackingCode();
  const volusion_checkout_enabled = useSelector((state)=>state.settings.volusion_checkout_enabled)
  const enableVolusion = ()=>{
    dispatch(updateSetting({
      volusion_checkout_enabled:true
    }))
  }
  return <>
    <p>Follow the steps below to integrate goaffpro via Volusion</p>
    <h3>Step 1</h3>
    <p>Add the following code to your volusion site footer area</p>
    <div className="mb-1">
      <input value={script}
             onFocus={(e) => e.target.setSelectionRange(0, script.length)}
             className={'form-control'}/>
    </div>
    <CopyButton text={script}
                className={'btn btn-outline-primary btn-sm'}></CopyButton>
    <hr/>
    <h3>Step 2</h3>
    <p>Click the button below to activate the above code</p>
        {
          volusion_checkout_enabled ? <>
            <span><FiCheckCircle className={"text-success"} style={{fontSize:22}} /> Activated</span>
          </> :
          <Button onClick={enableVolusion} className={"btn btn-primary"}>Activate</Button>
        }
  </>
}


function GoogleTagManager(){
  const website_match = useSelector((state)=>state.settings.website_match)
  const dispatch = useDispatch()
  const enableGTMAuto = ()=>{
    dispatch(updateSetting({
      gtm_auto_conversion_tracking:true
    }))
  }
  return <>
    <p>Follow the steps below to integrate goaffpro via Google Tag Manager</p>
    <ol>
      <li><a href={"https://goaffpro.com/gtm/template.tpl"} target={"_blank"}>Click here</a> to download the GTM template file on your computer</li>
      <li>Open your <a href={"https://tagmanager.google.com/#/home"} target={"_blank"}>Google Tag Manager admin panel</a></li>
      <li>Choose your container / account<br/><img src={"https://user-images.githubusercontent.com/1143211/203486772-b90d7771-b1d3-45bc-aa14-ab9ae2ac2ced.png"} style={{width:'100%'}} /></li>
      <li>Choose <code>Templates</code> in the sidebar and click <code>NEW</code> to create a new template<br/>
        <img src={"https://user-images.githubusercontent.com/1143211/203486778-88ff2b32-6e39-4c9d-9bec-29fdc053868f.png"} style={{width:'100%'}} />
      </li>
      <li>In the template editor, import the template provided in the Step 1.<br/>
      <img src={"https://user-images.githubusercontent.com/1143211/203486784-93cac93a-b1ce-4e7e-9593-acd498c68836.png"} style={{width:'100%'}}/>
      </li>
      <li>Click Save once the import is completed <br/>
        <img src={"https://user-images.githubusercontent.com/1143211/203487076-adf35bef-3b68-490b-aaf0-8e56872b213e.png"} style={{width:'100%'}}/>
      </li>
      <li>Now you can add a new tag to your container which loads and runs the
        goaffpro code
        <img
            src={'https://user-images.githubusercontent.com/1143211/203486800-b9af3a63-ba04-4320-97b2-50fc9ae3d8e8.png'}
            style={{width: '100%'}}/>
        <br/> <br/>

        <img
            src={'https://user-images.githubusercontent.com/1143211/203486808-e335ee71-3191-4c15-b8bd-8d9838ea1a88.png'}
            style={{width: '100%'}}/>
        <br/>
        <br/>
      </li>
      <li>In the Website API Token field, enter the following code
        <input className={"form-control mb-2 w-25"} value={website_match} />
        <CopyButton text={website_match} className={"btn btn-outline-primary btn-sm"} />
        <img src={"https://creatives.goaffpro.com/7/4lenm0k9g6-screenshot-2024-03-26-at-103841-am.png"} style={{width:'100%'}} />
      </li>
    </ol>
  </>
}

function Amazon(){
  return <>
    <p>Click the button below to start Integration with your Amazon store</p>
    <AmazonInstructions />
  </>
}

function Shopbase(){
  return <>
    <p>Turn on the switch below to activate the Shopbase integration</p>
    <ShopbaseIntegration />
  </>
}

const options = Object.keys(platforms).map((item)=>{
  return {
    label: item,
    value: item,
  }
})

function CustomSdkIntegrationSteps({website_match}){
    const [chosenPlatform, setChosenPlatform] = useState('')
    const Cmp = platforms[chosenPlatform]
    return (
        <>
          <FormGroup label={"Choose your platform"} info={"Select your platform. If your platform is not listed here, choose Custom SDK"}>
            <Select hasEmpty options={options} value={chosenPlatform} onChange={setChosenPlatform}/>
          </FormGroup>
          {
            Cmp ? <Cmp /> : null
          }
        </>
    )
}


export default connect(settingsStateToProps(['website_match']))(CustomSdkIntegrationSteps);
