import React from 'react';
import LazyPageLoader from '../../components/LazyPageLoader';

const Page = React.lazy(()=>import('./InstallersIndex'))

function LazyInstallers(props){
    return <React.Suspense fallback={<LazyPageLoader />}>
        <Page />
    </React.Suspense>
}



export default LazyInstallers
