import React from 'react';
import SettingsSection from "../../components/SettingSection";
import {connect} from "react-redux";
import settingsStateToProps from "../../settingsStateToProps";
import {Link} from "react-router-dom";
import settingsDispatchToProps from "../../premiumextras/settingsDispatchToProps";
import ConfirmButton from "../../../../components/ConfirmButton";

function StripeIntegrationSection({stripe_connected_account_is_active,stripe_private_api_key, updateSettings, stripe_connected_account_id}) {
    function confirmAndDisconnect(){
        updateSettings({
            stripe_connected_account_id: null,
            stripe_connected_account_is_active:null,
        })
    }
    function reset(){
        updateSettings({
            stripe_connected_account_id: null,
            stripe_connected_account_is_active:null,
        })
    }
    return (
        <SettingsSection title={"Stripe"} subtitle={'Integrate stripe for collecting membership fees from affiliates'}>
            {
                stripe_connected_account_is_active || stripe_private_api_key ? <>
                        <p>Connected</p>
                        <ConfirmButton message={"Disconnect stripe ?"} onClick={confirmAndDisconnect} className={"btn btn-link px-0"}>Disconnect</ConfirmButton>
                    </>
                    : <>
                        <Link to={"/settings/integrations/stripe"}>{stripe_connected_account_id ? "Retry Integration" : "Setup"}</Link>
                        {
                            stripe_connected_account_id ? <>
                                    <span className={"mx-4 text-muted"}>|</span>
                                    <ConfirmButton
                                        message={"Reset stripe account ? You will need to fill all the details for stripe verification again."}
                                        onClick={reset} className={"btn btn-link p-0 mx-2"}>Reset Stripe account</ConfirmButton>
                                </>
                                : null
                        }
                    </>
            }
        </SettingsSection>
    );
}

export default connect(settingsStateToProps([
    'stripe_connected_account_id',
    'stripe_connected_account_is_active','stripe_private_api_key'
]), settingsDispatchToProps)(StripeIntegrationSection);
