

export default (state = [], action)=>{
    switch (action.type) {
        case "paymentplans-set":
            return action.data;
        case "paymentplans-add":
            return [action.data, ...state];
        case "paymentplans-delete":
            return state.filter((item)=> String(item.plan_id) !== String(action.plan_id))
        case "paymentplans-update":
            return state.map((item)=>{
                return String(item.plan_id) === String(action.plan_id) ? {...item, ...action.change} : item;
            })
        default:
            return state;

    }
}

