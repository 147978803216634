import React, {Component} from 'react';
import {wixConfig} from '../../../config';
import {getCurrentQueryObject} from '../../../utils/func';
import ProgressLoader from '../../../components/ProgressLoader';
import {getHttpClient} from '../../../api';
import * as localStorage from '../../../utils/storage';

class WixInstall extends Component {
  state = {};
  oauthRedirect = async () => {
    const q = getCurrentQueryObject();
    if (q.instance) {
      const data = await getHttpClient().post('/wix/login', {
        instance: q.instance,
      }).then(({data}) => data);
      if (data.access_token) {
        localStorage.setItem('access_token', data.access_token);
        window.location = '/';
      } else {
        if (data.error) {
          if (data.code === 'NEEDS_INSTALLATION') {
            this.startInstallation(q);
          } else {
            this.setState({error: data.error});
          }
        }
      }
    } else {
      window.location.assign('https://api.goaffpro.com/wix/install')
      console.log('install the app from within your wix store');
    }
  };

  startInstallation = (q) => {
    const {appId, redirect_url} = wixConfig;
    const token = q && q.token ? `&token=${q.token}` : '';
    const websiteUrl = `https://www.wix.com/app-oauth-installation/consent?appId=${appId}&redirectUrl=${redirect_url}${token}`;
    if (window.self !== window.top) { // checking if it is an iframe
      window.parent.location.href = websiteUrl;
    } else {
      window.location.href = websiteUrl;
    }
  };

  componentDidMount() {
    this.oauthRedirect();
  }

  render() {
    return (
        <div
            className="container flex align-items-center justify-content-center">
          <div className={'py-4 my-4 text-center'}>
            {
              this.state.error ?
                  <textarea className={'form-control'}
                            value={this.state.error}/> :
                  <ProgressLoader/>

            }
          </div>
        </div>
    );
  }
}

export default WixInstall;
