import React, {useEffect, useMemo} from 'react';
import {getCurrentQueryObject} from '../../utils/func';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {getAccessToken, setAccessToken} from '../../utils/access_token_handler';
import {useDispatch} from 'react-redux';
import {
  actionMergeSetting,
  loadSettings,
} from '../../redux/settings/settingsAction';

function IframeCookieFix() {
  const params = useParams();
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const token = useMemo(()=>{
    // console.log('received', params.access_token, searchParams.get('token'))
    return params.access_token || searchParams.get('token')
  },[params?.access_token, searchParams.get('token')])
  useEffect(() => {
    if(token){
      // console.log('token received', token)
        if(token === "undefined"){
          navigate("/",{
            replace:true
          })
        }else{
          // console.log('setting access token', token)
          setAccessToken(token)
          dispatch(actionMergeSetting({
            access_token: token
          }))
          dispatch(loadSettings()).then(()=>{
            const {then} = getCurrentQueryObject() || {then: ''};
            // console.log('received then', then)
            if (then && then.startsWith('/')) {
             //  console.log('replacing the location to ', then)
             // // window.location.replace(then);
             //  console.log('replaced')
              navigate(then, {
                replace: true
              })
            } else {
              // console.log('replacing the location to ', window.location.href.replace(/\/t\/.+/, ''))
              window.location.replace(window.location.href.replace(/\/t\/.+/, ''));
              // console.log('replaced to new location')
              // window.location.href = window.location.href.replace(/\/t\/.+/, '');
            }
          })
        }
      }
  }, [token, navigate]);
  return <p className="text-center py-4">Just a moment...</p>;
}

export default IframeCookieFix;
