import React, {useCallback} from 'react';
import SettingsSection from "../../components/SettingSection";
import ReactSwitch from "react-switch";
import {useDispatch, useSelector} from "react-redux";
import type {ReduxStore} from "../../../../redux/settings/SettingsTypes";
import {updateSetting} from "../../../../redux/settings/settingsAction";
import {Link} from "react-router-dom";

function GoogleTagManagerSettingsSection(props) {
    const gtm_auto_conversion_tracking = useSelector<ReduxStore, boolean>((state)=>state.settings.gtm_auto_conversion_tracking)
    const dispatch = useDispatch();
    const onChange = useCallback((gtm_auto_conversion_tracking:boolean)=>{
        dispatch(updateSetting({
            gtm_auto_conversion_tracking: gtm_auto_conversion_tracking ? true : null,
        }))
    },[])
    return (
        <SettingsSection title={"Google tag manager"} subtitle={"Automatic conversion tracking if you record conversions in Google Analytics"}>
            {
                gtm_auto_conversion_tracking ? <div>
                        <ReactSwitch checked={gtm_auto_conversion_tracking} onChange={onChange} />
                </div> : null
            }
            <Link to={"gtm-auto-conversions"}>Setup</Link>
        </SettingsSection>
    );
}

export default GoogleTagManagerSettingsSection;
