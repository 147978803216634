import React, {useEffect, useMemo, useState} from 'react';
import {getHttpClient} from '../../api';
import ProgressLoader from '../../components/ProgressLoader';
import ActivatePlan from './ActivatePlan';
import withHistory from '../../WithHistory';
import {useParams} from 'react-router-dom';

function ActivatePlanIndex() {
    const [planLoading, setPlanLoading] = useState(true)
    const [data, setData] = useState({
        name: null,
        price: null
    })

    const params = useParams();
    const plan_id = useMemo(()=>params.plan_id, [params.plan_id])
    useEffect(()=> {
        setPlanLoading(true)
        getHttpClient().get(`/billing/plans`)
        .then(({data})=>{
            const {name, price} = data[plan_id];
            setData({name, price})
            setPlanLoading(false)
        }).catch((e)=>{
            setPlanLoading(false)
            //TODO log this error since plan upgrades is the most important screen
        })
    },[plan_id])
    const { name, price} = data;
    return (
        <UpgradeContainer>
            <h2 className={"mb-2"}>Upgrading Plan</h2>
            {
                planLoading ?   <PlanLoading /> : <ActivatePlan plan_name={name} plan_id={plan_id} plan_price={price}/>
            }
        </UpgradeContainer>
    );
}

function PlanLoading(){
    return <div style={{minHeight:'200px'}} className={"row justify-content-center align-items-center"}>
        <ProgressLoader />
    </div>
}


function UpgradeContainer({children}){
    return <div className={"container-fluid bg-light"}>
        <div className={"row min-vh-100 align-items-center justify-content-center"}>
            <div className={"col-md-4"}>
                <div className={"card"}>
                    <div className={"card-body"}>
                        {children}
                    </div></div></div>
        </div></div>
}

export default withHistory(ActivatePlanIndex);
