import React from 'react';
import CopyButton from "../../../components/CopyButton";
import Modal from '../../../components/Modal';
import SettingsSection from '../components/SettingSection';
import useToggle from "../../../components/hooks/useToggle";

function SquarespaceScheduling(props) {
    const [visible, toggle] = useToggle()
    return (
        <SettingsSection title={"Squarespace/Acuity Scheduling"}
                         subtitle={"See how to integrate Goaffpro with Squarespace Scheduling"}>
            <button onClick={toggle} className={"btn btn-link px-0"}>See instructions</button>
            <SquarespaceAcuityScheduling visible={visible} onClose={toggle}/>
        </SettingsSection>
    );
}


function SquarespaceAcuityScheduling({visible, onClose}) {
    const code = `<script type="text/javascript">
       var goaffproOrder = {
            id: "%id%",
            total: "%price%",
        }
        if (window.top && window.top !== window.self) {
            window.top.postMessage({
                goaffproOrder: goaffproOrder
            })
        } else if(typeof goaffproTrackConversion !== "undefined"){
            goaffproTrackConversion(goaffproOrder);
        }
      </script>`
    return <Modal title={"Squarespace Scheduling"} visible={visible} onClose={onClose}>
        <ul>
            <li>
                In the <strong>Home Menu</strong>, click <strong>Scheduling</strong>, then
                click <strong>Integrations</strong>.
            </li>
            <li>
                In the <strong>Analytics & conversion</strong> tracking section, click <strong>Set up for Custom
                conversion tracking</strong>.
            </li>
            <li>
                Paste the code below in the HTML tracking code field, then click Save settings.
            </li>
        </ul>
        <div className="form-group">
            <textarea readOnly className={"form-control mb-1"} value={code}></textarea>
            <CopyButton className={"btn btn-outline-primary"} text={code}/>
        </div>

        <p>That's it. The conversion tracking is now set for squarespace scheduling</p>
    </Modal>
}

export default SquarespaceScheduling;
