import React, {Component} from 'react';
import SettingsSection from "../../components/SettingSection";
import Button from "../../../../components/form/Button";
import Modal from "../../../../components/Modal";
import CopyButton from "../../../../components/CopyButton";
import Label from "../../../../components/form/Label";
import {connect} from "react-redux";
import {SettingsMap} from "../../../../components/VideoAndHelpSectionMap";

class ZipifyOcuSection extends Component {
    state = {

    }
    script = (website_match)=>`<script>
    var order_id = {{order_id}}
    var Shopify = {
        checkout:{
            order_id
        }
    }
    </script>
    <script type="text/javascript" src="https://api.goaffpro.com/loader.js?shop=${website_match}"></script>`

    toggleVisibility = ()=>{
        this.setState({modal:!this.state.modal})
    }

    render() {
        let script  = this.script(this.props.website_match).trim()
        return (
            <SettingsSection title={"Zipify OCU"} helpLink={SettingsMap.get('Integrations').ZipifyOCU.helpLink} videoID={SettingsMap.get('Integrations').ZipifyOCU.videoID} subtitle={"Integrate conversion tracking with Zipify OCU app"}>
                <Button className={"btn btn-secondary"}  onClick={this.toggleVisibility}>Setup</Button>
                <Modal size={"lg"} title={"Zipify OCU Integration"} onOk={this.toggleVisibility} visible={this.state.modal} onClose={this.toggleVisibility}>
                    <p>Add the following code to the Thank You page of Zipify OCU app.</p>
                    <img src={"/images/zipify_gif.gif"} className={"img-responsive"} style={{width:'100%'}} alt={"Zipify Thank you page script"} />
                    <div className={"mt-2"}/>
                    <Label title={"Integration code"} />
                    <textarea rows={4} className={"form-control "} readOnly value={script} />
                    <CopyButton className={"btn btn-link"} copyBtnText={"Copy Code"} text={script} />
                </Modal>
            </SettingsSection>
        );
    }
}
function mapStateToProps(state){
    return {
        website_match: state.settings.website_match
    }
}

export default connect(mapStateToProps)(ZipifyOcuSection);
