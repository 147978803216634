import React from "react";

export default function ({htmlFor, title, children, required}: {
    htmlFor?: string
    title: string,
    children?: React.ReactNode,
    required?: boolean
}) {
    return <label htmlFor={htmlFor}>
        <small className={'font-weight-bold'}>{children}{title} {required &&
            <span className={"text-danger"}>*</span>}</small>
    </label>
}
