

export default (state = [], action)=>{
    switch(action.type){
        case "groups-set":
            return action.data
        case "groups-update":
            return state.map((item)=>String(item.id) === String(action.id)  ? {...item, ...action.change} : item)
        case "groups-add":
            return [action.data, ...state]
        case "groups-delete":
            return state.filter((item)=>String(item.id) !== String(action.id))
        default:
            return state;
    }
}

