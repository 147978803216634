import React from 'react';
import LazyPageLoader from '../../components/LazyPageLoader';

const Page = React.lazy(()=>import('./CanvasEditorInternal'))

function CanvasEditor(props){
  return <React.Suspense fallback={<LazyPageLoader />}>
    <Page {...props} />
  </React.Suspense>
}

export default CanvasEditor
