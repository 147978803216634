import React from 'react';
import LazyPageLoader from '../../components/LazyPageLoader';

const Page = React.lazy(()=>import('./Oauth2Index'))
function LazyOauth2Index(props){
    return <React.Suspense fallback={<LazyPageLoader />}>
        <Page {...props} />
    </React.Suspense>
}
export default LazyOauth2Index;
