import React, {useCallback} from 'react';

function Input(props) {
  const changed = useCallback((e)=>{
    if(props.onChange){
      if(props.maxLength && e.target.value.length > props.maxLength) {
        return props.onChange(e.target.value.substring(0, props.maxLength));
      }
      props.onChange(e.target.value)
    }
  },[props])
  return (
      <Wrapper prefix={props.prefix} suffix={props.suffix}>
        <input className={"form-control"} {...props} onChange={changed}/>
        {
          props.maxLength &&
            <div>
              <small className="text-muted">{props.maxLength - (props.value || "").length} characters left</small>
            </div>
        }
      </Wrapper>
  );
}

function Wrapper({prefix, children, maxLength, suffix}){
  if(prefix || suffix){
    return <div className="input-group position-relative">
    {
        prefix && <span className="input-group-text">{prefix}</span>

    }
      {children}
    {
        suffix && <span className="input-group-text">{suffix}</span>
    }
  </div>
    }
  return children
}

export default Input;
