import React from 'react';
import LazyPageLoader from '../../components/LazyPageLoader';

const Page = React.lazy(()=>import('./EmailEditorIndex'))

function LazyEmailEditorIndex(props){
    return <React.Suspense fallback={<LazyPageLoader />}>
        <Page />
    </React.Suspense>
}


export default LazyEmailEditorIndex
