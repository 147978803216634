import React, {Component} from 'react';
import SettingsSection from "../../components/SettingSection";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import Button from "../../../../components/form/Button";
import {updateSetting} from "../../../../redux/settings/settingsAction";
import ProgressLoader from "../../../../components/ProgressLoader";
import PaypalFaqModal from "./PaypalFAQModal";
import {SettingsMap} from "../../../../components/VideoAndHelpSectionMap";

class PaypalPayoutsSection extends Component {

    state = {
        removing:false,
        faqModal:false
    }

    removeCredentials = (e)=>{
        this.setState({removing:true});
        this.props.updateSetting({enc_paypal_client_id: null, enc_paypal_client_secret: null, has_custom_paypal_enc: null})
            .then((e)=>{
                this.setState({removing:false})
            })
    }

    showFaqModal = ()=>{
        this.setState({faqModal:true});
    }

    hideFaqModal = ()=>{
        this.setState({faqModal:false});
    }

    render() {
        return (
            <SettingsSection title={"Paypal Payouts"} helpLink={SettingsMap.get('Integrations').PaypalPayouts.helpLink} videoID={SettingsMap.get('Integrations').PaypalPayouts.videoID} subtitle={<span>Send payments to your affiliates quickly from within the app. <button onClick={this.showFaqModal} className={"btn btn-link px-0"}>Read FAQ</button></span>}>
                <PaypalFaqModal visible={this.state.faqModal} onClose={this.hideFaqModal} />
                {
                    this.state.removing ? <ProgressLoader /> :
                    this.props.enc_paypal_client_id ? <div>
                        <div className={"text-success"}>Connected</div>
                        {
                            <div className="d-flex align-items-center">
                                <Link to={"/settings/integrations/configure/paypal"}>Re-Configure</Link>
                                <span className={"mx-4 text-muted"}>|</span>
                                <Button onClick={this.removeCredentials} className={"px-0 btn text-danger"} loading={this.state.removing}>Disconnect</Button>
                            </div>
                        }
                    </div> : <Link className="btn btn-secondary" to={"/settings/integrations/configure/paypal"}>Setup</Link>
                }

            </SettingsSection>
        );
    }
}

function mapStateToProps(state){
    return {
        enc_paypal_client_id: state.settings.enc_paypal_client_id,
        has_custom_paypal_enc: state.settings.has_custom_paypal_enc,
    }
}

function mapDispatchToProps(dispatch){
    return {
        updateSetting: (change)=>dispatch(updateSetting(change))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(PaypalPayoutsSection);
