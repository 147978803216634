import React, {useState} from 'react';
import FormGroup from '../../../components/form/FormGroup';
import Button from '../../../components/form/Button';
import {getCurrentQueryObject} from '../../../utils/func';
import {getHttpClient} from '../../../api';
import DocumentTitle from '../../../components/DocumentTitle';
import * as localStorage from '../../../utils/storage';

export default function ShopwiredInstall() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',

    apiKey: '',
    apiPassword: '',
    website_match: '',
    storeName: '',
  });

  const onChange = (key) => e => {
    setFormData({...formData, [key]: e.target.value});
  };

  const [error, setError] = useState('');
  const [saving, setSaving] = useState(false);

  const {mtoken} = getCurrentQueryObject();

  const doInstall = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setSaving(true);
    getHttpClient().post('/shopwired/save_keys', {
      name, email, password,
      shopwired_api_key: apiKey,
      shopwired_api_secret: apiPassword,
      website,
      merchant_token: mtoken,
    }).then(({data}) => {
      setSaving(false);
      if (data.error) {
        setError(data.error);
      } else if (data.access_token) {
        localStorage.setItem('access_token', data.access_token);
        window.location = '/wizard';
      }
    }).catch((e) => {
      setSaving(false);
    });
  };
  const {name, email, password, website, apiKey, apiPassword} = formData;
  const canSubmit = (mtoken || (name && password && email)) && apiKey &&
      apiPassword;

  return (
      <div className={'bg-light'}>
        <div className={'container-fluid '}>
          <div className={'row justify-content-center min-vh-100'}
               style={{paddingTop: '80px'}}>
            <div className={'col-md-8 col-lg-6'}>

              <DocumentTitle title={'Store Setup'}/>
              <h3 className={'text-center'}>Create store account</h3>
              <div className={'card'}>
                <div className={'card-body'}>
                  <form onSubmit={doInstall}>
                    {
                        !mtoken && <>
                          <h4>Admin account</h4>
                          <FormGroup label={'Your name'}>
                            <input type="text" name={'name'} value={name}
                                   onChange={onChange('name')}
                                   className="form-control"/>
                          </FormGroup>
                          <FormGroup label={'Email address'}>
                            <input type="email" name={'email'} value={email}
                                   onChange={onChange('email')}
                                   className="form-control"/>
                          </FormGroup>
                          <FormGroup label={'Password'}>
                            <input type="password" name={'password'}
                                   value={password}
                                   onChange={onChange('password')}
                                   className="form-control"/>
                          </FormGroup>
                          <hr/>
                        </>
                    }
                    <h4>API Keys</h4>
                    <ol>
                      <li>Login to your <strong>Shopwired admin panel</strong>
                      </li>
                      <li>Click on your <code>Account ID</code>, then click
                        on <code>Your Account</code> option
                      </li>
                      <img src={'/images/shopwired-help-2.png'}
                           alt={'shopwired help'} style={{maxWidth: '100%'}}/>
                      <li>Scroll down to API Access and Copy paste the Keys
                        below
                      </li>
                    </ol>
                    <FormGroup label={'API Key'}>
                      <input type="text" value={apiKey}
                             onChange={onChange('apiKey')}
                             className="form-control"/>
                    </FormGroup>
                    <FormGroup label={'API Secret'}>
                      <input type="text" value={apiPassword}
                             onChange={onChange('apiPassword')}
                             className="form-control"/>
                    </FormGroup>
                    <hr/>
                    <FormGroup label={'Your Website'}>
                      <input type="text" value={website}
                             onChange={onChange('website')}
                             className="form-control"/>
                    </FormGroup>

                    <Button
                        loading={saving}
                        disabled={!canSubmit} onClick={doInstall}
                        className={'btn btn-primary'}>Create account</Button>
                    {
                        error && <div className={'pt-2 text-center'}>
                          <div className={'alert alert-danger'}>
                            {error}
                          </div>
                        </div>
                    }
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );

}
