import React, {Component} from 'react';
import SettingsSection from '../../components/SettingSection';
import {updateSetting} from '../../../../redux/settings/settingsAction';
import {connect} from 'react-redux';
import ReactSwitch from 'react-switch';
import Label from '../../../../components/form/Label';
import {getCurrentQueryObject} from '../../../../utils/func';
import {shopify} from '../../../../config';
// import * as Redirect from "@shopify/app-bridge/actions/Navigation/Redirect";
//import shopifyApp from "../../../../utils/shopifyApp";
import {getHttpClient} from '../../../../api';
import ProgressLoader from '../../../../components/ProgressLoader';
import {Link} from 'react-router-dom';
import FormGroup from '../../../../components/form/FormGroup';
import {SettingsMap} from '../../../../components/VideoAndHelpSectionMap';
import CreatableReactSelect
    from '../../../../components/react-select/CreatableReactSelect';
import ReactSelect from '../../../../components/react-select/ReactSelect';

let options = [
    {
        label:'Affiliate\'s Name',
        value:'name'
    },
    {
        label:'Affiliate\'s Email',
        value:'email'
    },
    {
        label:'Affiliate\'s Commission',
        value:'commission'
    },
    {
        label:'Affiliate\'s Referral Code',
        value:'ref_code'
    },
    {
        label:'Affiliate\'s ID',
        value:'affiliate_id'
    },
    {
        label:'Affiliate\'s Group ID',
        value:'group_id'
    }
];

let real_tags = [
    {
        label:'Affiliate\'s Name',
        value:'name'
    },
    {
        label:'Affiliate\'s Email',
        value:'email'
    },
    {
        label:'Affiliate\'s Referral Code',
        value:'ref_code'
    },
    {
        label:'Goaffpro',
        value:'Goaffpro'
    },
    {
        label:'Affiliate',
        value:'Affiliate'
    },
    {
        label:'Affiliate\'s Group ID',
        value:'group_id'
    }
];

export function updateApp(shopify_domain){
    const query = getCurrentQueryObject();
    const shop = shopify_domain || query.shop;
    if(shop) {
        const nonce = new Date().getTime();
        const redirect_uri = 'https://app.goaffpro.com/shopify/auth_callback' || shopify.redirect_url;
        const permissionUrl = `https://${shop}/admin/oauth/authorize?client_id=${shopify.clientId}&scope=${shopify.scopes},write_orders&redirect_uri=${redirect_uri}&state=${nonce}&grant_options[]=offline`;
        if(window.top === window.self){
            window.location = permissionUrl
        }else{
            window.top.location = permissionUrl
        }
    }else{
        return {error:'Please install the app from within shopify app store'}
    }
}


class OrderTags extends Component {

    state = {
        selected_opts: undefined
    }

    getValue = (key)=>{
        return this.state[key] === undefined ? this.props[key] : this.state[key];
    }

    onChange = (data)=>{
        this.props.updateSetting({order_tags: JSON.stringify(data.map((item)=>item.value))})
    }

    onToggleChange = (order_tags_enabled)=>{
        if(order_tags_enabled) {
            if(this.props.upgrade_required){
                return this.setState({error:<>You need to <Link to={"/subscriptions"}>upgrade</Link> to use order tags</>})
            }
            this.setState({show_perms_error:false, checking_perms:true})
            getHttpClient().get('/shopify/is_installed?shop=' + this.props.website_match)
                .then(({data}) => {
                    if (data.scopes_array.indexOf('write_orders') > -1) {
                        this.props.updateSetting({order_tags_enabled})
                    } else {
                        this.setState({show_perms_error: true})
                    }
                    this.setState({checking_perms: false})
                }).catch((e)=>{
                    console.log()
            })
        }else{
            this.props.updateSetting({order_tags_enabled})
        }
    }

    onRealTags = (data)=>{
        this.props.updateSetting({real_order_tags: JSON.stringify(data.map((item)=>item.value))})

    }

    requestPermission = ()=>{
        updateApp(this.props.website_match);
    }

    render() {

        return (
            <SettingsSection title={"Order tags"} helpLink={SettingsMap.get('Advanced').OrderTags.helpLink} videoID={SettingsMap.get('Advanced').OrderTags.videoID}
                             subtitle={"Add extra information in your admin panel's order details page. You can also use dynamic tags to retrieve query parameters from the referral link etc. {{utm_source}} etc."}>
                <div className="form-group">
                    {
                        this.state.error && <p>{this.state.error}</p>
                    }
                    {
                        this.state.checking_perms ? <ProgressLoader/> :
                            <ReactSwitch checked={this.getValue('order_tags_enabled')} onChange={this.onToggleChange}/>
                    }
                    {
                        this.state.show_perms_error && <p className={"text-danger"}>App update is required for this functionality.<br/><button className={"btn btn-link"} onClick={this.requestPermission}>Click here to update</button></p>
                    }
                </div>
                <div className="form-group">
                    {
                        this.getValue('order_tags_enabled') &&
                            <>
                                <FormGroup>
                            <Label title={"Information to add to order"}/>
                                <ReactSelect options={options} value={this.getValue('order_tags')} onChange={this.onChange} isMulti closeOnMenuSelect={false} />
                            </FormGroup>
                                <FormGroup>
                                    <Label title={"Tags to add"}/>
                                    <CreatableReactSelect options={real_tags}
                                                     value={this.getValue('real_order_tags')}
                                                     onChange={this.onRealTags} isMulti
                                                     closeOnMenuSelect={false} />
                                    <small className={"text-muted"}>You can also write your own tags in the field above above</small>

                                </FormGroup>
                        </>
                    }
                </div>
            </SettingsSection>
        );
    }
}


function mapStateToProps(state) {
    const {order_tags, order_tags_enabled, real_order_tags} = state.settings;
    const r = order_tags ? JSON.parse(order_tags).map((item)=>{
        return options.find((itm)=>itm.value === item);
    }).filter((item)=>item) : [];

    const real_tags = real_order_tags ? JSON.parse(real_order_tags)
        .filter((item)=>item)
        .map((item)=>{
            return options.find((itm)=>itm.value === item) || {label:item, value: item};
        }) : [];

    return {
        order_tags: r,
        order_tags_enabled,
        website_match: state.settings.website_match,
        real_order_tags: real_tags,
        upgrade_required: state.settings.subscription !== 1,
    };
}



const mapDispatchToProps = (dispatch, props)=>{
    return {
        updateSetting:(change)=>dispatch(updateSetting(change))
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(OrderTags);
