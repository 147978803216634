import React from "react";
import {CloseIcon, PlusIcon} from "./Icons";
import PropTypes from 'prop-types';

class FaqItem extends React.PureComponent{

    constructor(props){
        super(props);
        this.state = {
            isOpen:props.isOpen || false
        };
    }

    toggle = ()=>{
        this.setState({isOpen:!this.state.isOpen})
    }

    render(){
        const isOpen = this.state.isOpen;
        return <li className="list-group-item">
            <div className={`d-flex justify-content-between align-items-center`}
                 onClick={this.toggle}>
                <div className={""}>
                    {this.props.title}
                </div>
                {
                    isOpen ? <CloseIcon /> : <PlusIcon />
                }
            </div>
            {
                isOpen &&  <div className={"text-muted"}>
                    {this.props.children}
                </div>
            }
        </li>
    }
}

FaqItem.propTypes = {
    title: PropTypes.any,
    children: PropTypes.any
}
export default FaqItem
