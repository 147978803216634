import React, {useEffect} from 'react';
import * as storage from '../utils/storage';
import {useNavigate, useParams} from 'react-router-dom';

function SetRefCode() {
    const params = useParams()
    const navigate = useNavigate()
    useEffect(()=>{
        const ref_code = params.ref_code;
        if(ref_code) {
            storage.setItem('admin_ref', ref_code);
        }
        navigate("/",{replace:true});
    },[navigate, params.ref_code])
    return null;
}

export default SetRefCode;
