import React, {Component} from 'react';
import Modal from "../../../../components/Modal";
import {getHttpClient} from "../../../../api";
import {actionMergeSetting} from "../../../../redux/settings/settingsAction";
import Label from "../../../../components/form/Label";
import {connect} from "react-redux";

class MailchimpListSelector extends Component {
    state = {
        lists:[]
    }


    componentDidMount() {
        getHttpClient().get('/integrations/mailchimp/get_lists').then(({data})=>{
            const {lists} = data;
            this.setState({lists});
        })
    }

    getList = ()=>{
        return this.state.mc_list_id !== undefined ? this.state.mc_list_id : this.props.mc_list_id;

    }

    handleChange = (e)=>{
        this.setState({mc_list_id: e.target.value})
    }

    onSave = (e)=>{
        if(e){
            e.preventDefault();
            e.stopPropagation();
        }
        const mc_list_id = this.getList();
        const l = this.state.lists.find(({id})=> mc_list_id == id);
        console.log(l);
        if(l) {
            this.setState({loading:true})
            getHttpClient().post('/integrations/mailchimp/update_list', {mc_list_id: l.id, mc_list_title: l.name})
                .then(({data}) => {
                    this.props.mergeSettings({...data})
                    this.props.onClose();
                    this.setState({loading: false})
                }).catch((e) => {
                this.setState({loading: false})
            })
        }
    }

    render() {
        return (
            <Modal title={"Choose List"} visible={this.props.visible} confirmLoading={this.state.loading} onSubmit={this.onSave} onCancel={this.props.onClose}>
                <Label title={"List"}/>
               <select className={'form-select'} value={this.getList()} onChange={this.handleChange}>
                    <option value=""></option>
                    {
                        this.state.lists.map(({id, name})=>{
                            return <option value={id} key={id}>{name}</option>
                        })
                    }
                </select>
            </Modal>
        );
    }
}



function mapDispatchToProps(dispatch){
    return {
        mergeSettings:(change)=>dispatch(actionMergeSetting(change))
    }
}

function mapStateToProps(state){
    return {
        mc_access_token: state.settings.mc_access_token,
        mc_list_id: state.settings.mc_list_id,
        mc_api_endpoint: state.settings.mc_api_endpoint,
        mc_list_title: state.settings.mc_list_title,
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(MailchimpListSelector);
