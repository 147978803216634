import React, {useCallback, useEffect, useRef, useState} from 'react';
import {actionMergeSetting, loadSettings} from '../redux/settings/settingsAction';
import {connect} from 'react-redux';
import {getHttpClient} from '../api';
import Spinner from '../components/Spinner';
import * as storage from '../utils/storage';
import useToggle from '../components/hooks/useToggle';
import {useSessionStorageState} from 'ahooks';
import Select from '../components/form/Select';
import LoadingSkeleton from '../components/LoadingSkeleton';
import {setAccessToken} from '../utils/access_token_handler';
import {useNavigate} from 'react-router-dom';

function MasterPage() {
  const [stores, setStores] = useState(null);
  const [loading, setLoading] = useState(false);
  const [activeStore, setActiveStore] = useSessionStorageState('activeStore');
  const [initialToken, setInitialToken] = useState('');
  const [multiSiteToken, setMultiSiteToken] = useState(null);
  const frame = useRef(null);
  const navigate = useNavigate();
  useEffect(() => {
    setLoading(true);
    if (storage.getItem('manager_access_token')) {
      getHttpClient().post('/manager/get_manager_stores', {
        access_token: storage.getItem('manager_access_token'),
      }).then(({data}) => {
        if(data.sites.length === 0){
          setStores(data.sites)
          setLoading(false)
          return;
        }
        if(data.sites.length === 1){
          setAccessToken(data.sites[0].access_token)
          navigate('/')
          return;
        }
        setMultiSiteToken(data.multiSiteToken);
        setStores(data.sites);
        setLoading(false);
        const active = (activeStore ?
            data.sites.find(({site_id})=>String(site_id) === String(activeStore)) :
            data.sites[0]) || data.sites[0];
        setActiveStore(active.site_id);
        storage.setItem('sites', JSON.stringify(data.sites));
        storage.setItem('multi_site_access_token', multiSiteToken);
      }).catch((e) => {
        setLoading(false);
      });
    }
  }, []);

  useEffect(() => {
    if(stores && activeStore && stores.length > 0) {
      const active = (activeStore ?
          stores.find(({site_id}) => String(site_id) === String(activeStore)) :
          stores[0]) || stores[0];
      setInitialToken(active.access_token);
      storage.setItem('access_token', active.access_token);
    }
  }, [stores, activeStore]);


  useEffect(() => {
    if (frame && frame.current && initialToken && stores.length > 0) {
      frame.current.contentWindow.postMessage({
        access_token: initialToken,
        sites: stores.map(({name})=>name),
        multi_site_access_token: multiSiteToken,
      }, '*');
    }
  }, [initialToken, stores,multiSiteToken, frame]);

  const [collapsed, toggleCollapse] = useToggle(false)
  if(Array.isArray(stores) && stores.length === 0){
    return <p className="text-center">You don't have any stores in your account</p>
  }
  if (!stores || !activeStore) return <p className={'text-center mt-4'}><Spinner/></p>;

  return (
      <div className={'d-flex min-vh-100'}
           style={{position:'fixed', inset:0}}>
        <div style={{maxWidth:240, overflowY:'auto'}} className={"d-none d-md-block"}>
          <div className="p-2 space-between">
            <span className={"fs-4"}>Stores</span>
            {/*<button className="btn" onClick={toggleCollapse}>*/}
            {/*  {*/}
            {/*    collapsed ?*/}
            {/*        <BsArrowBarRight /> :*/}
            {/*        <BsArrowBarLeft />*/}
            {/*  }*/}
            {/*</button>*/}
          </div>
          <ul className="list-group list-group-flush">
            {
              loading ? <div style={{width:240}}>
                <LoadingSkeleton height={65} count={4}/>
              </div> : <>
                {
                  stores.map(({site_id, access_token, store_name, website}) => {
                    return <StoreItem
                        key={String(site_id)}
                        active={String(site_id) === String(activeStore)}
                        store_name={store_name}
                        onClick={(e) => setActiveStore(site_id)}
                        website={website}
                    />
                  })
                }
              </>
            }

          </ul>
          <LogoutButton/>
        </div>
        <div className={"flex-fill"}>
          <div className={"d-md-none d-block p-2 bg-light-subtle"}>
            <Select options={stores.map(({site_id,store_name})=>{
              return {
                label: store_name,
                value: site_id
              }
            })} value={activeStore || ''} onChange={setActiveStore} />
          </div>
          <iframe ref={frame}
                  style={{width: '100%', border: 0, height: '100%'}}
                  src={`/t/?token=${initialToken}&then=${sessionStorage.getItem('masterDashboardLocation') || ''}`}/>
        </div>
      </div>
  );
}

function LogoutButton() {
  const logout = useCallback(() => {
    storage.removeItem('access_token');
    storage.removeItem('manager_access_token');
    storage.removeItem('multi_site_access_token');
    window.location = '/master';
  }, []);
  return <div className="my-4 text-center">
    <button onClick={logout} className={'btn btn-link text-danger'}>Logout
    </button>
  </div>;
}

function StoreItem({active, onClick, store_name, website}) {
  return <button style={{minWidth:200}} className={`list-group-item list-group-item-action ${active ?
      'active' :
      ''}`}
         onClick={onClick}>
    <div>{store_name}</div>
    <small>{website}</small>
  </button>;
}

function mapStateToProps(state) {
  return {
    master_token: state.settings.master_token,
    loading: state.settings.loading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadSettings: () => dispatch(loadSettings()),
    mergeSettings: (change) => dispatch(actionMergeSetting(change)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MasterPage);
