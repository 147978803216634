import React, {useEffect} from 'react';
import {getSquareConnectUrl} from './connectSquare';
import StartContainer from '../../login/StartContainer';
import DocumentTitle from '../../../components/DocumentTitle';
import Spinner from '../../../components/Spinner';
import {setAccessToken} from '../../../utils/access_token_handler';
function SquareUpIntegrationIndex() {

    useEffect(()=>{
      const params = new URL(window.location.href).searchParams
        const access_token = params.get('access_token')
        if(access_token){
          setAccessToken(access_token)
          window.location = getSquareConnectUrl()
        }else{
          if(params.has('signup')){
            window.location = `https://goaffpro.com/signup?redirect=/merchant/stores/add/square`
          }else{
            window.location = `https://goaffpro.com/login?redirect=/merchant/`
          }
        }
    },[])

    return (
        <StartContainer>
          <DocumentTitle title={'Square up online store integration'}/>
          <div className="text-center py-4">
            <Spinner />
          </div>
        </StartContainer>
    );
}

export default SquareUpIntegrationIndex;
