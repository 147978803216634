

export default (state = [], action)=>{
    switch (action.type) {
        case "set-customer-map":
            return action.data;
        case "update-customer-map":
            return state.map((item)=>item._id === action._id ? {...item, ...action.change} : item)
        case "delete-customer-map":
            return state.filter((item)=>item._id !== action._id)
        case "add-customer-map":
            return [...action.data, ...state];
        default:
            return state;
    }
}
