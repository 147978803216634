import React, {useMemo, useState} from 'react';
import {getCurrentQueryObject} from "../../../utils/func";
import {getHttpClient} from "../../../api";
import * as localStorage from '../../../utils/storage';
import StartContainer from "../../login/StartContainer";
import FormRenderer from "../../../components/form/FormRenderer";
import Button from "../../../components/form/Button";
import DocumentTitle from '../../../components/DocumentTitle';

const userFields = [
    {
        isRequired:true,
        isEnabled:true,
        id:'name',
        label:'Your Name',
        placeholder:'eg. John doe',
    },
    {
        isRequired:true,
        isEnabled:true,
        id:'email',
        label:'Your email address',
        placeholder:'eg. email@example.com',
    },
    {
        isRequired:true,
        isEnabled:true,
        id:'password',
        label:'Password',
    }
]
const storeFields = [{
    isRequired:true,
    isEnabled:true,
    id:'storeUrl',
    label:'Your Website URL',
    placeholder:'eg. https://yourstore.com',
}]
function SquarepsaceInstall(props) {
    const [formData, setFormData] = useState({
        name:'',
        email:'',
        password:'',
        storeUrl:'',
    })

    const {code, mtoken} = getCurrentQueryObject();
    const [error, setError] = useState(false);
    const [saving, setSaving] = useState(false);
    const onChange = (formData)=>{
        setFormData(formData)
    }

    function createSite(e){
        if(e){
            e.preventDefault();
            e.stopPropagation();
        }

        setSaving(true);
        getHttpClient().post('/squarespace/save_keys',{
            code,
            merchant_token: mtoken,
            ...formData,
        }).then(({data})=>{
            if(data.access_token){
                localStorage.setItem('access_token',data.access_token);
                window.location = "/wizard"
            }else {
                setError(data.error || 'Unexpected error')
            }
            setSaving(false)
        }).catch((E)=>{
            setSaving(false);
            setError('Unexpected error')
        })
    }
    const {name, email, password, storeUrl} = formData;
    const canSubmit = (mtoken || (name && email && password)) && storeUrl


    const formFields = useMemo(()=>mtoken ? storeFields : [...userFields, ...storeFields], [mtoken]);

    return (
        <>
            <StartContainer>
                <DocumentTitle title={"Store Setup"}/>
                {
                    error && <div className={"py-4 text-center"}>
                        <div className={"alert alert-danger"}>
                            {error}
                        </div>
                    </div>
                }
                    <h3 className={"text-center"}>Create store account</h3>
                    <div className={"card"}>
                        <form onSubmit={createSite} className={"card-body"}>
                            <FormRenderer {...formData} formFields={formFields} onChange={onChange} />
                            <Button disabled={!canSubmit}
                                    loading={saving}
                                    onClick={createSite} className={"btn btn-primary"} title={"Create store account"}/>
                        </form>
                    </div>
            </StartContainer>
        </>
    );
}

export default SquarepsaceInstall;
