import React, {useEffect, useState} from 'react';
import settingsDispatchToProps from '../premiumextras/settingsDispatchToProps';
import {connect, useSelector} from 'react-redux';
import settingsStateToProps from '../settingsStateToProps';
import SettingsSection from '../components/SettingSection';
import Button from '../../../components/form/Button';
import ReactSwitch from 'react-switch';
import {Link} from 'react-router-dom';
import Modal from '../../../components/Modal';
import FormGroup from '../../../components/form/FormGroup';
import {getHttpClient} from '../../../api';
import {useDebounce} from '../../../components/hooks';
import {SettingsMap} from '../../../components/VideoAndHelpSectionMap';

function CampaignMonitor({updateSettings, campaignmonitor_sync_enabled, campaignmonitor_list_id, campaignmonitor_api_key}){
    const [setupModal, showSetupModal] = useState(false);
    const subscription = useSelector((state)=>state.settings.subscription)
    const [syncing, setSyncing] = useState(false);
    const [syncCompleted, setSyncingCompleted] = useState(false);
    function resync(){
        setSyncing(true);
        getHttpClient()
            .post('/integrations/campaignmonitor/do_initial_sync')
            .then((e)=>{
                setSyncing(false);
                setSyncingCompleted(true)
                setTimeout((e)=>{
                    setSyncingCompleted(false)
                }, 3000)
            }).catch((e)=>{
            setSyncing(false);
        })
    }

    let cmp = null;
    if(Number(subscription) < 1){
        cmp = <Link to={"/subscriptions"}>Upgrade to Sync</Link>
    }else if(campaignmonitor_api_key && campaignmonitor_list_id){
        cmp = <>
            <div>
                <ReactSwitch checked={campaignmonitor_sync_enabled} onChange={(e)=>updateSettings({activecampaign_sync_enabled:e})}/>
            </div>
            {
                syncCompleted ? <p className={"text-success"}>Sync complete</p> : null
            }
            <div>
                <Button className={"btn px-0 btn-link"} onClick={()=>showSetupModal(!setupModal)}>Re-Configure</Button>
                <span className={"mx-4"}>|</span>
                <Button loading={syncing} className={"btn btn-link"} onClick={resync}>Re-Sync</Button>
            </div>
        </>
    }else{
        cmp = <Button className={"btn px-0 btn-link"} onClick={()=>showSetupModal(!setupModal)}>Setup</Button>
    }

    return (
        <SettingsSection requiresUpgrade={subscription < 1} title={"CampaignMonitor"} helpLink={SettingsMap.get('Integrations').CampaignMonitor.helpLink} videoID={SettingsMap.get('Integrations').CampaignMonitor.videoID} subtitle={"Sync affiliate data with campaignmonitor"}>
            {cmp}
            <ConnectedSetupModal visible={setupModal} onClose={()=>showSetupModal(false)}/>
        </SettingsSection>
    )
}
function SetupModal({  campaignmonitor_list_id, campaignmonitor_api_key, updateSettings, visible, onClose}){
    const [listId, setListId] = useState('')
    const [apiKey, setApiKey] = useState('')
    const [saving, setSaving] = useState(false);

    function onSave(e){
        e.preventDefault();
        e.stopPropagation();
        setSaving(true);
        updateSettings({
            campaignmonitor_api_key: apiKey,
            campaignmonitor_list_id: listId,
            campaignmonitor_sync_enabled:true
        }).then((e)=>{
            return  getHttpClient()
                .post('/integrations/campaignmonitor/do_initial_sync')
        }).then((e)=>{
            setSaving(false)
            onClose()
        })
    }

    useEffect(()=>{
        setListId(campaignmonitor_list_id);
        setApiKey(campaignmonitor_api_key);
    }, [ campaignmonitor_list_id, campaignmonitor_api_key])

    return <Modal title={"Campaign Monitor"} visible={visible}
                  disabled={!apiKey || !listId}
                  onCancel={onClose} confirmLoading={saving} okText={'Submit'} onSubmit={onSave}>
        <p>Copy the <kbd>API Key</kbd> from your CampaignMonitor account settings page. <a href={"https://help.campaignmonitor.com/api-keys"}
                                                                                           rel={"noreferrer noopener nofollow"}
                                                                                           target={"_blank"}>More Details</a></p>
        <FormGroup label={"API Key"}>
            <input
                value={apiKey}
                onChange={(e)=>setApiKey(e.target.value)}
                type="text" className="form-control"/>
            <small className="text-muted">eg. HkRgWkFNZabd/KvObbH3OjM4HsxfMlfdd....</small>
        </FormGroup>
        <FormGroup label={"List ID"}>
            <CampaignMonitorLists apiKey={apiKey} listI={listId} onChange={(e)=>setListId(e.target.value)}/>
        </FormGroup>
    </Modal>
}

function CampaignMonitorLists({apiKey, listId, onChange}){
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const key = useDebounce(apiKey, 500)
    useEffect(()=>{
        if(apiKey) {
            setLoading(true)
            getHttpClient().post('/integrations/campaignmonitor/getlists',{
                apiKey
            }).then(({data})=>{
                setLoading(false)
                setData(data.lists)
            }).catch(()=>{
                setLoading(false)
            })
        }
    },[key, apiKey])
    return <select className={'form-select'} value={listId} onChange={onChange}>
        <option disabled>Choose a list</option>
        {
            loading ? <option disabled>Loading...</option> :  null
        }
        {
            data.map((item)=>{
                return <option value={item.value} key={item.value}>{item.label}</option>
            })
        }
    </select>
}

const ConnectedSetupModal = connect(settingsStateToProps(['subscription','campaignmonitor_sync_enabled','campaignmonitor_api_key','campaignmonitor_list_id']), settingsDispatchToProps)(SetupModal)

export default connect(settingsStateToProps(['subscription','campaignmonitor_sync_enabled','campaignmonitor_api_key','campaignmonitor_list_id']), settingsDispatchToProps)(CampaignMonitor)
