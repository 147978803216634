import React from 'react';
import {useConfirm} from './ConfirmModal';
import Button from './form/Button';

function ConfirmButton({
                         title,
                         type,
                         loading,
                         className,
                         message,
                         onClick,
                         disabled,
                         children,
                       }:{
    title?: string,
    type?: 'button' | 'submit',
    loading?: boolean,
    className?: string,
    message: string,
    onClick:()=>void,
    disabled?: boolean,
    children: React.ReactNode,
}) {
  const confirm = useConfirm();

  const onConfirm = () => {
    confirm({
      message: message || 'Confirm action ?',
      onConfirm: onClick,
    });
  };
  return (
      <Button title={title} type={type} loading={loading}
              className={`btn ${className}`}
              disabled={disabled}
              onClick={onConfirm}>
        {children}
      </Button>
  );
}

export default ConfirmButton;
