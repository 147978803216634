import React from 'react';
import {Route, Routes} from 'react-router-dom';
import WooCommerceInstall from './WooCommerceInstall';
import WooCommerceCallback from './WooCommerceCallback';
import WooCommerceManualInstall from './install/WooCommerceManualInstall';
import WooCommerceAutomaticInstall from './install/WooCommerceAutomaticInstall';
import SetCredentials from './SetCredentials';

export default function WooCommerceIndex(){
        return (
            <Routes>
                <Route path={"/woocommerce/install/manual"} element={<WooCommerceManualInstall/>} />
                <Route path={"/woocommerce/install/auto"} element={<WooCommerceAutomaticInstall/>} />
                <Route path={"/woocommerce/install"} element={<WooCommerceInstall/>} />
                <Route path={"/woocommerce/setupkeys"} element={<SetCredentials/>} />
                <Route path={"/woocommerce/auth_callback"} element={<WooCommerceCallback/>} />
            </Routes>
        );
}

