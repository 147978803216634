import React, {Component, useState} from 'react';
import SettingsSection from "../../components/SettingSection";
import {connect} from "react-redux";
import ReactSwitch from "react-switch";
import Label from "../../../../components/form/Label";
import {Link} from "react-router-dom";
import settingsStateToProps from "../../settingsStateToProps";
import settingsDispatchToProps from "../../premiumextras/settingsDispatchToProps";
import {getHttpClient} from "../../../../api";
import Button from "../../../../components/form/Button";
import {SettingsMap} from "../../../../components/VideoAndHelpSectionMap";

function OmnisendSettings({updateSettings, subscription, omnisend_sync_enabled, omnisend_api_key, omnisend_list_name, omnisend_welcome_email}) {
    const setupBtn = subscription ?
        <Link to={"/settings/integrations/omnisend/configure"} className={"btn btn-secondary"}>Setup</Link> :
        <Link to={"/subscriptions"}>Upgrade to Sync</Link>
    const [syncing, setSyncing] = useState(false)
    const [syncCompleted, setSyncComplete] = useState(false)

    function resync(){
        setSyncing(true)
        getHttpClient().post('/integrations/omnisend/do_initial_sync')
            .then((e)=>{
                setSyncing(false)
                setSyncComplete(true)
            setTimeout((e)=>{
                setSyncComplete(false)
            }, 3000)
        }).catch((e)=>{
            setSyncing(false)
        })
    }
    return (
        <SettingsSection title={"Omnisend"} helpLink={SettingsMap.get('Integrations').Omnisend.helpLink} videoID={SettingsMap.get('Integrations').Omnisend.videoID} subtitle={"Sync affiliate's with omnisend marketing automation"}>
            {
                omnisend_api_key ?
                    <div>
                        <ReactSwitch checked={omnisend_sync_enabled} onChange={(e)=>updateSettings({omnisend_sync_enabled: e})} />
                        {
                            omnisend_list_name && <div>
                                <Label title={"Syncing to"}/>
                                <p>{omnisend_list_name}</p>
                                <Label title={"Send welcome email"} />
                                <p>
                                    {
                                        omnisend_welcome_email ? 'YES' : 'NO'
                                    }
                                </p>
                            </div>
                        }
                        {
                            syncCompleted && <p className="text-success">Sync completed</p>
                        }
                        <div className="d-flex align-items-center">
                            <Link to={"/settings/integrations/omnisend/configure"}>Re-Configure</Link>
                            <span className={"mx-4"}>|</span>
                            <Button className={"btn btn-link"} loading={syncing} onClick={resync}>Re-Sync</Button>
                        </div>
                    </div> : setupBtn

            }
        </SettingsSection>
    );
}
export default connect(settingsStateToProps(['omnisend_list','omnisend_api_key','omnisend_sync_enabled','omnisend_welcome_email']), settingsDispatchToProps)(OmnisendSettings);
