import {BsCameraVideo, BsQuestionCircle} from "react-icons/bs";
import Modal from "../Modal";
import React from "react";
import Youtube from "./Youtube";
import {useSelector} from 'react-redux';
import useToggle from '../hooks/useToggle';

export default function VideoModalWithHelpLink({videoID, helpLink, title, logoClassName}: {
    videoID?: string,
    helpLink?: string;
    title?: string;
    logoClassName?: string
}) {
    //@ts-ignore
    const integration = useSelector((state) => state.settings.integration)
    const [isModalVisible, onModalToggle] = useToggle()
    if (integration === "shoplazza") {
        return null;
    }
    return !!(videoID && helpLink) ? <>
        <button className={"btn btn-link text-muted btn-sm pt-0 px-0 d-block d-sm-inline ml-sm-2"}
                onClick={onModalToggle}>
            <BsCameraVideo className={logoClassName} style={{fontSize: "18px"}}/>
        </button>

        <Modal visible={isModalVisible} headerLink={helpLink} size={"lg"} title={title} onClose={onModalToggle}>
            <Youtube videoId={videoID}/>
        </Modal>
    </> : <a href={helpLink} target={"_blank"}
             className={"btn btn-link text-muted btn-sm pt-0 mb-1 text-decoration-none"} rel="noreferrer">
        <BsQuestionCircle/>
    </a>
}
