import React, {useMemo} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

const useHistory = function(){
  const navigate = useNavigate();
  return useMemo(()=>{
    return {
      replace: (path) => navigate(path, {replace: true}),
      goBack: () => navigate(-1),
      push:(path)=>navigate(path)
    }
  },[navigate])
}

function withHistory(Component) {
  function ComponentWithRouterProp(props) {
    const history = useHistory()
    const params = useParams()
    return (
        <Component
            history={history}
            match={{
              params
            }}
        />
    );
  }
  return ComponentWithRouterProp;
}
export default withHistory
