import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {getHttpClient} from '../../../api';
import {Link, useNavigate} from 'react-router-dom';
import StartContainer from '../../login/StartContainer';
import DocumentTitle from '../../../components/DocumentTitle';
import FormGroup from '../../../components/form/FormGroup';
import CurrencySelector from '../../../components/form/CurrencySelector';
import Button from '../../../components/form/Button';
import useObjectState from '../../../components/hooks/useObjectState';
import Select from '../../../components/form/Select';
import {setAccessToken} from '../../../utils/access_token_handler';
import LoadingSkeleton from '../../../components/LoadingSkeleton';
import {getAppOauthUrl} from './connectSquare';

function SquareUpCallback(){
    const params = new URL(document.location.href).searchParams
    const state = params.get('state');
    const code = params.get('code')
    const error = params.get('error')

    let session_state = null;
    try{
        session_state = sessionStorage.getItem('square_oauth_session_state')
    }catch(e){
    }
    // if(state !== session_state){
    //     return <GenericContainer>
    //         <div className="card">
    //             <div className="card-body">
    //                 <p className={'text-danger text-center'}>Invalid oAuth
    //                     session state</p>
    //                 <pre>{state} != {session_state}</pre>
    //             </div>
    //         </div>
    //     </GenericContainer>
    // } else
    if (code) {
        return <SquareUpCallbackInternal/>;
    } else if (error) {
        const description = params.get('error_description')
        if(description === "user_denied"){
            return <GenericContainer>
                <div className="card">
                    <div className="card-body">
                        <h5>Sorry!</h5>
                        <div>
                            <p>You'll need to give the required
                                permissions for
                                the app to work</p>
                            <Link to={getAppOauthUrl()}
                                  className="btn btn-primary">Retry
                                Installation</Link>
                        </div>
                    </div>
                </div>
            </GenericContainer>
        } else {
            return <StartContainer>
                <p className={'text-center'}>{error} / {description}</p>
            </StartContainer>;
        }
    } else {
        return <StartContainer>
            <div className="text-center">
            <p>Unknown oAuth callback</p>
                <textarea className={'form-control'}
                          value={document.location.href}></textarea>
            </div>
        </StartContainer>
    }
}

function SquareUpCallbackInternal() {
    const navigate = useNavigate();
    const code = new URL(document.location.href).searchParams.get('code')

    const [loading, setLoading] = useState(true)
    const [result, setResult] = useState(null)
    const [error, setError] = useState('')
    const [accountCreating, setAccountCreating] = useState(false)
    useEffect(()=>{
        if(code){
            setLoading(true)
            getHttpClient()
                .post('/integrations/square/on_callback',{
                    code
                }).then(({data})=>{
                setResult(data)
                setLoading(false)
            }).catch((e)=>{
                setLoading(false)
            })
        }
    },[code])
    const [data, setData, replace] = useObjectState({
        currency:'usd'
    })
    const createAccount = useCallback((e)=>{
        if(e){
            e.preventDefault();
            e.stopPropagation();
        }
        setAccountCreating(true)
        getHttpClient()
        .post('/integrations/square/complete_installation',{
            ...result,
            ...data,
        }).then(({data})=>{
            if(data.error){
                setError(data.error)
            }else if(data.access_token){
                setAccessToken(data.access_token);
                navigate("/wizard")
            }
            setAccountCreating(false)
        }).catch((e)=>{
            setError(e.message)
            setAccountCreating(false)
        })
    },[data, result])

    const siteOptions = useMemo(()=>{
        return result?.sites?.map(({id, site_title, domain})=>{
            return {
                label: site_title+' ('+domain+')',
                value: id,
            }
        }) || []
    },[result])

    return (
        <GenericContainer>
            <h2 className={'text-center'}>Choose online store</h2>
                <div className="card">
                    <form onSubmit={createAccount} className="card-body">
                        <FormGroup label={"Choose site"}>
                            {
                                loading ? <LoadingSkeleton height={34.5}/> : <>
                                    {
                                        siteOptions.length > 0 ?
                                    <Select
                                        defaultSelectFirst
                                        value={data.site}
                                        onChange={setData('site')}
                                        options={siteOptions}/> : <p>Sorry! You need to have  an online square site to install / set up the app</p>
                                    }</>
                            }
                        </FormGroup>
                        <FormGroup label={'Currency'}>
                            <CurrencySelector value={data.currency}
                                              onChange={(e)=>setData('currency')(e.target.value)}/>
                        </FormGroup>
                        {
                            error ? <p className="alert alert-danger">{error}</p> : null
                        }
                        <Button disabled={!data.site}
                                className={'btn btn-primary'}
                                loading={accountCreating}
                                onClick={createAccount}>Submit</Button>
                    </form>
                </div>
            <div className="text-center mt-2 text-sm">
                Couldn't find your site ? <Link to={getAppOauthUrl()}>Try installation again</Link>
            </div>
        </GenericContainer>
    );
}

function GenericContainer({children}){
    return <>
        <StartContainer>
        <DocumentTitle title={'Store Setup'}/>
        <div className="text-center mb-4">
            <img src={'/icon-square.svg'} style={{width:128, height:128, borderRadius:16}} alt="Logo" className={'logo'}/>
        </div>
            {children}
        </StartContainer>
    </>
}

export default SquareUpCallback;
