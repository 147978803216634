import React, {useEffect, useState} from 'react';
import {getCurrentQueryObject} from '../../../utils/func';
import {getHttpClient} from '../../../api';
import FakeProgressBar from '../../../components/FakeProgressBar';
import {Link} from 'react-router-dom';

export default function PaypalCallback() {
    const [error, setError] = useState(null)
    useEffect(()=>{
        const {subscription_id, gfpSiteToken} = getCurrentQueryObject();
        const params = gfpSiteToken ? {
            gfpSiteToken
        } : {}
        getHttpClient().post('/billing/paypal/activate',{
            subscription_id
        }, {
            params
        }).then(({data})=>{
            if(data.subscription){
                window.location = "/";
                window.close();
                setError(null);
            }else{
                setError('Unexpected error');
            }
        }).catch((e)=>{
            setError('Network error')
        })
    },[])

    const {subscription_id} = getCurrentQueryObject();
    return (
            <div className="min-vh-100 d-flex justify-content-center align-items-center">
                <div className="col-sm-4">
                {
                    error ? <div className="card">
                        <div className="card-body">
                            <h3>OOPS!</h3>
                            <p>The system encountered some error while processing your subscription. Contact us to activate your subscription</p>
                            <Link to={"/contact?body=Subscription activation failed "+subscription_id}>Contact us</Link>
                        </div>
                    </div> : <FakeProgressBar time={3}/>
                }
                </div>
            </div>
        );
}
